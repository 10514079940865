import {Component, OnDestroy, OnInit} from '@angular/core';
import {debounceTime, Subscription} from "rxjs";
import {ServiceLoadingScreen} from "../../service/loading-screen/loading-screen.service";

@Component({
  selector: 'app-loading-screen',
  templateUrl: './loading-screen.component.html',
  styleUrls: ['./loading-screen.component.scss']
})
export class LoadingScreenComponent implements OnInit, OnDestroy {
  loading = false;
  loadingSubscription!: Subscription;

  constructor(private loadingScreenService: ServiceLoadingScreen) {}

  ngOnInit(): void {
    this.loadingSubscription = this.loadingScreenService.loadingStatus
      .pipe(debounceTime(200))
      .subscribe((value: boolean) => {
        this.loading = value;
      });
  }

  ngOnDestroy(): void {
    this.loadingSubscription.unsubscribe();
  }
}
