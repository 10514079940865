import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {NavigationRoutes} from "./helpers/navigation.routes.helper";
import {NotAuthorizedComponent} from "./components/not-authorized/not-authorized.component";
import {AbstractRouteGuard} from "./modules/microsoft-authentication-library/route-guard/route-mock.guard.abstract";
import {HomeComponent} from "./modules/pages/components/home/home.component";
import {LeftSideNavComponent} from "./components/left-side-nav/left-side-nav.component";

const routes: Routes = [
  {
    path: '',
    redirectTo: NavigationRoutes.Auth.path,
    pathMatch: "full",
  },
  {
    path: NavigationRoutes.NotAuthorized.path,
    component: NotAuthorizedComponent,
    canActivate: [AbstractRouteGuard],
    data: { requiredRoles: NavigationRoutes.NotAuthorized.allowedRoles },
  },
  {
    path: NavigationRoutes.Secured.path,
    component: LeftSideNavComponent,
    canActivate: [AbstractRouteGuard],
    children: [
      {
        path: NavigationRoutes.LandingPage.path,
        component: HomeComponent,
        canActivate: [AbstractRouteGuard],
        data: {requiredRoles: NavigationRoutes.LandingPage.allowedRoles},
      },
      {
        path: NavigationRoutes.Pages.path,
        loadChildren: () => import('./modules/pages/pages.module').then((m) => m.PagesModule),
      },
      {
        path: NavigationRoutes.PagesManagement.path,
        loadChildren: () => import('./modules/pages-management/pages-management.module').then((m) => m.PagesManagementModule),
      },
      {
        path: NavigationRoutes.Request.path,
        loadChildren: () => import('./modules/request-management/request-management.module').then((m) => m.RequestManagementModule),
      },
      {
        path: NavigationRoutes.DashBoard.path,
        loadChildren: () => import('./modules/dashboard-management/dashboard-management.module').then((m) => m.DashboardManagementModule),
      },
      {
        path: NavigationRoutes.CountryManagement.path,
        loadChildren: () => import('./modules/country-management/country-management.module').then((m) => m.CountryManagementModule),
      },
      {
        path: NavigationRoutes.MasterDataConfiguration.path,
        loadChildren: () => import('./modules/master-data-configuration/master-data-configuration.module').then((m) => m.MasterDataConfigurationModule),
      },
      {
        path: NavigationRoutes.SegmentManagement.path,
        loadChildren: () => import('./modules/segment-management/segment-management.module').then((m) => m.SegmentManagementModule),
      },
      {
        path: NavigationRoutes.ServiceManagement.path,
        loadChildren: () => import('./modules/service-management/service-management.module').then((m) => m.ServiceManagementModule),
      },
      {
        path: NavigationRoutes.UserManagement.path,
        loadChildren: () => import('./modules/user-management/user-management.module').then((m) => m.UserManagementModule),
      },
    ]
  },
  {
    path: NavigationRoutes.Auth.path,
    loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: NavigationRoutes.RequestDetailEmail.path,
    loadChildren: () => import('./modules/request-details-approval-email-management/request-details-approval-email-management.module').then((m) => m.RequestDetailsApprovalEmailManagementModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
