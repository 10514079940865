import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';

import {
  MsalBroadcastService,
  MsalModule,
  MsalService,
  MsalRedirectComponent,
} from '@azure/msal-angular';
import { CoreModule } from './core/core.module';
import { HotToastModule } from '@ngneat/hot-toast';
import { NgIdleModule } from '@ng-idle/core';
import { LoadingScreenComponent } from './components/loading-screen/loading-screen.component';
import { FooterComponent } from './components/footer/footer.component';
import {FactoryServiceConfig} from "./service/config/config.service.factory";
import {ServiceConfig} from "./service/config/config.service";
import {Router} from "@angular/router";
import {InterceptorLoadingScreen} from "./interceptors/loading.interceptor";
import {InterceptorError} from "./interceptors/error.interceptor";
import {ConsoleErrorHandler} from "./helpers/console-error.helper";
import {AbstractEndpoints} from "../environments/endpoints/endpoints.abstract";
import {FactoryEndpoints} from "../environments/endpoints/endpoints.factory";
import { ErrorDialogComponent } from './components/error-dialog/error-dialog.component';
import {AbstractServiceAuthentication} from "./service/authentication/authentication.service.abstract";
import {FactoryServiceAuthentication} from "./service/authentication/authentication.service.factory";
import {ServiceMonitoring} from "./service/monitor/monitor.service";
import {
  MicrosoftAuthenticationLibraryModule
} from "./modules/microsoft-authentication-library/microsoft-authentication-library.module";
import { ConfirmationDialogComponent } from './dialog-boxes/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogWithCommentComponent } from './dialog-boxes/confirmation-dialog-with-comment/confirmation-dialog-with-comment.component';
import { TextAreaDialogComponent } from './dialog-boxes/text-area-dialog/text-area-dialog.component';
import {SharedModule} from "./shared/shared.module";
import {SessionStorage} from "./service/session/session.storage";
import {SessionServiceAbstract} from "./service/session/session.service.abstract";
import {SessionServiceFactory} from "./service/session/session.service.factory";
import { NotAuthorizedComponent } from './components/not-authorized/not-authorized.component';
import { LeftSideNavComponent } from './components/left-side-nav/left-side-nav.component';
import { NgIconsModule } from '@ng-icons/core';
import {
  bootstrapChevronDown,
  bootstrapPencil,
  bootstrapCalculator,
  bootstrapWallet2,
  bootstrapJournal
} from "@ng-icons/bootstrap-icons";
import {
  faSolidHouseChimney,
  faSolidScaleBalanced,
  faSolidPlus,
  faSolidGear,
  faSolidClockRotateLeft,
  faSolidRoad,
  faSolidCheck,
  faSolidCircleXmark,
  faSolidPencil
} from "@ng-icons/font-awesome/solid";
import {
  featherFileText,
  featherGrid,
  featherUser,
  featherX,
  featherThumbsUp,
  featherThumbsDown,
  featherTrendingUp,
  featherTrendingDown,
  featherEdit
} from "@ng-icons/feather-icons";
import {
  matDashboardOutline,
  matLanguageOutline,
  matTrackChangesOutline,
  matRocketLaunchOutline
} from "@ng-icons/material-icons/outline";
import {
  simpleOpenlayers
} from "@ng-icons/simple-icons"
import {PagesServiceAbstract} from "./modules/pages/services/pages.service.abstract";
import {PagesServiceFactory} from "./modules/pages/services/pages.service.factory";
import {DATE_PIPE_DEFAULT_OPTIONS} from "@angular/common";
import {NavigationService} from "./service/navigation/navigation.service";
import { ActiveFilterComponent } from './components/active-filter/active-filter.component';

@NgModule({
  declarations: [
    AppComponent,
    LoadingScreenComponent,
    FooterComponent,
    ErrorDialogComponent,
    ConfirmationDialogComponent,
    ConfirmationDialogWithCommentComponent,
    TextAreaDialogComponent,
    NotAuthorizedComponent,
    LeftSideNavComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    MsalModule,
    CoreModule,
    NgIdleModule.forRoot(),
    HotToastModule.forRoot(), //https://ngneat.github.io/hot-toast/#examples
    SharedModule.forRoot(),
    MicrosoftAuthenticationLibraryModule.forRoot(),
    NgIconsModule.withIcons({
      bootstrapChevronDown,
      faSolidHouseChimney,
      faSolidScaleBalanced,
      featherFileText,
      faSolidPlus,
      matDashboardOutline,
      faSolidGear,
      featherGrid,
      featherUser,
      simpleOpenlayers,
      matLanguageOutline,
      matTrackChangesOutline,
      featherX,
      faSolidClockRotateLeft,
      faSolidRoad,
      faSolidCheck,
      faSolidCircleXmark,
      faSolidPencil,
      bootstrapPencil,
      featherThumbsUp,
      featherThumbsDown,
      featherTrendingUp,
      featherTrendingDown,
      bootstrapCalculator,
      bootstrapWallet2,
      matRocketLaunchOutline,
      bootstrapJournal,
      featherEdit
    })
  ],
  providers: [
    // APP INITIALIZER'
    {provide: DATE_PIPE_DEFAULT_OPTIONS, useValue: {timezone: ''}},
    {
      provide: APP_INITIALIZER,
      useFactory: FactoryServiceConfig,
      deps: [ServiceConfig, Router],
      multi: true,
    },
    // HTTP INTERCEPTORS
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorLoadingScreen,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorError,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: ConsoleErrorHandler
    },
    {
      provide: AbstractEndpoints,
      useFactory: FactoryEndpoints,
      deps: [ServiceConfig],
    },
    {
      provide: AbstractServiceAuthentication,
      useFactory: FactoryServiceAuthentication,
      deps: [
        MsalService,
        MsalBroadcastService,
        AbstractEndpoints,
        ServiceMonitoring,
      ],
    },
    SessionStorage,
    NavigationService,
    {
      provide: SessionServiceAbstract,
      useFactory: SessionServiceFactory,
      deps: [HttpClient, AbstractEndpoints, ServiceMonitoring],
    },
    {
      provide: PagesServiceAbstract,
      useFactory: PagesServiceFactory,
      deps: [HttpClient, AbstractEndpoints, ServiceMonitoring],
    },
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
  exports: [
    ActiveFilterComponent
  ]
})
export class AppModule {}
