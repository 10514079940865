import {Component, OnInit} from '@angular/core';
import {CanComponentDeactivateConfirm} from "../../../../guards/navigate-away-confirm.guard";
import {PageMode} from "../../../../enums/separated-enums/page-mode.enum";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {CreateSubSegmentCommand, SubSegmentResponse, UpdateSubSegmentCommand} from "../../models/sub-segment";
import {Router} from "@angular/router";
import {SnackBarService} from "../../../../service/snack-bar/snack-bar.service";
import {ServiceErrorHandler} from "../../../../service/error-handler/error-handler.service";
import {SegmentManagementServiceAbstract} from "../../services/segment-management.service.abstract";
import {SegmentManagementValidator} from "../../validators/segment-management.validator";
import {CreateAudiFirmCommand, UpdateAudiFirmCommand} from "../../../master-data-configuration/models/audit-firm";
import {HttpErrorResponse} from "@angular/common/http";
import {Constants} from "../../../master-data-configuration/models/constants";
import {SegmentResponse} from "../../models/segment";
import {SystemUserResponse} from "../../../user-management/models/system-user";
import {UserManagementServiceAbstract} from "../../../user-management/services/user-management.service.abstract";
import {Role} from "../../../../enums";
import {map} from "rxjs";

@Component({
  selector: 'app-sub-segment-maintain',
  templateUrl: './sub-segment-maintain.component.html',
  styleUrls: ['./sub-segment-maintain.component.scss']
})
export class SubSegmentMaintainComponent implements OnInit, CanComponentDeactivateConfirm {
  existingSubSegments?: SubSegmentResponse[];
  selectedSubSegment?: SubSegmentResponse;
  pageMode: PageMode = PageMode.Add;
  pageModeText = 'Create new';
  submitButtonText = 'Create';
  isLoading = false;
  subSegmentForm: FormGroup;
  useGuard = true;

  segments: SegmentResponse[] = [];
  users: SystemUserResponse[] = [];
  accountExecutiveUser: SystemUserResponse[] = [];
  chiefFinancialOfficerUser: SystemUserResponse[] = [];
  businessFinancialControllerUser: SystemUserResponse[] = [];
  groupFinancialControllerUser: SystemUserResponse[] = [];
  financialDirectorUser: SystemUserResponse[] = [];

  constructor(
    public router: Router,
    private snackBar: SnackBarService,
    private segmentManagementValidator: SegmentManagementValidator,
    private segmentManagementService: SegmentManagementServiceAbstract,
    private userManagementService : UserManagementServiceAbstract,
    private serviceErrorHandler: ServiceErrorHandler,
  ){
    const parameters: any = router.GetNavigationParameter(
      SubSegmentMaintainComponent
    );

    if (parameters) {
      this.existingSubSegments = parameters.existingSubSegments;
      this.selectedSubSegment = parameters.selectedSubSegment;

      if (this.selectedSubSegment) {
        this.pageMode = PageMode.Edit;
        this.pageModeText = 'Edit';
        this.submitButtonText = 'Update';
      } else {
        this.pageMode = PageMode.Add;
        this.pageModeText = 'Create new';
        this.submitButtonText = 'Create';
      }
    }
    else{
      router.NavigateTo.SubSegment();
    }

    this.subSegmentForm = new FormGroup({});
  }

  ngOnInit(): void {
    this.setupForm();
    this.getSegment();
    this.getSystemUser();
  }

  confirmNavigateAway(): boolean {
    return (
      this.subSegmentForm.touched && this.subSegmentForm.dirty && this.useGuard
    );
  }

  get nameCharacterCount() {
    return this.subSegmentForm.controls['name'].value.length;
  }

  get createSubSegmentCommand() {
    return {
      name: this.subSegmentForm.controls['name'].value.trim(),
      costCenter: this.subSegmentForm.controls['costCenter'].value.trim(),
      accountExecutiveUserId: this.subSegmentForm.controls['accountExecutiveUserId'].value,
      chiefFinancialOfficerUserId: this.subSegmentForm.controls['chiefFinancialOfficerUserId'].value,
      businessFinancialControllerUserId: this.subSegmentForm.controls['businessFinancialControllerUserId'].value,
      groupFinancialControllerUserId: this.subSegmentForm.controls['groupFinancialControllerUserId'].value,
      financialDirectorUserId: this.subSegmentForm.controls['financialDirectorUserId'].value,
      segmentId: this.subSegmentForm.controls['segmentId'].value,
    } as CreateSubSegmentCommand;
  }

  get updateSubSegmentCommand() {
    return {
      id: this.subSegmentForm.controls['id'].value,
      name: this.subSegmentForm.controls['name'].value.trim(),
      costCenter: this.subSegmentForm.controls['costCenter'].value.trim(),
      accountExecutiveUserId: this.subSegmentForm.controls['accountExecutiveUserId'].value,
      chiefFinancialOfficerUserId: this.subSegmentForm.controls['chiefFinancialOfficerUserId'].value,
      businessFinancialControllerUserId: this.subSegmentForm.controls['businessFinancialControllerUserId'].value,
      groupFinancialControllerUserId: this.subSegmentForm.controls['groupFinancialControllerUserId'].value,
      financialDirectorUserId: this.subSegmentForm.controls['financialDirectorUserId'].value,
      segmentId: this.subSegmentForm.controls['segmentId'].value,
    } as UpdateSubSegmentCommand;
  }

  setupForm() {
    this.subSegmentForm = new FormGroup({
      id: new FormControl(
        this.selectedSubSegment ? this.selectedSubSegment.id : undefined
      ),
      name: new FormControl(
        this.selectedSubSegment ? this.selectedSubSegment.name : ''
      ),
      costCenter: new FormControl(this.selectedSubSegment ? this.selectedSubSegment.costCenter: ''),
      accountExecutiveUserId : new FormControl(this.selectedSubSegment ? this.selectedSubSegment.accountExecutiveUser?.id : undefined, Validators.required),
      chiefFinancialOfficerUserId : new FormControl(this.selectedSubSegment ? this.selectedSubSegment.chiefFinancialOfficerUser?.id : undefined,  Validators.required),
      businessFinancialControllerUserId : new FormControl(this.selectedSubSegment ? this.selectedSubSegment.businessFinancialControllerUser?.id : undefined, Validators.required),
      groupFinancialControllerUserId : new FormControl(this.selectedSubSegment ? this.selectedSubSegment.groupFinancialControllerUser?.id : undefined, Validators.required),
      financialDirectorUserId : new FormControl(this.selectedSubSegment ? this.selectedSubSegment.financialDirectorUser?.id : undefined, Validators.required),
      segmentId: new FormControl(this.selectedSubSegment?.segment ? this.selectedSubSegment.segment.id : undefined, Validators.required)
    });

    this.subSegmentForm.controls['name'].setValidators([
      Validators.required,
      // this.segmentManagementValidator.duplicateNameValidator(
      //   this.subSegmentForm,
      //   this.existingSubSegments
      // ),
    ]);
  }

  getSegment(){
    this.isLoading = true;
    this.segmentManagementService
      .getAllSegments()
      .subscribe({
        next: (result: SegmentResponse[]) => {
          this.segments = result;
        },
        error: (error: HttpErrorResponse) => {
          this.serviceErrorHandler.displayHttpErrorDialog(
            this,
            error,
            Constants.ErrorMessages
          );
        },
      })
      .add(() => {
        this.isLoading = false;
      });
  }

  getSystemUser(){
    this.isLoading = true;
    this.userManagementService
      .getAllSystemUsers()
      .subscribe({
        next: (result: SystemUserResponse[]) => {
          console.log(Role.Admin.trim().toLowerCase())
          this.users = result.filter(x => x.isAbsaEmployee === true && x.isActive === true);
          this.accountExecutiveUser = this.users.filter(x => x.roles?.map(z => z.name.trim().toLowerCase())?.includes(Role.ExcoMember.toLowerCase()))
          this.chiefFinancialOfficerUser = this.users.filter(x => x.roles?.map(z => z.name.trim().toLowerCase())?.includes(Role.ChiefFinancialOfficer.toLowerCase()))
          this.businessFinancialControllerUser = this.users.filter(x => x.roles?.map(z => z.name.trim().toLowerCase())?.includes(Role.BusinessFinancialController.toLowerCase()))
          this.groupFinancialControllerUser = this.users.filter(x => x.roles?.map(z => z.name.trim().toLowerCase())?.includes(Role.GroupFinancialController.toLowerCase()))
          this.financialDirectorUser = this.users.filter(x => x.roles?.map(z => z.name.trim().toLowerCase())?.includes(Role.FinancialDirector.toLowerCase()))
        },
        error: (error: HttpErrorResponse) => {
          this.serviceErrorHandler.displayHttpErrorDialog(
            this,
            error,
            Constants.ErrorMessages
          );
        },
      })
      .add(() => {
        this.isLoading = false;
      });
  }

  onSubmit() {
    Object.values(this.subSegmentForm.controls).forEach((control) => {
      control.markAsTouched();
    });

    if (this.subSegmentForm.valid) {
      if (this.pageMode === PageMode.Add) {
        this.processCreate();
      } else {
        this.processEdit();
      }
    }
  }

  processCreate() {
    this.isLoading = true;
    this.segmentManagementService
      .createSubSegment(this.createSubSegmentCommand)
      .subscribe({
        next: () => {
          this.showSuccessMessage();
        },
        error: (error: HttpErrorResponse) => {
          this.serviceErrorHandler.displayHttpErrorDialog(
            this,
            error,
            Constants.ErrorMessages
          );
        },
      })
      .add(() => {
        this.isLoading = false;
      });
  }

  processEdit() {
    if (this.selectedSubSegment?.id) {
      this.isLoading = true;
      this.segmentManagementService
        .updateSubSegment(
          this.selectedSubSegment.id,
          this.updateSubSegmentCommand
        )
        .subscribe({
          next: () => {
            this.showSuccessMessage();
          },
          error: (error: HttpErrorResponse) => {
            this.serviceErrorHandler.displayHttpErrorDialog(
              this,
              error,
              Constants.ErrorMessages
            );
          },
        })
        .add(() => {
          this.isLoading = false;
        });
    }
  }

  onCancel() {
    this.NavigateBackToView();
  }

  NavigateBackToView() {
    this.router.NavigateTo.SubSegment();
  }

  showSuccessMessage() {
    this.useGuard = false;
    this.NavigateBackToView();

    this.snackBar.open(
      `Sub Segment successfully ${
        this.pageMode === PageMode.Add ? 'created' : 'updated'
      }`
    );
  }
}
