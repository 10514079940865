import {Observable} from "rxjs";
import {CreatePageTemplateCommand, PageTemplateResponse, UpdatePageTemplateCommandModel} from "../models";

export abstract class PagesManagementServiceAbstract {
  abstract getAllPageTemplates(): Observable<PageTemplateResponse[]>;
  abstract getPageTemplateByPageTarget(pageTarget: number): Observable<PageTemplateResponse[]>;
  abstract getPageTemplateById(id: number): Observable<PageTemplateResponse>;
  abstract createPageTemplate(newPageTemplate: CreatePageTemplateCommand): Observable<number>;
  abstract updatePageTemplate(id: number, changedPageTemplate: UpdatePageTemplateCommandModel): Observable<number>;
  abstract deletePageTemplate(id: number): Observable<number>;
  abstract getActivePageTemplate(pageTarget: number): Observable<PageTemplateResponse>;
  abstract setActivePageTemplate(id: number, pageTarget: number): Observable<number>
}
