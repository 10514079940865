import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {
  MasterDataConfigurationServiceAbstract
} from "../../../../master-data-configuration/services/master-data-configuration.service.abstract";
import {ServiceErrorHandler} from "../../../../../service/error-handler/error-handler.service";
import {HttpErrorResponse} from "@angular/common/http";
import {
  Constants,
  CountryCurrencyResponse,
  CountryResponse,
  RequestMaintainCountryContractData,
  RequestResponse
} from "../../../models";
import {min} from "rxjs";
import {
  CountryManagementServiceAbstract
} from "../../../../country-management/services/country-management.service.abstract";
import {format as CurrencyFormat, unformat as CurrencyUnFormat} from 'currency-formatter'

@Component({
  selector: 'app-request-maintain-country-contract',
  templateUrl: './request-maintain-country-contract.component.html',
  styleUrls: ['./request-maintain-country-contract.component.scss']
})
export class RequestMaintainCountryContractComponent
    implements OnInit
{

  @Input()
  selectedRequest?: RequestResponse;


  protected isLoading = false;
  public countryContractForm: FormGroup;

  protected countries: CountryResponse[] = [];
  protected countryCurrencies: CountryCurrencyResponse[] = [];

  isExchangeRateDateVisible: boolean = false;
  isCenterVisible: boolean = false;
  isZAREquivalent: boolean = false;
  minDate?: Date;
  maxDate?: Date;

  exchangeRateDate?: Date;

  constructor(
      private masterDataConfigurationService: MasterDataConfigurationServiceAbstract,
      private countryManagementService: CountryManagementServiceAbstract,
      private serviceErrorHandler: ServiceErrorHandler
  ) {
    this.countryContractForm = new FormGroup({});
  }

  ngOnInit(): void {
    this.getCountries();
    this.getCountryCurrencies();
    this.setMinDate();
    this.setMaxDate();
    this.setupForm();
  }

  formatCurrency_ContractValue(event: any){
    const newValue = this.currencyFormat(+this.countryContractForm.controls['contractValue'].value);
    this.countryContractForm.controls['contractValue'].patchValue(newValue)
  }

  unFormatCurrency_ContractValue(event: any){
    let value = this.countryContractForm.controls['contractValue'].value;
    if(value?.includes && value.includes(',')){
      const newValue = this.currencyUnFormat(this.countryContractForm.controls['contractValue'].value);
      this.countryContractForm.controls['contractValue'].patchValue(newValue);
    }
  }

  formatCurrency_ExchangeRate(event: any){
    const newValue = this.currencyFormat(+this.countryContractForm.controls['exchangeRate'].value);
    this.countryContractForm.controls['exchangeRate'].patchValue(newValue);
  }

  unFormatCurrency_ExchangeRate(event: any){
    let value = this.countryContractForm.controls['exchangeRate'].value;
    if(value?.includes && value.includes(',')){
      const newValue = this.currencyUnFormat(this.countryContractForm.controls['exchangeRate'].value);
      this.countryContractForm.controls['exchangeRate'].patchValue(newValue);
    }

  }

  currencyUnFormat(value: string): number{
    const unFormattedValue = CurrencyUnFormat(value, {})
    return +unFormattedValue;
  }
  currencyFormat(value: number): string{
    return CurrencyFormat(value, {});
  }
  setupForm(): void {
    this.countryContractForm = new FormGroup({
      countryId: new FormControl(this.selectedRequest ? this.selectedRequest.country?.id : null, Validators.required),
      contractCurrencyId: new FormControl(this.selectedRequest ? this.selectedRequest.contractCurrency?.id : null, Validators.required),
      contractValue: new FormControl(this.selectedRequest ? this.selectedRequest.contractValue : '', [Validators.required, Validators.min(0.01)]),
      exchangeRate: new FormControl(''),
      zAREquivalent: new FormControl(this.selectedRequest ? this.selectedRequest.zAREquivalent : ''),
      exchangeRateDate: new FormControl(undefined),
    });

    this.countryContractForm.controls['contractCurrencyId'].valueChanges.subscribe((value) => {
      this.updateFormFields(+value);
    });

    this.countryContractForm.controls['exchangeRate'].valueChanges.subscribe((value) => {
      console.log('Exchange rate', value);
      let newValue = value
      if(value?.includes && value?.includes(',')){
        newValue = CurrencyUnFormat(value, {})
      }
      this.updateZarEquivalent(+newValue);
    });

    this.countryContractForm.controls['contractValue'].valueChanges.subscribe((value) => {
      console.log("contractValue",  value)
      let newValue = value
      if(value?.includes && value?.includes(',')){
        newValue = CurrencyUnFormat(value, {})
      }
      console.log("contractValue unformated", newValue)
      this.updateZarEquivalent(+newValue);
    });

  }

  updateFormFields(value?: number){
    if(value){
      if (this.countryCurrencies.find((x) => x.id === value)?.currency === 'ZAR') {
        this.isCenterVisible = false;
        this.isZAREquivalent = false;
        this.isExchangeRateDateVisible = false;

        this.countryContractForm.controls['exchangeRate'].setValidators(null);
        this.countryContractForm.controls['zAREquivalent'].setValidators(null);
        this.countryContractForm.controls['exchangeRateDate'].setValidators(null);
        this.countryContractForm.controls['exchangeRate'].disabled;
        this.countryContractForm.controls['exchangeRate'].patchValue(null);
        this.countryContractForm.controls['exchangeRateDate'].patchValue(null);

      } else {
        this.isCenterVisible = true;
        this.countryContractForm.controls['exchangeRate'].enabled;
        this.isZAREquivalent = true;
        this.isExchangeRateDateVisible = true;
        this.countryContractForm.controls['exchangeRate'].setValidators([Validators.required, Validators.min(0.01)]);
        this.countryContractForm.controls['zAREquivalent'].setValidators([Validators.required, Validators.min(0.01)]);
        this.countryContractForm.controls['exchangeRateDate'].setValidators(Validators.required);
        this.countryContractForm.controls['zAREquivalent'].patchValue(CurrencyFormat(this.countryContractForm.controls['contractValue'].value, {}))
      }
    }

    console.log('this.countryContractForm.controls[\'zAREquivalent\']', this.countryContractForm.controls['zAREquivalent'].value)
  }

  updateZarEquivalent(value?: number){
    if(value){
      if (this.countryCurrencies.find((x) => x.id === +this.countryContractForm.controls['contractCurrencyId'].value)?.name.trim().toLowerCase() === 'South African Rand (ZAR)'.trim().toLowerCase()) {
        this.countryContractForm.controls['zAREquivalent'].patchValue(CurrencyFormat(value, {}));
      }
      else{
        this.calculate();
      }
    }
  }

  clearForm(): void {
    this.countryContractForm = new FormGroup({
      countryId: new FormControl(undefined, Validators.required),
      contractCurrencyId: new FormControl(undefined, Validators.required),
      contractValue: new FormControl('', [Validators.required, Validators.min(0.01)]),
      exchangeRate: new FormControl(''),
      zAREquivalent: new FormControl(''),
      exchangeRateDate: new FormControl(null),
    });

    this.countryContractForm.controls['contractCurrencyId'].valueChanges.subscribe((value) => {
      this.updateFormFields(+value)
    });

    this.countryContractForm.controls['exchangeRate'].valueChanges.subscribe((value) => {
      let newValue = value
      if(value?.includes && value?.includes(',')){
        newValue = CurrencyUnFormat(value, {})
      }
      this.updateZarEquivalent(+newValue);
    });

    this.countryContractForm.controls['contractValue'].valueChanges.subscribe((value) => {
      let newValue = value
      if(value?.includes && value?.includes(',')){
        newValue = CurrencyUnFormat(value, {})
      }
      this.updateZarEquivalent(+newValue);
    });

  }

  getCountries(){
    this.isLoading = true;
    this.countryManagementService
        .getAllCountries()
        .subscribe({
          next: (result: CountryResponse[]) => {
            this.countries = result?.filter(x => x?.isActive);
          },
          error: (error: HttpErrorResponse) => {
            this.serviceErrorHandler.displayHttpErrorDialog(
                this,
                error,
                Constants.ErrorMessages
            );
          },
        })
        .add(() => {
          this.isLoading = false;
        });
  }

  getCountryCurrencies() {
    this.isLoading = true;
    this.countryManagementService
        .getAllCountryCurrencies()
        .subscribe({
          next: (result: CountryCurrencyResponse[]) => {
            this.countryCurrencies = result?.filter(x => x?.isActive);
            this.updateFormFields(this.selectedRequest?.contractCurrency?.id)
          },
          error: (error: HttpErrorResponse) => {
            this.serviceErrorHandler.displayHttpErrorDialog(
                this,
                error,
                Constants.ErrorMessages
            );
          },
        })
        .add(() => {
          this.isLoading = false;
        });
  }

  private calculate() {
    let contractValue = this.countryContractForm.controls['contractValue'].value;
    let exchangeRate = this.countryContractForm.controls['exchangeRate'].value;

    if(contractValue?.includes && contractValue?.includes(',')) contractValue = CurrencyUnFormat(contractValue, {});
    if(exchangeRate?.includes && exchangeRate?.includes(',')) exchangeRate = CurrencyUnFormat(exchangeRate, {});

    if(contractValue && exchangeRate) {
      let calculatedZarValue = +contractValue * +exchangeRate;
      calculatedZarValue.toFixed(2);

      this.countryContractForm.controls['zAREquivalent'].patchValue(CurrencyFormat(calculatedZarValue, {}));
    }
  }

  // private addCommas(x: any) {
  //   let parts = x.toString().split('.');
  //   parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  //   return parts.join('.');
  // }

  clearExchangeRateDate() {
    this.countryContractForm.controls['exchangeRateDate'].reset();
  }

  get unFormatContractValue(){
    let value = this.countryContractForm.controls['contractValue'].value;
    if(value?.includes && value.includes(',')) value = CurrencyUnFormat(value, {});
    return +value > 0  ? value : null
  }

  get unFormatExchangeRate(){
    let value = this.countryContractForm.controls['exchangeRate'].value;
    if(value?.includes && value.includes(',')) value = CurrencyUnFormat(value, {});
    return +value > 0  ? value : null
  }

  get unFormatZAREquivalent(){
    let value = this.countryContractForm.controls['zAREquivalent'].value;
    if(value?.includes && value.includes(',')) value = CurrencyUnFormat(value, {});
    return +value > 0  ? value : null
  }
  public fetchData(): RequestMaintainCountryContractData {
    const exchangeRateDate = new Date( this.countryContractForm.controls['exchangeRateDate'].value);

    const exchangeRateYear = exchangeRateDate.getFullYear();
    const exchangeRateMonth = (exchangeRateDate.getMonth());
    const exchangeRateDay = exchangeRateDate.getDate();
    const date = new Date(Date.UTC(exchangeRateYear, exchangeRateMonth, exchangeRateDay))
    return {
      countryId: this.countryContractForm.controls['countryId'].value ?
        Number(this.countryContractForm.controls['countryId'].value) : null,
      contractCurrencyId: this.countryContractForm.controls['contractCurrencyId'].value ?
        Number(this.countryContractForm.controls['contractCurrencyId'].value) : null,
      contractValue: this.unFormatContractValue ?
        Number(this.unFormatContractValue) : null ,
      exchangeRate: this.unFormatExchangeRate && this.unFormatExchangeRate !== '0.00' ?
        Number(this.unFormatExchangeRate) : null,
      zAREquivalent: this.unFormatZAREquivalent ?
        Number(this.unFormatZAREquivalent) : Number(this.unFormatContractValue ?? 0.00),
      exchangeRateDate: this.countryContractForm.controls['exchangeRateDate'].value ? date : null
    } as  RequestMaintainCountryContractData;
  }

  private setMinDate(): void {
      let dte = new Date();
      dte.setDate(dte.getDate() - 1);
      this.minDate = dte;
  }

  private setMaxDate(): void {
    let dte = new Date();
    dte.setDate(dte.getDate());
    this.maxDate = dte;
  }

  protected readonly min = min;

}
