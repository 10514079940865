<div class="flex flex-col gap-[32px] items-stretch content-stretch p-3">
  <div fxLayout="column" fxLayoutGap="8px">
    <div class="mat-display-4">{{ pageModeText }} Sub Segment</div>
    <div class="mat-subheading-1">
      Please capture all required information below.
    </div>
  </div>
  <mat-divider></mat-divider>

  <form [formGroup]="subSegmentForm" fxLayout="column" fxLayoutGap="5px">

    <mat-form-field appearance="fill" fxFlex>
      <mat-label>Segment</mat-label>
      <mat-select formControlName="segmentId">
        <mat-option *ngFor="let item of segments" [value]="item.id">
          {{item.name }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="subSegmentForm.controls['segmentId'].hasError('required')">
        Please select a segment in the indicated field
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex appearance="fill">
      <mat-label>Sub Segment Name</mat-label>
      <input
        matInput
        maxlength="100"
        formControlName="name"
        autocomplete="off"
      />
<!--      <mat-error-->
<!--        *ngIf="subSegmentForm.controls['name'].hasError('duplicate')"-->
<!--      >-->
<!--        The captured audit firm already exists-->
<!--      </mat-error>-->
      <mat-error *ngIf="subSegmentForm.controls['name'].hasError('required')">
        Please capture audit firm in the indicated field
      </mat-error>
      <mat-hint align="end">{{ nameCharacterCount }} / 100</mat-hint>
    </mat-form-field>

    <mat-form-field fxFlex appearance="fill">
      <mat-label>Cost Center</mat-label>
      <input
        matInput
        maxlength="100"
        formControlName="costCenter"
        autocomplete="off"
      />

      <mat-error *ngIf="subSegmentForm.controls['costCenter'].hasError('required')">
        Please capture cost center in the indicated field
      </mat-error>
      <mat-hint align="end">{{ nameCharacterCount }} / 100</mat-hint>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex>
      <mat-label>Account Executive</mat-label>
      <mat-select formControlName="accountExecutiveUserId">
        <mat-option *ngFor="let item of accountExecutiveUser" [value]="item.id">
          {{item.fullName }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="subSegmentForm.controls['accountExecutiveUserId'].hasError('required')">
        Please select a account executive in the indicated field
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex>
      <mat-label>Chief Financial Officer</mat-label>
      <mat-select formControlName="chiefFinancialOfficerUserId">
        <mat-option *ngFor="let item of chiefFinancialOfficerUser" [value]="item.id">
          {{item.fullName }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="subSegmentForm.controls['chiefFinancialOfficerUserId'].hasError('required')">
        Please select a chief financial officer in the indicated field
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex>
      <mat-label>Business Financial Controller</mat-label>
      <mat-select formControlName="businessFinancialControllerUserId">
        <mat-option *ngFor="let item of businessFinancialControllerUser" [value]="item.id">
          {{item.fullName }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="subSegmentForm.controls['businessFinancialControllerUserId'].hasError('required')">
        Please select a business financial controller in the indicated field
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex>
      <mat-label>Group Financial Controller</mat-label>
      <mat-select formControlName="groupFinancialControllerUserId">
        <mat-option *ngFor="let item of groupFinancialControllerUser" [value]="item.id">
          {{item.fullName }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="subSegmentForm.controls['groupFinancialControllerUserId'].hasError('required')">
        Please select a group financial controller in the indicated field
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex>
      <mat-label>Financial Director</mat-label>
      <mat-select formControlName="financialDirectorUserId">
        <mat-option *ngFor="let item of financialDirectorUser" [value]="item.id">
          {{item.fullName }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="subSegmentForm.controls['financialDirectorUserId'].hasError('required')">
        Please select a financial director in the indicated field
      </mat-error>
    </mat-form-field>

  </form>

  <div fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="16px">
    <button
      mat-stroked-button
      color="primary"
      class="mat-stroked-button"
      (click)="onCancel()"
      [disabled]="isLoading"
    >
      Cancel
    </button>
    <button
      mat-flat-button
      color="primary"
      class="mat-flat-button"
      (click)="onSubmit()"
      [disabled]="isLoading"
    >
      {{ submitButtonText }}
    </button>
  </div>
</div>
