import {MatSnackBar, MatSnackBarConfig} from "@angular/material/snack-bar";
import {Injectable} from "@angular/core";

@Injectable()
export class SnackBarService {
  constructor(public snackBar: MatSnackBar) {}

  public open(message: string, action = '') {
    const config = new MatSnackBarConfig();
    config.duration = 3000;
    config.verticalPosition = 'top';
    config.horizontalPosition = 'right';
    this.snackBar.open(message, action, config);
  }
}
