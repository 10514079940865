import { Component } from '@angular/core';

@Component({
  selector: 'app-country-currency-maintain',
  templateUrl: './country-currency-maintain.component.html',
  styleUrls: ['./country-currency-maintain.component.scss']
})
export class CountryCurrencyMaintainComponent {

}
