import {
  Component,
  OnInit,
} from '@angular/core';
import {SessionStorage} from "./service/session/session.storage";
import {AbstractServiceAuthentication} from "./service/authentication/authentication.service.abstract";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements  OnInit{

  constructor(
    // private sessionStorage: SessionStorage,
  ) {

  }

  ngOnInit() {
    // this.sessionStorage.setSystemRoles();
  }
}
