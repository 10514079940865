import {HttpClient} from "@angular/common/http";
import {AbstractEndpoints} from "../../../../environments/endpoints/endpoints.abstract";
import {ServiceMonitoring} from "../../../service/monitor/monitor.service";
import {AppSettings} from "../../../../environments/app-settings/app-settings";
import {Environments} from "../../../enums/separated-enums/environments.enum";
import {PagesServiceMock} from "./pages.service.mock";
import {PagesService} from "./pages.service";

export function PagesServiceFactory(
  http: HttpClient,
  endpoints: AbstractEndpoints,
  serviceMonitor: ServiceMonitoring
) {
  serviceMonitor.logEvent(
    'PagesServiceFactory',
    'Pages Service Factory loaded'
  );

  switch (AppSettings.environment) {
    case Environments.MockData:
      return new PagesServiceMock(endpoints, http);
    default:
      return new PagesService(endpoints, http);
  }
}
