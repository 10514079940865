import {Injectable} from "@angular/core";
import {ServiceErrorHandler} from "../error-handler/error-handler.service";
import {SessionServiceAbstract} from "./session.service.abstract";
import {AbstractServiceAuthentication} from "../authentication/authentication.service.abstract";
import {SystemUserResponse} from "../../model/user/system-user-response.model";
import {HttpErrorResponse} from "@angular/common/http";

@Injectable({
  providedIn: 'root',
})
export class SessionStorage {
  private systemUserRoles?: string[] | undefined;
  private userName?: string
  private UserEmailAddress?: string;
  constructor(
    private authenticationService : AbstractServiceAuthentication,
    private sessionService: SessionServiceAbstract,
    private serviceErrorHandler: ServiceErrorHandler
  ) {}

  isSystemUserRoleDefined(): boolean {
    return this.systemUserRoles != undefined;
  }

  isUserDetailsDefined(): boolean{
    return this.userName != undefined && this.UserEmailAddress != undefined;
  }

  setSystemRoles(mustFetchNewValue = false): void {
    if(!this.isSystemUserRoleDefined() || mustFetchNewValue){
      this.fetchSystemUserRole();
    }
    if(!this.isUserDetailsDefined()){
      this.assignUserDetail()
    }
    console.log("this.systemUserRoles", this.systemUserRoles)
  }

  setItem(name: string, object: string): void {
    localStorage.setItem(name, object);
  }

  getItem(name: string): string | null {
    return localStorage.getItem(name);
  }


  get getSystemRoles() : string[] | undefined{
    return this.systemUserRoles;
  }

  public get getUserName(): string | undefined {
    return this.userName;
  }

  public get getUserEmailAddress(): string | undefined{
    return this.UserEmailAddress;
  }

  // GET FROM API
  private fetchSystemUserRole() {
    if(this.authenticationService.account?.username){
      const email = this.authenticationService.account?.username;
      this.sessionService.getSystemUserByEmailAddress(email).subscribe({
        next: (result: SystemUserResponse) => {
          this.systemUserRoles = result?.roles?.map(x => x.name);
        },
        error: (error: HttpErrorResponse) => {
          this.serviceErrorHandler.displayErrorDialog(this, error.message);
        },
      });
    }
  }

  private assignUserDetail(){
    this.userName = this.authenticationService.account?.name;
    this.UserEmailAddress = this.authenticationService.account?.username;
  }
}
