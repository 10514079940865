<div class="flex flex-col gap-[32px] items-stretch content-stretch p-3">
  <div fxLayout="column" fxLayoutGap="8px">
    <div class="mat-display-4">{{ pageModeText }} Service Category</div>
    <div class="mat-subheading-1">
      Please capture all required information below.
    </div>
  </div>
  <mat-divider></mat-divider>
  <form [formGroup]="serviceCategoryForm" fxLayout="column" fxLayoutGap="5px">

    <mat-form-field fxFlex appearance="fill">
      <mat-label>Service Type</mat-label>
      <mat-select formControlName="serviceTypeId">
        <mat-option *ngFor="let item of serviceTypes" [value]="item.id">
          {{ item.name }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="serviceCategoryForm.controls['serviceTypeId'].hasError('required')">
        Please select a service type in the indicated field
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex appearance="fill">
      <mat-label>Service Category name</mat-label>
      <input
        matInput
        maxlength="500"
        formControlName="name"
        autocomplete="off"
      />
      <mat-error
        *ngIf="serviceCategoryForm.controls['name'].hasError('duplicate')"
      >
        The captured audit firm already exists
      </mat-error>
      <mat-error *ngIf="serviceCategoryForm.controls['name'].hasError('required')">
        Please capture audit firm in the indicated field
      </mat-error>
      <mat-hint align="end">{{ nameCharacterCount }} / 500</mat-hint>
    </mat-form-field>

  </form>
  <div fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="16px">
    <button
      mat-stroked-button
      color="primary"
      class="mat-stroked-button"
      (click)="onCancel()"
      [disabled]="isLoading"
    >
      Cancel
    </button>
    <button
      mat-flat-button
      color="primary"
      class="mat-flat-button"
      (click)="onSubmit()"
      [disabled]="isLoading"
    >
      {{ submitButtonText }}
    </button>
  </div>
</div>
