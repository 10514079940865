import {Injectable} from "@angular/core";
import {AbstractControl, FormGroup, ValidatorFn} from "@angular/forms";

@Injectable({
    providedIn: 'root',
})
export class RequestManagementValidator {
    attestationTrueValidator(form: FormGroup): ValidatorFn {
        return (control: AbstractControl): { [key: string]: boolean } | null => {
            const attestation = form.controls['attestation'].value ?? false

            if(attestation) return null;

            control.markAsTouched();

            const isAttestation = attestation === true;

            return isAttestation ? {attestationTrue: true} : null;
        }
    }
}
