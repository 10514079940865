<div class="flex flex-col gap-[32px] items-stretch content-stretch p-3">
  <div fxLayout="column" fxLayoutGap="8px">
    <div class="mat-display-4">{{ pageModeText }} Segment</div>
    <div class="mat-subheading-1">
      Please capture all required information below.
    </div>
  </div>
  <mat-divider></mat-divider>
  <form [formGroup]="segmentForm" fxLayout="column" fxLayoutGap="32px">
    <mat-form-field fxFlex appearance="outline">
      <mat-label>Segment name</mat-label>
      <input
        matInput
        maxlength="100"
        formControlName="name"
        autocomplete="off"
      />
      <mat-error
        *ngIf="segmentForm.controls['name'].hasError('duplicate')"
      >
        The captured segment already exists
      </mat-error>
      <mat-error *ngIf="segmentForm.controls['name'].hasError('required')">
        Please capture segment firm in the indicated field
      </mat-error>
      <mat-hint align="end">{{ nameCharacterCount }} / 100</mat-hint>
    </mat-form-field>
  </form>

  <div fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="16px">
    <button
      mat-stroked-button
      color="primary"
      class="mat-stroked-button"
      (click)="onCancel()"
      [disabled]="isLoading"
    >
      Cancel
    </button>
    <button
      mat-flat-button
      color="primary"
      class="mat-flat-button"
      (click)="onSubmit()"
      [disabled]="isLoading"
    >
      {{ submitButtonText }}
    </button>
  </div>
</div>
