export enum Role {
  Admin = 'administrator',
  OnlyWhenNotAdmin = 'OnlyWhenNotAdmin',
  AuditFirm = 'Audit Firm',
  BusinessFinancialController = 'Business Financial Controller',
  ChiefFinancialOfficer = 'Chief Financial Officer',
  Developer = 'developer',
  ExcoMember = 'Exco Member',
  FinancialDirector = 'Financial Director',
  GACCChair = 'GACC Chair',
  GACCGroup = 'GACC Group',
  GroupFinancialController = 'Group Financial Controller',
  GroupSecretariat = 'Group Secretariat',
  Review = 'Review',
}
