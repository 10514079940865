<div class="flex flex-col gap-[32px] content-stretch items-stretch p-3">
  <div fxLayout="column" fxLayoutGap="8px">
    <div class="mat-display-4">{{ pageModeText }} Home Template</div>
    <div class="mat-subheading-1">
      Please capture all required information below.
    </div>
  </div>
  <mat-divider></mat-divider>
  <form [formGroup]="pageTemplateForm" fxLayout="column" fxLayoutGap="32px">
    <mat-form-field fxFlex appearance="outline">
      <mat-label>Template name</mat-label>
      <input
        matInput
        maxlength="100"
        formControlName="templateName"
        autocomplete="off"
      />
      <mat-error
        *ngIf="pageTemplateForm.controls['templateName'].hasError('duplicate')"
      >
        The captured template name already exists
      </mat-error>
      <mat-error *ngIf="pageTemplateForm.controls['templateName'].hasError('required')">
        Please capture template name in the indicated field
      </mat-error>
      <mat-hint align="end">{{ templateNameCharacterCount }} / 500</mat-hint>
    </mat-form-field>
    <div class="flex flex-row gap-3 items-center justify-start">
      <label class="">Show Greeting</label>
      <mat-radio-group  formControlName="showGreeting">
        <mat-radio-button class="example-margin" value="true">
          Yes
        </mat-radio-button>
        <mat-radio-button class="example-margin" value="false">
          No
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="NgxEditor__Wrapper">
      <ngx-editor-menu [editor]="editor" [toolbar]="toolbar"> </ngx-editor-menu>
      <ngx-editor [editor]="editor" formControlName="templateHtml" > </ngx-editor>
    </div>
  </form>
  <div fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="16px">
    <button
      mat-stroked-button
      color="primary"
      class="mat-stroked-button"
      (click)="onCancel()"
      [disabled]="isLoading"
    >
      Cancel
    </button>
    <button
      mat-flat-button
      color="primary"
      class="mat-flat-button"
      (click)="onSubmit()"
      [disabled]="isLoading"
    >
      {{ submitButtonText }}
    </button>
  </div>
</div>
