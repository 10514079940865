<form [formGroup]="auditFirmForm" fxLayout="column" fxLayoutGap="2px">
    <!--Audit_Firm-->
    <mat-form-field fxFlex appearance="fill">
        <mat-label >{{ "Audit Firm" }}</mat-label>
        <mat-select formControlName="auditFirmId">
            <ng-container>
                <mat-option *ngFor="let p of auditFirms" [value]="p.id">
                    {{ p.name }}
                </mat-option>
            </ng-container>
        </mat-select>
      <mat-error *ngIf="auditFirmForm.controls['auditFirmId'].hasError('required')">
        The Audit Firm field is required.
      </mat-error>
    </mat-form-field>

    <!-- Assigned_Audit_Partner -->
    <mat-form-field fxFlex appearance="fill">
        <mat-label >{{ "Assigned Audit Partner" }}</mat-label>
        <input matInput formControlName="assignedAuditPartnerName">
      <mat-error *ngIf="auditFirmForm.controls['assignedAuditPartnerName'].hasError('required')">
       The Assigned Audit Partner field is required.
      </mat-error>
    </mat-form-field>
</form>
