import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  public openSideNav: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    constructor() {
    }
}
