import {ModuleWithProviders, NgModule} from "@angular/core";
import {OverlayModule} from "@angular/cdk/overlay";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {SnackBarService} from "../service/snack-bar/snack-bar.service";
import {AngularMaterialModule} from "./angular-material/material.module";
import {SearchGraphDropdownComponent} from "../components/search-graph-dropdown/search-graph-dropdown.component";
import {AsyncPipe} from "@angular/common";
import {NoAccessHideDirective} from "../directives/no-access-hide.directive";
import {ActiveFilterComponent} from "../components/active-filter/active-filter.component";
import {NgxEditorModule} from "ngx-editor";

@NgModule({
  declarations: [
    NoAccessHideDirective,
    SearchGraphDropdownComponent,
    ActiveFilterComponent,
  ],
  imports: [
    NgxEditorModule.forRoot({
      locals: {
        // menu
        bold: 'Bold',
        italic: 'Italic',
        code: 'Code',
        blockquote: 'Blockquote',
        underline: 'Underline',
        strike: 'Strike',
        bullet_list: 'Bullet List',
        ordered_list: 'Ordered List',
        heading: 'Heading',
        h1: 'Header 1',
        h2: 'Header 2',
        h3: 'Header 3',
        h4: 'Header 4',
        h5: 'Header 5',
        h6: 'Header 6',
        align_left: 'Left Align',
        align_center: 'Center Align',
        align_right: 'Right Align',
        align_justify: 'Justify',
        text_color: 'Text Color',
        background_color: 'Background Color',

        // popups, forms, others...
        url: 'URL',
        text: 'Text',
        openInNewTab: 'Open in new tab',
        insert: 'Insert',
        altText: 'Alt Text',
        title: 'Title',
        remove: 'Remove',
      },
    }),
    AngularMaterialModule,
    OverlayModule,
    DragDropModule,
    AsyncPipe],
  exports: [
    AngularMaterialModule,
    NoAccessHideDirective,
    SearchGraphDropdownComponent,
    ActiveFilterComponent,
  ],
  providers: [
    {
      provide: SnackBarService,
    },
  ],
  entryComponents: [],
})

export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
    };
  }
}
