<FormGroup [formGroup]="frmSearch" fxLayout="column">
  <mat-form-field appearance="outline" fxFlex>
    <mat-label>Search by Name or Email Address</mat-label>
    <input
      [formControl]="frmSearch.controls.searchInput"
      matInput
      #trigger="matAutocompleteTrigger"
      [matAutocomplete]="auto"
      (input)="onChange()"
      (blur)="checkSelected()"
    />
    <mat-icon matSuffix *ngIf="!showSpinner">search</mat-icon>
    <mat-spinner
      *ngIf="showSpinner"
      matSuffix
      diameter="22"
      class="right-m-4 left-m-4"
    ></mat-spinner>
    <mat-hint> Type at least 3 characters to search </mat-hint>
    <mat-error *ngIf="frmSearch.controls.searchInput.hasError('duplicate')">
      The user already exists
    </mat-error>
    <mat-error
      *ngIf="
        frmSearch.controls.searchInput.hasError('notSelected') &&
        !frmSearch.controls.searchInput.hasError('required')
      "
    >
      Please select a option from the drop down
    </mat-error>
    <mat-error *ngIf="frmSearch.controls.searchInput.hasError('required')">
      Name and Surname is required
    </mat-error>
    <mat-autocomplete
      #auto="matAutocomplete"
      [displayWith]="getAutoCompleteDisplayName"
      (optionSelected)="selectAutoComplete($event.option.value)"
    >
      <mat-option
        *ngFor="let option of filteredOptions | async"
        [value]="option"
      >
        <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign=" center">
          <div
            class="avatar avatar-small primary-container mat-caption"
            fxLayoutAlign="center center"
            fxLayout="row"
          >
            {{ getInitial(option.name) }}
          </div>
          <div>
            {{ option.name }}
          </div>
        </div>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</FormGroup>
