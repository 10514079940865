<div  class="flex flex-col justify-center justify-content-center items-center primary-container-blue box-p-8 w-full h-full">
  <div   class="flex flex-col gap-[40px] surface landing-container box-p-10  w-[90%]  md:w-[50%]"  >
    <div class="flex flex-col gap-[24px]">
      <div  class="w-100 flex flex-col gap-[24px] justify-content-center justify-content-center items-center">
        <div>
          <img src="../assets/img/images/absa-logo-roundred.png" alt="logo"/>
        </div>
        <h3 class="text text-center text-2xl text-primary-color font-bold">
          Permission required
        </h3>
      </div>
      <mat-divider></mat-divider>
      <h5 class="font-normal font-sans text-primary-color text-sm text-center">
        We are sorry, you do not have the necessary permissions to access this page.
      </h5>

      <div class="w-full flex justify-content-center justify-center">
        <button
          mat-raised-button
          color="primary"
          (click)="navigateToLoginPage()"
        >
          Back to Login
        </button>
      </div>
    </div>
  </div>
</div>
