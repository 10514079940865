import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import {AttachmentConstants, Constants} from "../../../request-management/models/constants";
import {SnackBarService} from "../../../../service/snack-bar/snack-bar.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {PagesService} from "../../services/pages.service";
import {PagesServiceAbstract} from "../../services/pages.service.abstract";

@Component({
  selector: 'app-policy-maintain',
  templateUrl: './policy-maintain.component.html',
  styleUrls: ['./policy-maintain.component.scss']
})
export class PolicyMaintainComponent {
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef | undefined;

  allowedFileExtensions: string[] = ['pdf'];

  errorMessage = '';
  successMessage = '';

  constructor(
    private snackBar: SnackBarService,
    private httpClient: HttpClient,
    private pagesService : PagesServiceAbstract
  ) {

  }

  get acceptedFileExtensions() {
    return this.allowedFileExtensions.map((ext) => `.${ext}`).join(',');
  }
  onSelectFile() {
    this.fileInput?.nativeElement.click();
  }

  attachFile(event: any) {
    const inputElement = event.target as HTMLInputElement;
    console.log('file', event.target.files?.[0]);
    const file: File = event.target.files?.[0];
    if (file && this.isValid(file)) {
      this.errorMessage = '';
      const formData = new FormData();
      formData.append('file', file, file.name);

      this.pagesService.createPolicy(formData)
        .subscribe({
          next: (response) => {
            this.successMessage = `Document ${file.name} is uploaded successfully`;
            this.getSuccess();
          },
          error: (error) => {
            this.successMessage = `Document ${file.name} is uploaded successfully`;
            this.getSuccess();
          },
        });
    }
  }

  isValid(file: File) {
    if (file.size > AttachmentConstants.maxFileSizeBinary) {
      this.errorMessage = AttachmentConstants.fileSizeErrorMessage;
      return false;
    }

    if (
      !AttachmentConstants.allowedFileExtensions.includes(
        this.getExtension(file.name.toLowerCase())
      )
    ) {
      this.errorMessage = AttachmentConstants.fileTypeErrorMessage;
      return false;
    }

    this.errorMessage = '';
    return true;
  }

  getExtension(filename: string) {
    return filename.substring(filename?.lastIndexOf('.') + 1);
  }

  getSuccess(){
    return this.snackBar.open('Policy Upload Success','Policy');
  }

  getError(){
    return this.snackBar.open('Policy Upload fail','Policy');
  }
}
