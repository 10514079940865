import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {CanComponentDeactivateConfirm} from "../../../../guards/navigate-away-confirm.guard";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {PageMode, Role} from "../../../../enums";
import {Router} from "@angular/router";
import {SnackBarService} from "../../../../service/snack-bar/snack-bar.service";
import {SegmentManagementValidator} from "../../validators/segment-management.validator";
import {SegmentManagementServiceAbstract} from "../../services/segment-management.service.abstract";
import {ServiceErrorHandler} from "../../../../service/error-handler/error-handler.service";
import {
  CreateDetailedSegmentCommand,
  DetailedSegmentResponse,
  UpdateDetailedSegmentCommand
} from "../../models/detailed-segment";
import {SystemUserResponse} from "../../../user-management/models/system-user";
import {HttpErrorResponse} from "@angular/common/http";
import {Constants} from "../../../master-data-configuration/models/constants";
import {SegmentResponse} from "../../models/segment";
import {UserManagementServiceAbstract} from "../../../user-management/services/user-management.service.abstract";

@Component({
  selector: 'app-detailed-segment-maintain',
  templateUrl: './detailed-segment-maintain.component.html',
  styleUrls: ['./detailed-segment-maintain.component.scss']
})
export class DetailedSegmentMaintainComponent implements OnInit, CanComponentDeactivateConfirm, OnDestroy  {
  existingDetailedSegments?: DetailedSegmentResponse[];
  selectedDetailedSegment?: DetailedSegmentResponse;
  pageMode: PageMode = PageMode.Add;
  pageModeText = 'Create new';
  submitButtonText = 'Create';
  isLoading = false;
  detailedSegmentForm: FormGroup;
  useGuard = true;

  createNewSegment: boolean = false;
  createNewSubSegment: boolean = false;

  updateSelectedSegment: boolean = false;

  processing = this.pageMode === PageMode.Edit;

  segments: SegmentResponse[] = [];
  users: SystemUserResponse[] = [];
  accountExecutiveUser: SystemUserResponse[] = [];
  chiefFinancialOfficerUser: SystemUserResponse[] = [];
  businessFinancialControllerUser: SystemUserResponse[] = [];
  groupFinancialControllerUser: SystemUserResponse[] = [];
  financialDirectorUser: SystemUserResponse[] = [];

  constructor(
    public router: Router,
    private snackBar: SnackBarService,
    private segmentManagementValidator: SegmentManagementValidator,
    private segmentManagementService: SegmentManagementServiceAbstract,
    private serviceErrorHandler: ServiceErrorHandler,
    private userManagementService : UserManagementServiceAbstract,
    private cd : ChangeDetectorRef
  ){
    const parameters: any = router.GetNavigationParameter(
      DetailedSegmentMaintainComponent
    );

    if (parameters) {
      this.existingDetailedSegments = parameters.existingDetailedSegments;
      this.selectedDetailedSegment = parameters.selectedDetailedSegment;

      if (this.selectedDetailedSegment) {
        this.pageMode = PageMode.Edit;
        this.pageModeText = 'Edit';
        this.submitButtonText = 'Update';
      } else {
        this.pageMode = PageMode.Add;
        this.pageModeText = 'Create new';
        this.submitButtonText = 'Create';
      }
    }
    else{
      router.NavigateTo.DetailedSegment();
    }

    this.detailedSegmentForm = new FormGroup({});
  }

  ngOnInit(): void {
    this.setupForm();
    this.getSegment();
    this.getSystemUser();
  }

  ngOnDestroy(): void {
    this.cd.detach();
  }

  confirmNavigateAway(): boolean {
    return (
      this.detailedSegmentForm.touched && this.detailedSegmentForm.dirty && this.useGuard
    );
  }

  get createDetailedSegmentCommand() {
    return {
      segmentId: this.detailedSegmentForm.controls['segmentId'].value,
      segmentName: this.createNewSegment ? this.detailedSegmentForm.controls['segmentName'].value : null,
      subSegmentId: this.detailedSegmentForm.controls['subSegmentId'].value,
      subSegmentName: this.createNewSubSegment ? this.detailedSegmentForm.controls['subSegmentName'].value : null,
      subSegmentCostCenter: this.createNewSubSegment ? this.detailedSegmentForm.controls['subSegmentCostCenter'].value : null,
      subSegmentAccountExecutiveUserId: this.createNewSubSegment ? this.detailedSegmentForm.controls['subSegmentAccountExecutiveUserId'].value : null,
      subSegmentChiefFinancialOfficerUserId: this.createNewSubSegment ? this.detailedSegmentForm.controls['subSegmentChiefFinancialOfficerUserId'].value : null,
      subSegmentBusinessFinancialControllerUserId: this.createNewSubSegment ? this.detailedSegmentForm.controls['subSegmentBusinessFinancialControllerUserId'].value : null,
      subSegmentGroupFinancialControllerUserId: this.createNewSubSegment ? this.detailedSegmentForm.controls['subSegmentGroupFinancialControllerUserId'].value : null,
      subSegmentFinancialDirectorUserId: this.createNewSubSegment ? this.detailedSegmentForm.controls['subSegmentFinancialDirectorUserId'].value : null,
    } as CreateDetailedSegmentCommand;
  }

  get updateDetailedSegmentCommand() {
    const formSegmentId = +this.detailedSegmentForm.controls['segmentId'].value;
    const formSubSegmentId = +this.detailedSegmentForm.controls['subSegmentId'].value;
    return {
      segmentId: formSegmentId,
      segmentName: this.createNewSegment || formSegmentId === this.selectedDetailedSegment?.segmentId ? this.detailedSegmentForm.controls['segmentName'].value : null,
      subSegmentId: formSubSegmentId,
      subSegmentName: this.createNewSubSegment ? this.detailedSegmentForm.controls['subSegmentName'].value : null,
      subSegmentCostCenter: this.createNewSubSegment ? this.detailedSegmentForm.controls['subSegmentCostCenter'].value : null,
      subSegmentAccountExecutiveUserId: this.createNewSubSegment ? this.detailedSegmentForm.controls['subSegmentAccountExecutiveUserId'].value : null,
      subSegmentChiefFinancialOfficerUserId: this.createNewSubSegment ? this.detailedSegmentForm.controls['subSegmentChiefFinancialOfficerUserId'].value : null,
      subSegmentBusinessFinancialControllerUserId: this.createNewSubSegment ? this.detailedSegmentForm.controls['subSegmentBusinessFinancialControllerUserId'].value : null,
      subSegmentGroupFinancialControllerUserId: this.createNewSubSegment ? this.detailedSegmentForm.controls['subSegmentGroupFinancialControllerUserId'].value : null,
      subSegmentFinancialDirectorUserId: this.createNewSubSegment ? this.detailedSegmentForm.controls['subSegmentFinancialDirectorUserId'].value : null,
    } as UpdateDetailedSegmentCommand;
  }

  setupForm() {
    this.detailedSegmentForm = new FormGroup({
      segmentId: new FormControl(this.selectedDetailedSegment ? this.selectedDetailedSegment.segmentId : undefined, [Validators.required, Validators.min(0)]),
      segmentName: new FormControl(this.selectedDetailedSegment ? this.selectedDetailedSegment.segmentName : ''),
      createSubSegment: new FormControl(this.selectedDetailedSegment?.subSegmentId ? this.selectedDetailedSegment.subSegmentId > 0 ? 1 : 0 : 0),
      subSegmentId: new FormControl(this.selectedDetailedSegment ? this.selectedDetailedSegment.subSegmentId : undefined),
      subSegmentName: new FormControl(this.selectedDetailedSegment ? this.selectedDetailedSegment.subSegmentName : ''),
      subSegmentCostCenter: new FormControl(this.selectedDetailedSegment ? this.selectedDetailedSegment.subSegmentCostCenter : ''),
      subSegmentAccountExecutiveUserId: new FormControl(this.selectedDetailedSegment ? this.selectedDetailedSegment.subSegmentAccountExecutiveUserId : undefined),
      subSegmentChiefFinancialOfficerUserId: new FormControl(this.selectedDetailedSegment ? this.selectedDetailedSegment.subSegmentChiefFinancialOfficerUserId : undefined),
      subSegmentBusinessFinancialControllerUserId: new FormControl(this.selectedDetailedSegment ? this.selectedDetailedSegment.subSegmentBusinessFinancialControllerUserId : undefined),
      subSegmentGroupFinancialControllerUserId: new FormControl(this.selectedDetailedSegment ? this.selectedDetailedSegment.subSegmentGroupFinancialControllerUserId : undefined),
      subSegmentFinancialDirectorUserId: new FormControl(this.selectedDetailedSegment ? this.selectedDetailedSegment.subSegmentFinancialDirectorUserId : undefined)
    });

    if(this.selectedDetailedSegment){
      const { segmentId, subSegmentId } = this.selectedDetailedSegment;

      if(segmentId){
        if(segmentId > 0){
          this.detailedSegmentForm.controls['segmentName'].setValidators([
            Validators.required,
            this.segmentManagementValidator.duplicateDetailedSegmentNameValidator(
              this.detailedSegmentForm,
              this.existingDetailedSegments
            ),
          ]);
          this.cd.detectChanges();
        } else {
          this.detailedSegmentForm.controls['segmentName'].clearValidators();
          this.detailedSegmentForm.controls['segmentName'].updateValueAndValidity();
        }

        this.detailedSegmentForm.updateValueAndValidity();
      }

      if(subSegmentId){
        this.createNewSubSegment = subSegmentId > 0 ? true : false;
        if(this.createNewSubSegment){
          this.detailedSegmentForm.controls['subSegmentName'].setValidators([
            Validators.required
          ]);
          this.detailedSegmentForm.controls['subSegmentAccountExecutiveUserId'].setValidators([
            Validators.required,
            Validators.min(1)
          ]);
          this.detailedSegmentForm.controls['subSegmentChiefFinancialOfficerUserId'].setValidators([
            Validators.required,
            Validators.min(1)
          ]);
          this.detailedSegmentForm.controls['subSegmentBusinessFinancialControllerUserId'].setValidators([
            Validators.required,
            Validators.min(1)
          ]);
          this.detailedSegmentForm.controls['subSegmentGroupFinancialControllerUserId'].setValidators([
            Validators.required,
            Validators.min(1)
          ]);
          this.detailedSegmentForm.controls['subSegmentFinancialDirectorUserId'].setValidators([
            Validators.required,
            Validators.min(1)
          ]);
        } else {
          this.detailedSegmentForm.controls['subSegmentName'].clearValidators();
          this.detailedSegmentForm.controls['subSegmentAccountExecutiveUserId'].clearValidators();
          this.detailedSegmentForm.controls['subSegmentChiefFinancialOfficerUserId'].clearValidators();
          this.detailedSegmentForm.controls['subSegmentBusinessFinancialControllerUserId'].clearValidators();
          this.detailedSegmentForm.controls['subSegmentGroupFinancialControllerUserId'].clearValidators();
          this.detailedSegmentForm.controls['subSegmentFinancialDirectorUserId'].clearValidators();
          this.detailedSegmentForm.controls['subSegmentName'].updateValueAndValidity();
          this.detailedSegmentForm.controls['subSegmentAccountExecutiveUserId'].updateValueAndValidity();
          this.detailedSegmentForm.controls['subSegmentChiefFinancialOfficerUserId'].updateValueAndValidity();
          this.detailedSegmentForm.controls['subSegmentBusinessFinancialControllerUserId'].updateValueAndValidity();
          this.detailedSegmentForm.controls['subSegmentGroupFinancialControllerUserId'].updateValueAndValidity();
          this.detailedSegmentForm.controls['subSegmentFinancialDirectorUserId'].updateValueAndValidity();
        }

        this.detailedSegmentForm.updateValueAndValidity();
      }
      else{
        this.createNewSubSegment = false;
        this.detailedSegmentForm.controls['subSegmentName'].clearValidators();
        this.detailedSegmentForm.controls['subSegmentAccountExecutiveUserId'].clearValidators();
        this.detailedSegmentForm.controls['subSegmentChiefFinancialOfficerUserId'].clearValidators();
        this.detailedSegmentForm.controls['subSegmentBusinessFinancialControllerUserId'].clearValidators();
        this.detailedSegmentForm.controls['subSegmentGroupFinancialControllerUserId'].clearValidators();
        this.detailedSegmentForm.controls['subSegmentFinancialDirectorUserId'].clearValidators();
        this.detailedSegmentForm.controls['subSegmentName'].updateValueAndValidity();
        this.detailedSegmentForm.controls['subSegmentAccountExecutiveUserId'].updateValueAndValidity();
        this.detailedSegmentForm.controls['subSegmentChiefFinancialOfficerUserId'].updateValueAndValidity();
        this.detailedSegmentForm.controls['subSegmentBusinessFinancialControllerUserId'].updateValueAndValidity();
        this.detailedSegmentForm.controls['subSegmentGroupFinancialControllerUserId'].updateValueAndValidity();
        this.detailedSegmentForm.controls['subSegmentFinancialDirectorUserId'].updateValueAndValidity();
      }
    }

    this.detailedSegmentForm.controls['segmentId'].valueChanges.subscribe((value) => {
      if(+value === 0){
        this.createNewSegment = true;
        this.updateSelectedSegment = false;
        this.detailedSegmentForm.controls['segmentName'].setValidators([
          Validators.required,
          this.segmentManagementValidator.duplicateDetailedSegmentNameValidator(
            this.detailedSegmentForm,
            this.existingDetailedSegments
          ),
        ]);
        this.detailedSegmentForm.controls['segmentName'].patchValue('');
      }
      else {
        if(+value !== this.selectedDetailedSegment?.segmentId){
          this.createNewSegment = false;
          this.updateSelectedSegment = true;
          this.detailedSegmentForm.controls['segmentName'].clearValidators();
          this.detailedSegmentForm.controls['segmentName'].updateValueAndValidity();
          this.detailedSegmentForm.controls['segmentName'].patchValue('');
        }
      }

      if(this.pageMode === PageMode.Edit){
        if(+value === this.selectedDetailedSegment?.segmentId){
          this.createNewSegment = true;
          this.updateSelectedSegment = false;
          this.detailedSegmentForm.controls['segmentName'].setValidators([
            Validators.required,
            this.segmentManagementValidator.duplicateDetailedSegmentNameValidator(
              this.detailedSegmentForm,
              this.existingDetailedSegments
            ),
          ]);
          this.detailedSegmentForm.controls['segmentName'].patchValue(this.selectedDetailedSegment.segmentName);
        }
      }

      this.detailedSegmentForm.updateValueAndValidity();
    });

    this.detailedSegmentForm.controls['createSubSegment'].valueChanges.subscribe((value) => {
      if(+value === 1){
        this.createNewSubSegment = true;
        this.detailedSegmentForm.controls['subSegmentName'].setValidators([
          Validators.required
        ]);
        this.detailedSegmentForm.controls['subSegmentAccountExecutiveUserId'].setValidators([
          Validators.required,
          Validators.min(1)
        ]);
        this.detailedSegmentForm.controls['subSegmentChiefFinancialOfficerUserId'].setValidators([
          Validators.required,
          Validators.min(1)
        ]);
        this.detailedSegmentForm.controls['subSegmentBusinessFinancialControllerUserId'].setValidators([
          Validators.required,
          Validators.min(1)
        ]);
        this.detailedSegmentForm.controls['subSegmentGroupFinancialControllerUserId'].setValidators([
          Validators.required,
          Validators.min(1)
        ]);
        this.detailedSegmentForm.controls['subSegmentFinancialDirectorUserId'].setValidators([
          Validators.required,
          Validators.min(1)
        ]);
      }
      else {
        this.createNewSubSegment = false;
        this.detailedSegmentForm.controls['subSegmentName'].clearValidators();
        this.detailedSegmentForm.controls['subSegmentAccountExecutiveUserId'].clearValidators();
        this.detailedSegmentForm.controls['subSegmentChiefFinancialOfficerUserId'].clearValidators();
        this.detailedSegmentForm.controls['subSegmentBusinessFinancialControllerUserId'].clearValidators();
        this.detailedSegmentForm.controls['subSegmentGroupFinancialControllerUserId'].clearValidators();
        this.detailedSegmentForm.controls['subSegmentFinancialDirectorUserId'].clearValidators();
        this.detailedSegmentForm.controls['subSegmentName'].updateValueAndValidity();
        this.detailedSegmentForm.controls['subSegmentAccountExecutiveUserId'].updateValueAndValidity();
        this.detailedSegmentForm.controls['subSegmentChiefFinancialOfficerUserId'].updateValueAndValidity();
        this.detailedSegmentForm.controls['subSegmentBusinessFinancialControllerUserId'].updateValueAndValidity();
        this.detailedSegmentForm.controls['subSegmentGroupFinancialControllerUserId'].updateValueAndValidity();
        this.detailedSegmentForm.controls['subSegmentFinancialDirectorUserId'].updateValueAndValidity();
      }

      this.detailedSegmentForm.updateValueAndValidity();
    });

    if(this.pageMode == PageMode.Edit){
      this.detailedSegmentForm.controls['createSubSegment'].disable();
    }
  }

  getSegment() {
    this.isLoading = true;
    this.segmentManagementService
      .getAllSegments()
      .subscribe({
        next: (result: SegmentResponse[]) => {
          this.segments = result;
        },
        error: (error: HttpErrorResponse) => {
          this.serviceErrorHandler.displayHttpErrorDialog(
            this,
            error,
            Constants.ErrorMessages
          );
        },
      })
      .add(() => {
        this.isLoading = false;
      });
  }

  getSystemUser() {
    this.isLoading = true;
    this.userManagementService
      .getAllSystemUsers()
      .subscribe({
        next: (result: SystemUserResponse[]) => {
          console.log(Role.Admin.trim().toLowerCase())
          this.users = result.filter(x => x.isAbsaEmployee === true && x.isActive === true);
          this.accountExecutiveUser = this.users.filter(x => x.roles?.map(z => z.name.trim().toLowerCase())?.includes(Role.ExcoMember.toLowerCase()))
          this.chiefFinancialOfficerUser = this.users.filter(x => x.roles?.map(z => z.name.trim().toLowerCase())?.includes(Role.ChiefFinancialOfficer.toLowerCase()))
          this.businessFinancialControllerUser = this.users.filter(x => x.roles?.map(z => z.name.trim().toLowerCase())?.includes(Role.BusinessFinancialController.toLowerCase()))
          this.groupFinancialControllerUser = this.users.filter(x => x.roles?.map(z => z.name.trim().toLowerCase())?.includes(Role.GroupFinancialController.toLowerCase()))
          this.financialDirectorUser = this.users.filter(x => x.roles?.map(z => z.name.trim().toLowerCase())?.includes(Role.FinancialDirector.toLowerCase()))
        },
        error: (error: HttpErrorResponse) => {
          this.serviceErrorHandler.displayHttpErrorDialog(
            this,
            error,
            Constants.ErrorMessages
          );
        },
      })
      .add(() => {
        this.isLoading = false;
      });
  }

  onSubmit() {
    Object.values(this.detailedSegmentForm.controls).forEach((control) => {
      control.markAsTouched();
    });

    if (this.detailedSegmentForm.valid) {
      if (this.pageMode === PageMode.Add) {
        this.processCreate();
      } else {
        this.processEdit();
      }
    }
  }

  processCreate() {
    this.isLoading = true;
    this.segmentManagementService
      .createDetailedSegment(this.createDetailedSegmentCommand)
      .subscribe({
        next: () => {
          this.showSuccessMessage();
        },
        error: (error: HttpErrorResponse) => {
          this.serviceErrorHandler.displayHttpErrorDialog(
            this,
            error,
            Constants.ErrorMessages
          );
        },
      })
      .add(() => {
        this.isLoading = false;
      });
  }

  processEdit() {
    const formServiceTypeId = +this.detailedSegmentForm.controls['segmentId'].value;
    const formServiceSubCategoryId = +this.detailedSegmentForm.controls['createSubSegment'].value === 1
      ? this.detailedSegmentForm.controls['subSegmentId'].value : null;
    if (this.selectedDetailedSegment?.segmentId) {
      this.isLoading = true;
      this.segmentManagementService
        .updateDetailedSegment(
          formServiceTypeId,
          this.updateDetailedSegmentCommand,
          formServiceSubCategoryId
        )
        .subscribe({
          next: () => {
            this.showSuccessMessage();
          },
          error: (error: HttpErrorResponse) => {
            this.serviceErrorHandler.displayHttpErrorDialog(
              this,
              error,
              Constants.ErrorMessages
            );
          },
        })
        .add(() => {
          this.isLoading = false;
        });
    }
  }

  onCancel() {
    this.NavigateBackToView();
  }

  NavigateBackToView() {
    this.router.NavigateTo.DetailedSegment();
  }

  showSuccessMessage() {
    this.useGuard = false;
    this.NavigateBackToView();

    this.snackBar.open(
      `Detailed Service Type successfully ${
        this.pageMode === PageMode.Add ? 'created' : 'updated'
      }`
    );
  }

  protected readonly PageMode = PageMode;

  //Ng Classes
  public getUpdateSegment(segmentId: number){
    return this.pageMode === PageMode.Edit ? {
      primaryRedTextColor: segmentId === this.selectedDetailedSegment?.segmentId,
      blackTextColor: segmentId !== this.selectedDetailedSegment?.segmentId,
    } : {
      blackTextColor: true
    }
  }
}
