export const AttachmentConstants = {
  allowedFileExtensions: ['pdf'],
  maxFileSizeBinary: 5.24288e6,
  fileSizeErrorMessage: 'The file is larger than 5MB.',
  fileTypeErrorMessage:
    'The file type selected is not supported. Please select PDF file type only.',
};

export abstract class Constants {
  public static ErrorMessages: { [key: string]: string } = {
    'Role.IdDoesNotExist': 'The value for the requested ID does not exist.',
  }
}
