<form [formGroup]="fileUploadAttestForm" fxLayout="column" fxLayoutGap="2px">
    <!--Letter_Engagement-->
    <br>
    <span class="alert">{{ errorMessage }}</span>
    <span class="success">{{ successMessage }}</span>
    <input #fileInput type="file" class="file-input" style="display: none" [accept]="acceptedFileExtensions"
           (change)="attachFile($event)" />

    <div class="row document-type">
        <div class="col-xs-12">
            <button mat-mini-fab color="primary" class="upload-btn" action="file" (click)="onSelectFile()"
                    type="submit">
                <mat-icon>attach_file</mat-icon>
            </button>
          Upload Letter of Engagement (Only PDF files are accepted):
        </div>
      <mat-error *ngIf="fileUploadAttestForm.controls['fileName'].hasError('required')">
        Please attach a letter of engagement.
      </mat-error>
    </div>


    <br>
    <mat-checkbox color="primary" formControlName="attestation" class="mb-1">
      I confirm that all the information captured on the form is correct and take full responsibility for ensuring that all details submitted in this nas request is contained in the signed letter of engagement.
    </mat-checkbox>
    <mat-error *ngIf="fileUploadAttestForm.controls['attestation'].hasError('required')">
      Please attest that information captured on the form is true.
    </mat-error>
    <br>

</form>
