<!-- Page footer -->
<div class="footer">
  <div class="container">
    <footer id="site-footer" role="contentinfo" class="header-footer-group">
      <div class="container-fluid">
        <div class="row terms">
          <div class="col-12 col-sm-12">
            <p>
              <a href="https://www.absa.africa/legal/terms-of-use"  class="" target="_blank">Terms Of Use</a>&nbsp;|&nbsp;
              <a href="https://www.absa.africa/legal/privacy-statement" class="" target="_blank">Privacy Statement</a>&nbsp;|&nbsp;
              <a href="https://www.absa.africa/legal/privacy-statement" class="" target="_blank">Cookies Policy</a>&nbsp;|
              <a href="https://www.absa.africa/sitemap" class="" target="_blank">Sitemap</a> | Absa Group Limited | Incorporated in the
              Republic of South Africa | Registration number: 1986/003934/06 | Authorised financial services and
              registered
              credit provider (NCRCP7) | JSE share code:&nbsp;ABG | ISIN:&nbsp;ZAE000255915
            </p>
          </div>
        </div>
      </div>
    </footer>
  </div>
</div>
