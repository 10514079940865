<div class="flex flex-col gap-[32px] items-stretch content-stretch p-3">
  <div fxLayout="column" fxLayoutGap="8px">
    <div class="mat-display-4">{{ pageModeText }} Detailed Segment</div>
    <div class="mat-subheading-1">
      Please capture all required information below.
    </div>
  </div>
  <mat-divider></mat-divider>

  <form [formGroup]="detailedSegmentForm" class="flex flex-col gap-1" *ngIf="!processing">
    <div class="flex flex-row gap-2">
      <mat-form-field fxFlex appearance="fill">
        <mat-label>Select Or {{pageMode === PageMode.Add ? 'Create New' : 'Update'}}  Service Type</mat-label>
        <mat-select formControlName="segmentId">
          <mat-option [value]="0" *ngIf="segments.length > 5">
          <span class="greenTextColor">
            Create new segment
          </span>
          </mat-option>
          <mat-option *ngFor="let item of segments" [value]="item.id">
           <span [ngClass]="getUpdateSegment(item.id)">
             {{ item.name }}
           </span>
          </mat-option>
          <mat-option [value]="0">
          <span class="greenTextColor">
            Create new segment
          </span>
          </mat-option>
        </mat-select>
        <mat-error *ngIf="detailedSegmentForm.controls['segmentId'].hasError('required')">
          Please select a segment in the indicated field
        </mat-error>
      </mat-form-field>

      <mat-form-field fxFlex appearance="fill" *ngIf="(createNewSegment || pageMode === PageMode.Edit) && !updateSelectedSegment">
        <mat-label>Segment name</mat-label>
        <input
          matInput
          maxlength="100"
          formControlName="segmentName"
          autocomplete="off"
        />
        <mat-error
          *ngIf="detailedSegmentForm.controls['segmentName'].hasError('duplicate')"
        >
          The captured segment already exists
        </mat-error>
        <mat-error *ngIf="detailedSegmentForm.controls['segmentName'].hasError('required')">
          Please capture segment in the indicated field
        </mat-error>
      </mat-form-field>
    </div>

    <mat-divider></mat-divider>

    <mat-form-field fxFlex appearance="fill">
      <mat-label>Do you want to create a sub segment</mat-label>
      <mat-select formControlName="createSubSegment">
        <mat-option [value]="0">
          <span class="redTextColor">
            No
          </span>
        </mat-option>
        <mat-option [value]="1">
          <span class="greenTextColor">
           Yes
          </span>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div *ngIf="createNewSubSegment" class="flex flex-col gap-2">
      <div class="flex flex-row gap-2">
        <mat-form-field fxFlex appearance="fill">
          <mat-label>Sub Segment name</mat-label>
          <input
            matInput
            maxlength="100"
            formControlName="subSegmentName"
            autocomplete="off"
          />
          <mat-error
            *ngIf="detailedSegmentForm.controls['subSegmentName'].hasError('duplicate')"
          >
            The captured sub segment already exists
          </mat-error>
          <mat-error *ngIf="detailedSegmentForm.controls['subSegmentName'].hasError('required')">
            Please capture sub segment in the indicated field
          </mat-error>
        </mat-form-field>

        <mat-form-field fxFlex appearance="fill">
          <mat-label>Cost Center</mat-label>
          <input
            matInput
            maxlength="100"
            formControlName="subSegmentCostCenter"
            autocomplete="off"
          />

          <mat-error *ngIf="detailedSegmentForm.controls['subSegmentCostCenter'].hasError('required')">
            Please capture cost center in the indicated field
          </mat-error>
        </mat-form-field>
      </div>

      <div class="flex flex-row gap-2">
        <mat-form-field appearance="fill" fxFlex>
          <mat-label>Account Executive</mat-label>
          <mat-select formControlName="subSegmentAccountExecutiveUserId">
            <mat-option *ngFor="let item of accountExecutiveUser" [value]="item.id">
              {{item.fullName }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="detailedSegmentForm.controls['subSegmentAccountExecutiveUserId'].hasError('required')">
            Please select a account executive in the indicated field
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" fxFlex>
          <mat-label>Chief Financial Officer</mat-label>
          <mat-select formControlName="subSegmentChiefFinancialOfficerUserId">
            <mat-option *ngFor="let item of chiefFinancialOfficerUser" [value]="item.id">
              {{item.fullName }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="detailedSegmentForm.controls['subSegmentChiefFinancialOfficerUserId'].hasError('required')">
            Please select a chief financial officer in the indicated field
          </mat-error>
        </mat-form-field>
      </div>

      <div class="flex flex-row gap-2">
        <mat-form-field appearance="fill" fxFlex>
          <mat-label>Business Financial Controller</mat-label>
          <mat-select formControlName="subSegmentBusinessFinancialControllerUserId">
            <mat-option *ngFor="let item of businessFinancialControllerUser" [value]="item.id">
              {{item.fullName }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="detailedSegmentForm.controls['subSegmentBusinessFinancialControllerUserId'].hasError('required')">
            Please select a business financial controller in the indicated field
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" fxFlex>
          <mat-label>Group Financial Controller</mat-label>
          <mat-select formControlName="subSegmentGroupFinancialControllerUserId">
            <mat-option *ngFor="let item of groupFinancialControllerUser" [value]="item.id">
              {{item.fullName }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="detailedSegmentForm.controls['subSegmentGroupFinancialControllerUserId'].hasError('required')">
            Please select a group financial controller in the indicated field
          </mat-error>
        </mat-form-field>
      </div>

      <mat-form-field appearance="fill" fxFlex>
        <mat-label>Financial Director</mat-label>
        <mat-select formControlName="subSegmentFinancialDirectorUserId">
          <mat-option *ngFor="let item of financialDirectorUser" [value]="item.id">
            {{item.fullName }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="detailedSegmentForm.controls['subSegmentFinancialDirectorUserId'].hasError('required')">
          Please select a financial director in the indicated field
        </mat-error>
      </mat-form-field>

    </div>
  </form>

  <div fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="16px">
    <button
      mat-stroked-button
      color="primary"
      class="mat-stroked-button"
      (click)="onCancel()"
      [disabled]="isLoading"
    >
      Cancel
    </button>
    <button
      mat-flat-button
      color="primary"
      class="mat-flat-button"
      (click)="onSubmit()"
      [disabled]="isLoading"
    >
      {{ submitButtonText }}
    </button>
  </div>
</div>
