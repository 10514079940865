<form [formGroup]="countryContractForm" fxLayout="column" fxLayoutGap="2px">
    <!--Country-->
    <mat-form-field fxFlex appearance="fill">
        <mat-label >{{ "Country" }}</mat-label>
        <mat-select placeholder="Country" formControlName="countryId">
            <ng-container>
                <mat-option *ngFor="let p of countries" [value]="p.id">
                    {{ p.name }}
                </mat-option>
            </ng-container>
        </mat-select>
      <mat-error *ngIf="countryContractForm.controls['countryId'].hasError('required')">
        The Country field is required.
      </mat-error>
    </mat-form-field>

    <!--Contract_Currency-->
    <mat-form-field fxFlex appearance="fill">
        <mat-select placeholder="Contract Currency" formControlName="contractCurrencyId">
            <mat-option>{{ "Contract Currency" }}</mat-option>
            <mat-option *ngFor="let p of countryCurrencies" [value]="p.id">
                {{ p.name }}
            </mat-option>
        </mat-select>
      <mat-error *ngIf="countryContractForm.controls['contractCurrencyId'].hasError('required')">
        The Country Currency field is required.
      </mat-error>
    </mat-form-field>

    <!--Contract_Value-->
    <mat-form-field fxFlex appearance="fill">
        <mat-label >{{ "Contract Value" }}</mat-label>
        <input matInput type="text" placeholder="0.00" formControlName="contractValue" (blur)="formatCurrency_ContractValue($event)" (focus)="unFormatCurrency_ContractValue($event)">
      <mat-error *ngIf="countryContractForm.controls['contractValue'].hasError('required')">
        The Contract Value field is required.
      </mat-error>
      <mat-error *ngIf="countryContractForm.controls['contractValue'].hasError('min')">
        The Contract Value field is required.
      </mat-error>
    </mat-form-field>

    <!--Exchange Rate-->
    <mat-form-field fxFlex appearance="fill" *ngIf="isExchangeRateDateVisible">
        <mat-label >{{ "Exchange Rate" }}</mat-label>
        <input matInput type="text" formControlName="exchangeRate" placeholder="0.00" (blur)="formatCurrency_ExchangeRate($event)" (focus)="unFormatCurrency_ExchangeRate($event)">
      <mat-error *ngIf="countryContractForm.controls['exchangeRate'].hasError('required')">
        The Exchange Rate field is required.
      </mat-error>
      <mat-error *ngIf="countryContractForm.controls['exchangeRate'].hasError('min')">
        The Exchange Rate field is required.
      </mat-error>
    </mat-form-field>

    <!--ExchangeRateDate-->
    <mat-form-field fxFlex appearance="fill" *ngIf="isExchangeRateDateVisible">
        <mat-label >{{ "Exchange Rate Date" }}</mat-label>
        <input
          matInput
          [matDatepicker]="dateToPicker"
          [min]="minDate"
          [max]="maxDate"
          formControlName="exchangeRateDate"
          readonly
          (click)="dateToPicker.open()"
        />
        <mat-datepicker-toggle
                matIconSuffix
                [for]="dateToPicker"
        ></mat-datepicker-toggle>

        <mat-datepicker #dateToPicker>
            <mat-datepicker-actions>
                <button mat-button (click)="clearExchangeRateDate(); dateToPicker.close()">
                    Clear
                </button>
                <button mat-button matDatepickerCancel>Cancel</button>
                <button mat-raised-button color="primary" matDatepickerApply>
                    OK
                </button>
            </mat-datepicker-actions>
        </mat-datepicker>
      <mat-error *ngIf="countryContractForm.controls['exchangeRateDate'].hasError('required')">
        The Exchange Rate Date field is required.
      </mat-error>
    </mat-form-field>

    <!--ZAR_Equivalent-->
    <mat-form-field fxFlex appearance="fill" *ngIf="isZAREquivalent">
        <mat-label >{{ "ZAR Equivalent" }}</mat-label>
        <input matInput type="text" formControlName="zAREquivalent" readonly="true" >
      <mat-error *ngIf="countryContractForm.controls['zAREquivalent'].hasError('required')">
        The ZAR Equivalent field is required.
      </mat-error>
      <mat-error *ngIf="countryContractForm.controls['zAREquivalent'].hasError('min')">
        The ZAR Equivalent field is required.
      </mat-error>
    </mat-form-field>

</form>
