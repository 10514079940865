import {Component, Input, OnInit} from '@angular/core';
import {RequestMaintainAuditFirm, RequestResponse} from "../../../models";
import {FormControl, FormGroup, RequiredValidator, Validators} from "@angular/forms";
import {HttpErrorResponse} from "@angular/common/http";
import {Constants} from "../../../models";
import {
  MasterDataConfigurationServiceAbstract
} from "../../../../master-data-configuration/services/master-data-configuration.service.abstract";
import {ServiceErrorHandler} from "../../../../../service/error-handler/error-handler.service";
import {AuditFirmResponse} from "../../../models";

@Component({
  selector: 'app-request-maintain-audit-firm',
  templateUrl: './request-maintain-audit-firm.component.html',
  styleUrls: ['./request-maintain-audit-firm.component.scss']
})
export class RequestMaintainAuditFirmComponent
    implements OnInit
{

  @Input()
  selectedRequest?: RequestResponse;

  isLoading = false;
  auditFirmForm: FormGroup;

  auditFirms: AuditFirmResponse[] = []

  constructor(
      private masterDataConfigurationService: MasterDataConfigurationServiceAbstract,
      private serviceErrorHandler: ServiceErrorHandler
  ) {
    this.auditFirmForm = new FormGroup({});
  }

  ngOnInit(): void {
    this.setupForm();
    this.getAuditFirms();
  }


  setupForm(): void {
    console.log('this.selectedRequest.assignedAuditPartner', this.selectedRequest?.assignedAuditPartner)
    this.auditFirmForm = new FormGroup({
      auditFirmId: new FormControl(this.selectedRequest ? this.selectedRequest.auditFirm?.id : null, Validators.required),
      assignedAuditPartnerId: new FormControl(this.selectedRequest ? this.selectedRequest.assignedAuditPartnerUser?.id : null),
      assignedAuditPartnerName: new FormControl(this.selectedRequest ? this.selectedRequest?.assignedAuditPartner : '',Validators.required),
    });

    this.auditFirmForm.controls['auditFirmId'].valueChanges.subscribe((value) => {
      this.updateAssignedAuditPartnerUser(+value);
    });

    this.updateAssignedAuditPartnerUser(this.selectedRequest?.auditFirm?.id);
  }

  private updateAssignedAuditPartnerUser(value?: number){
    if(value){
      const assignedAuditPartnerUser = this.auditFirms
        .find((x) => x.id === value)?.User[0]!;

      this.auditFirmForm.controls['assignedAuditPartnerId'].patchValue(assignedAuditPartnerUser?.id);
    }
  }

  clearForm(): void {
    this.auditFirmForm = new FormGroup({
      auditFirmId: new FormControl(null, Validators.required),
      assignedAuditPartnerId: new FormControl(null),
      assignedAuditPartnerName: new FormControl('', Validators.required),
    });

    this.auditFirmForm.controls['auditFirmId'].valueChanges.subscribe((value) => {

      const assignedAuditPartnerUser = this.auditFirms
        .find((x) => x.id === +value)?.User[0]!;

      this.auditFirmForm.controls['assignedAuditPartnerId'].patchValue(assignedAuditPartnerUser?.id);

    });
  }

  getAuditFirms(){
    this.isLoading = true;
    this.masterDataConfigurationService
        .getAllAudiFirm()
        .subscribe({
          next: (result: AuditFirmResponse[]) => {
            this.auditFirms = result?.filter(x => x?.isActive);
            this.updateAssignedAuditPartnerUser(this.selectedRequest?.auditFirm?.id);
          },
          error: (error: HttpErrorResponse) => {
            this.serviceErrorHandler.displayHttpErrorDialog(
                this,
                error,
                Constants.ErrorMessages
            );
          },
        })
        .add(() => {
          this.isLoading = false;
        });
  }

  fetchData() :RequestMaintainAuditFirm {
    return {
      auditFirmId: this.auditFirmForm.controls['auditFirmId'].value ?
        Number(this.auditFirmForm.controls['auditFirmId'].value) : null,
      assignedAuditPartnerId: this.auditFirmForm.controls['assignedAuditPartnerId'].value ?
        Number(this.auditFirmForm.controls['assignedAuditPartnerId'].value) : null,
      assignedAuditPartner: this.auditFirmForm.controls['assignedAuditPartnerName'].value
    } as RequestMaintainAuditFirm;
  }
}
