import { Role } from "../enums";

export class NavigationRoutes{

  static Maintain = class {
    static path = 'maintain';
    static fullPath = 'maintain';
    static title = 'Maintain';
    static allowedRoles = [Role.Admin];
  };

  static NotFound = class {
    static path = 'not-found';
    static fullPath = 'not-found';
    static title = 'Not Found';
    static allowedRoles = undefined;
  };

  static NotAuthorized = class {
    static path = 'not-authorized';
    static fullPath = 'not-authorized';
    static title = 'Not Authorized';
    static allowedRoles = undefined;
  };

  static Secured = class {
    static path = 'secured';
    static fullPath = 'secured';
    static title = 'Secured';
    static allowedRoles = [];
  };

  static LandingPage = class {
    static path = 'home';
    static fullPath = `${NavigationRoutes.Secured.fullPath}/${this.path}`;
    static title = 'Home';
    static allowedRoles = undefined;
  };

  static Pages = class {
    static path = ``;
    static fullPath = `${NavigationRoutes.Secured.fullPath}`;
    static title = 'Page';
    static allowedRoles = undefined;
  };

  static Policy = class {
    static path = 'policy';
    static fullPath = `${NavigationRoutes.Pages.fullPath}/${this.path}`;
    static title = 'Policy';
    static allowedRoles = undefined;
  };

  static Admin = class {
    static path = 'admin';
    static fullPath = `${NavigationRoutes.Pages.fullPath}/${this.path}`;
    static title = 'admin';
    static allowedRoles = [Role.Admin, Role.Developer];
  };

  static MaintainPolicy = class {
    static path = `${NavigationRoutes.Policy.path}/${NavigationRoutes.Maintain.path}`;
    static fullPath = `${NavigationRoutes.Pages.fullPath}/${this.path}`;
    static title = `${NavigationRoutes.Maintain.title}`;
    static allowedRoles = [Role.Admin, Role.Developer];
  };

  static UserGuide = class {
    static path = 'user-guide';
    static fullPath = `${NavigationRoutes.Pages.fullPath}/${this.path}`;
    static title = 'User Guide';
    static allowedRoles = [Role.Admin, Role.Developer];
  };

  static MaintainUserGuide = class {
    static path = `${NavigationRoutes.UserGuide.path}/${NavigationRoutes.Maintain.path}`;
    static fullPath = `${NavigationRoutes.Pages.fullPath}/${this.path}`;
    static title = `${NavigationRoutes.Maintain.title}`;
    static allowedRoles = [Role.Admin, Role.Developer];
  };

  static UserGuideDownload = class {
    static path = `${NavigationRoutes.UserGuide.path}/download`;
    static fullPath = `${NavigationRoutes.Pages.fullPath}/${this.path}`;
    static title = 'User Guide Download';
    static allowedRoles = [];
  };

  static Request = class {
    static path = 'request';
    static fullPath = `${NavigationRoutes.Secured.fullPath}/${this.path}`;
    static title = 'Request';
    static allowedRoles = [Role.Admin, Role.Developer];
  };

  static RequestDetail = class {
    static path = 'detail';
    static fullPath = `${NavigationRoutes.Request.fullPath}/${this.path}`;
    static title = 'Request Detail';
    static allowedRoles = [Role.Admin, Role.Developer];
  };

  static RequestDetailEmail = class {
    static path = 'request-details';
    static fullPath = `request-details`;
    static title = 'Request Details Approvals';
    static allowedRoles = undefined;
  };

  static RequestDetailsApproveMail = class {
    static path = 'approve';
    static fullPath = `${NavigationRoutes.RequestDetailEmail.fullPath}/${this.path}`;
    static title = 'Request Details Approve';
    static allowedRoles = undefined;
  }

  static RequestDetailsApproveWithAmendmentMail = class {
    static path = 'approve-with-amendment';
    static fullPath = `${NavigationRoutes.RequestDetailEmail.fullPath}/${this.path}`;
    static title = 'Request Details Approve';
    static allowedRoles = undefined;
  }

  static RequestMade = class {
    static path = 'made';
    static fullPath = `${NavigationRoutes.Request.fullPath}/${this.path}`;
    static title = 'Request Made';
    static allowedRoles = [Role.OnlyWhenNotAdmin];
  };

  static ViewRequest = class {
    static path = 'view';
    static fullPath = `${NavigationRoutes.Request.fullPath}/${this.path}`;
    static title = 'View Request';
    static allowedRoles = undefined;
  };

  static MaintainRequest = class {
    static path = `${NavigationRoutes.Maintain.path}`;
    static fullPath = `${NavigationRoutes.Request.fullPath}/${this.path}`;
    static title = `${NavigationRoutes.Maintain.title}`;
    static allowedRoles = undefined;
  };

  static MaintainValidatorRequest = class {
    static path = `${NavigationRoutes.Maintain.path}/validator`;
    static fullPath = `${NavigationRoutes.Request.fullPath}/${this.path}`;
    static title = `${NavigationRoutes.Maintain.title}`;
    static allowedRoles = undefined;
  };

  static DashBoard = class {
    static path = 'dashboard';
    static fullPath = `${NavigationRoutes.Secured.fullPath}/${this.path}`;
    static title = 'Dashboard';
    static allowedRoles = [Role.Admin, Role.Developer];
  };

  static DashBoardHome = class {
    static path = '';
    static fullPath = `${NavigationRoutes.DashBoard.fullPath}/${this.path}`;
    static title = 'Dashboard Home';
    static allowedRoles = [Role.Admin, Role.Developer];
  }

  static CountryManagement = class {
    static path = 'country-management';
    static fullPath = `${NavigationRoutes.Secured.fullPath}/${this.path}`;
    static title = 'Country Management';
    static allowedRoles = [Role.Admin, Role.Developer];
  };

  static Country = class {
    static path = 'country';
    static fullPath = `${NavigationRoutes.CountryManagement.fullPath}/${this.path}`;
    static title = 'Country';
    static allowedRoles = [Role.Admin, Role.Developer];
  };

  static MaintainCountry = class {
    static path = `${NavigationRoutes.Country.path}/${NavigationRoutes.Maintain.path}`;
    static fullPath = `${NavigationRoutes.CountryManagement.fullPath}/${this.path}`;
    static title = 'Country';
    static allowedRoles = [Role.Admin, Role.Developer];
  };

  static CountryCurrency = class {
    static path = 'country-currency';
    static fullPath = `${NavigationRoutes.CountryManagement.fullPath}/${this.path}`;
    static title = 'Country Currency';
    static allowedRoles = [Role.Admin, Role.Developer];
  }

  static MaintainCountryCurrency = class {
    static path = `${NavigationRoutes.CountryCurrency.path}/${NavigationRoutes.Maintain.path}`;
    static fullPath = `${NavigationRoutes.CountryManagement.fullPath}/${this.path}`;
    static title = 'Country Currency';
    static allowedRoles = [Role.Admin, Role.Developer];
  }

  static PagesManagement = class {
    static path = 'pages-management';
    static fullPath = `${NavigationRoutes.Secured.fullPath}/${this.path}`;
    static title = 'Pages Management';
    static allowedRoles = [Role.Admin, Role.Developer];
  };

  static HomePageTemplate = class {
    static path = 'home';
    static fullPath = `${NavigationRoutes.PagesManagement.fullPath}/${this.path}`;
    static title = 'Home';
    static allowedRoles = [Role.Admin, Role.Developer];
  };

  static MaintainHomePageTemplate = class {
    static path = `${NavigationRoutes.HomePageTemplate.path}/${NavigationRoutes.Maintain.path}`;
    static fullPath = `${NavigationRoutes.PagesManagement.fullPath}/${this.path}`;
    static title = 'Home';
    static allowedRoles = [Role.Admin, Role.Developer];
  };

  static MasterDataConfiguration = class {
    static path = 'master-data-configuration';
    static fullPath = `${NavigationRoutes.Secured.fullPath}/${this.path}`;
    static title = 'Master Data Configuration';
    static allowedRoles = [Role.Admin, Role.Developer];
  };

  static AuditFirm = class {
    static path = 'audit-firm';
    static fullPath = `${NavigationRoutes.MasterDataConfiguration.fullPath}/${this.path}`;
    static title = 'Audit Firm';
    static allowedRoles = [Role.Admin, Role.Developer];
  };

  static MaintainAuditFirm = class {
    static path = `${NavigationRoutes.AuditFirm.path}/${NavigationRoutes.Maintain.path}`;
    static fullPath = `${NavigationRoutes.MasterDataConfiguration.fullPath}/${this.path}`;
    static title = 'Maintain Audit Firm';
    static allowedRoles = [Role.Admin, Role.Developer];
  };

  static Deliverable = class {
    static path = 'deliverable';
    static fullPath = `${NavigationRoutes.MasterDataConfiguration.fullPath}/${this.path}`;
    static title = 'Deliverable';
    static allowedRoles = [Role.Admin, Role.Developer];
  };

  static MaintainDeliverable = class {
    static path = `${NavigationRoutes.Deliverable.path}/${NavigationRoutes.Maintain.path}`;
    static fullPath = `${NavigationRoutes.MasterDataConfiguration.fullPath}/${this.path}`;
    static title = 'Maintain Deliverable';
    static allowedRoles = [Role.Admin, Role.Developer];
  };

  static Reason = class {
    static path = 'reason';
    static fullPath = `${NavigationRoutes.MasterDataConfiguration.fullPath}/${this.path}`;
    static title = 'Reason';
    static allowedRoles = [Role.Admin, Role.Developer];
  };

  static MaintainReason = class {
    static path = `${NavigationRoutes.Reason.path}/${NavigationRoutes.Maintain.path}`;
    static fullPath = `${NavigationRoutes.MasterDataConfiguration.fullPath}/${this.path}`;
    static title = 'Maintain Reason';
    static allowedRoles = [Role.Admin, Role.Developer];
  };

  static SegmentManagement = class {
    static path = 'segment-management';
    static fullPath = `${NavigationRoutes.Secured.fullPath}/${this.path}`;
    static title = 'Segment Management';
    static allowedRoles = [Role.Admin, Role.Developer];
  };

  static Segment = class {
    static path = 'segment';
    static fullPath = `${NavigationRoutes.SegmentManagement.fullPath}/${this.path}`;
    static title = 'Segment';
    static allowedRoles = [Role.Admin, Role.Developer];
  };

  static MaintainSegment = class {
    static path = `${NavigationRoutes.Segment.path}/${NavigationRoutes.Maintain.path}`;
    static fullPath = `${NavigationRoutes.SegmentManagement.fullPath}/${this.path}`;
    static title = 'Maintain Segment';
    static allowedRoles = [Role.Admin, Role.Developer];
  };

  static SubSegment = class {
    static path = 'sub-segment';
    static fullPath = `${NavigationRoutes.SegmentManagement.fullPath}/${this.path}`;
    static title = 'Sub Segment';
    static allowedRoles = [Role.Admin, Role.Developer];
  };

  static MaintainSubSegment = class {
    static path = `${NavigationRoutes.SubSegment.path}/${NavigationRoutes.Maintain.path}`;
    static fullPath = `${NavigationRoutes.SegmentManagement.fullPath}/${this.path}`;
    static title = 'Maintain Sub Segment';
    static allowedRoles = [Role.Admin, Role.Developer];
  };

  static DetailedSegment = class {
    static path = 'detailed-segment';
    static fullPath = `${NavigationRoutes.SegmentManagement.fullPath}/${this.path}`;
    static title = 'Detailed Segment';
    static allowedRoles = [Role.Admin, Role.Developer];
  };

  static MaintainDetailedSegment = class {
    static path = `${NavigationRoutes.DetailedSegment.path}/${NavigationRoutes.Maintain.path}`;
    static fullPath = `${NavigationRoutes.SegmentManagement.fullPath}/${this.path}`;
    static title = 'Maintain Detailed Segment';
    static allowedRoles = [Role.Admin, Role.Developer];
  };

  static ServiceManagement = class {
    static path = 'service-management';
    static fullPath = `${NavigationRoutes.Secured.fullPath}/${this.path}`;
    static title = 'Service Management';
    static allowedRoles = [Role.Admin, Role.Developer];
  };

  static ServiceType = class {
    static path = 'service-type';
    static fullPath = `${NavigationRoutes.ServiceManagement.fullPath}/${this.path}`;
    static title = 'Service Type';
    static allowedRoles = [Role.Admin, Role.Developer];
  };

  static MaintainServiceType = class {
    static path = `${NavigationRoutes.ServiceType.path}/${NavigationRoutes.Maintain.path}`;
    static fullPath = `${NavigationRoutes.ServiceManagement.fullPath}/${this.path}`;
    static title = 'Maintain Service Type';
    static allowedRoles = [Role.Admin, Role.Developer];
  };

  static ServiceCategory = class {
    static path = 'server-category';
    static fullPath = `${NavigationRoutes.ServiceManagement.fullPath}/${this.path}`;
    static title = 'Service Category';
    static allowedRoles = [Role.Admin, Role.Developer];
  };

  static MaintainServiceCategory = class {
    static path = `${NavigationRoutes.ServiceCategory.path}/${NavigationRoutes.Maintain.path}`;
    static fullPath = `${NavigationRoutes.ServiceManagement.fullPath}/${this.path}`;
    static title = 'Maintain Service Category';
    static allowedRoles = [Role.Admin, Role.Developer];
  };

  static ServiceSubCategory = class {
    static path = 'server-sub-category';
    static fullPath = `${NavigationRoutes.ServiceManagement.fullPath}/${this.path}`;
    static title = 'Server Sub Category';
    static allowedRoles = [Role.Admin, Role.Developer];
  };

  static MaintainServiceSubCategory = class {
    static path = `${NavigationRoutes.ServiceSubCategory.path}/${NavigationRoutes.Maintain.path}`;
    static fullPath = `${NavigationRoutes.ServiceManagement.fullPath}/${this.path}`;
    static title = 'Maintain Service Sub Category';
    static allowedRoles = [Role.Admin, Role.Developer];
  };

  static DetailedServiceType = class {
    static path = 'detailed-service-type';
    static fullPath = `${NavigationRoutes.ServiceManagement.fullPath}/${this.path}`;
    static title = 'Detailed Service Type';
    static allowedRoles = [Role.Admin, Role.Developer];
  };

  static MaintainDetailedServiceType = class {
    static path = `${NavigationRoutes.DetailedServiceType.path}/${NavigationRoutes.Maintain.path}`;
    static fullPath = `${NavigationRoutes.ServiceManagement.fullPath}/${this.path}`;
    static title = 'Maintain Service Type';
    static allowedRoles = [Role.Admin, Role.Developer];
  };

  static Auth = class {
    static path = 'auth';
    static fullPath = 'auth';
    static title = 'Auth';
    static allowedRoles = undefined;
  };

  static Login = class {
    static path = `login`;
    static fullPath = `${NavigationRoutes.Auth.fullPath}/${this.path}`;
    static title = 'Login';
    static allowedRoles = [Role.Admin, Role.Developer];
  };

  static UserManagement = class {
    static path = 'user-management';
    static fullPath = `${NavigationRoutes.Secured.fullPath}/${this.path}`;
    static title = 'User Management';
    static allowedRoles = [Role.Admin, Role.Developer];
  };

  static SystemUser = class {
    static path = 'system-user';
    static fullPath = `${NavigationRoutes.UserManagement.fullPath}/${this.path}`;
    static title = 'System User';
    static allowedRoles = [Role.Admin, Role.Developer];
  };

  static MaintainSystemUser = class {
    static path = `${NavigationRoutes.SystemUser.path}/${NavigationRoutes.Maintain.path}`;
    static fullPath = `${NavigationRoutes.UserManagement.fullPath}/${this.path}`;
    static title = 'Maintain User Management';
    static allowedRoles = [Role.Admin, Role.Developer];
  };

  static Role = class {
    static path = 'role';
    static fullPath = `${NavigationRoutes.UserManagement.fullPath}/${this.path}`;
    static title = 'Role';
    static allowedRoles = [Role.Developer];
  };

  static MaintainRole = class {
    static path = `${NavigationRoutes.Role.path}/${NavigationRoutes.Maintain.path}`;
    static fullPath = `${NavigationRoutes.UserManagement.fullPath}/${this.path}`;
    static title = 'Maintain Role';
    static allowedRoles = [Role.Developer];
  };
}
