import {Component, OnInit} from '@angular/core';
import {PageMode} from "../../../../enums";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {SnackBarService} from "../../../../service/snack-bar/snack-bar.service";
import {CanComponentDeactivateConfirm} from "../../../../guards/navigate-away-confirm.guard";
import {MasterDataConfigurationValidator} from "../../validators/master-data-configuration.validator";
import {MasterDataConfigurationServiceAbstract} from "../../services/master-data-configuration.service.abstract";
import {HttpErrorResponse} from "@angular/common/http";
import {Constants} from "../../models/constants";
import {ServiceErrorHandler} from "../../../../service/error-handler/error-handler.service";
import {AuditFirmResponse, CreateAudiFirmCommand, UpdateAudiFirmCommand} from "../../models/audit-firm";
import {UserManagementServiceAbstract} from "../../../user-management/services/user-management.service.abstract";
import {UserResponse} from "../../models/user-response.model";
import {SystemUserResponse} from "../../../user-management/models/system-user";

@Component({
  selector: 'app-audit-firm-maintain',
  templateUrl: './audit-firm-maintain.component.html',
  styleUrls: ['./audit-firm-maintain.component.scss']
})
export class AuditFirmMaintainComponent implements OnInit, CanComponentDeactivateConfirm{
  existingAuditFirms?: AuditFirmResponse[];
  selectedAuditFirm?: AuditFirmResponse;
  pageMode: PageMode = PageMode.Add;
  pageModeText = 'Create new';
  submitButtonText = 'Create';
  isLoading = false;
  auditFirmForm: FormGroup;
  useGuard = true;

  systemUsers: UserResponse[] = []

  constructor(
    public router: Router,
    private snackBar: SnackBarService,
    private lookupValidator: MasterDataConfigurationValidator,
    private masterDataConfigurationService: MasterDataConfigurationServiceAbstract,
    private userManagementService: UserManagementServiceAbstract,
    private serviceErrorHandler: ServiceErrorHandler,
  ){
    const parameters: any = router.GetNavigationParameter(
      AuditFirmMaintainComponent
    );

    if (parameters) {
      this.existingAuditFirms = parameters.existingAuditFirms;
      this.selectedAuditFirm = parameters.selectedAuditFirm;

      if (this.selectedAuditFirm) {
        this.pageMode = PageMode.Edit;
        this.pageModeText = 'Edit';
        this.submitButtonText = 'Update';
      } else {
        this.pageMode = PageMode.Add;
        this.pageModeText = 'Create new';
        this.submitButtonText = 'Create';
      }
    }
    else{
      router.NavigateTo.AuditFirm();
    }

    this.auditFirmForm = new FormGroup({});
  }

  ngOnInit(): void {
    this.setupForm();
    this.getUsers();
  }

  confirmNavigateAway(): boolean {
    return (
      this.auditFirmForm.touched && this.auditFirmForm.dirty && this.useGuard
    );
  }

  get nameCharacterCount() {
    return this.auditFirmForm.controls['name'].value.length;
  }

  get createAudiFirmCommand() {
    return {
      name: this.auditFirmForm.controls['name'].value.trim(),
    } as CreateAudiFirmCommand;
  }

  get updateAudiFirmCommand() {
    return {
      name: this.auditFirmForm.controls['name'].value.trim(),
    } as UpdateAudiFirmCommand;
  }

  private getUsers(){
    this.isLoading = true;
    this.userManagementService
        .getAllSystemUsers()
        .subscribe({
          next: (result: SystemUserResponse[]) => {
            this.systemUsers = result as UserResponse[];
          },
          error: (error: HttpErrorResponse) => {
            this.serviceErrorHandler.displayHttpErrorDialog(
                this,
                error,
                Constants.ErrorMessages
            );
          },
        })
        .add(() => {
          this.isLoading = false;
        });
  }

  setupForm() {
    this.auditFirmForm = new FormGroup({
      id: new FormControl(
        this.selectedAuditFirm ? this.selectedAuditFirm.id : undefined
      ),
      name: new FormControl(
        this.selectedAuditFirm ? this.selectedAuditFirm.name : ''
      ),
    });

    this.auditFirmForm.controls['name'].setValidators([
      Validators.required,
      this.lookupValidator.duplicateNameValidator(
        this.auditFirmForm,
        this.existingAuditFirms
      ),
    ]);
  }

  onSubmit() {
    Object.values(this.auditFirmForm.controls).forEach((control) => {
      control.markAsTouched();
    });

    if (this.auditFirmForm.valid) {
      if (this.pageMode === PageMode.Add) {
        this.processCreate();
      } else {
        this.processEdit();
      }
    }
  }

  processCreate() {
    this.isLoading = true;
    this.masterDataConfigurationService
      .createAuditFirm(this.createAudiFirmCommand)
      .subscribe({
        next: () => {
          this.showSuccessMessage();
        },
        error: (error: HttpErrorResponse) => {
          this.serviceErrorHandler.displayHttpErrorDialog(
            this,
            error,
            Constants.ErrorMessages
          );
        },
      })
      .add(() => {
        this.isLoading = false;
      });
  }

  processEdit() {
    if (this.selectedAuditFirm?.id) {
      this.isLoading = true;
      this.masterDataConfigurationService
        .updateAuditFirm(
          this.selectedAuditFirm.id,
          this.updateAudiFirmCommand
        )
        .subscribe({
          next: () => {
            this.showSuccessMessage();
          },
          error: (error: HttpErrorResponse) => {
            this.serviceErrorHandler.displayHttpErrorDialog(
              this,
              error,
              Constants.ErrorMessages
            );
          },
        })
        .add(() => {
          this.isLoading = false;
        });
    }
  }

  onCancel() {
    this.NavigateBackToView();
  }

  NavigateBackToView() {
    this.router.NavigateTo.AuditFirm();
  }

  showSuccessMessage() {
    this.useGuard = false;
    this.NavigateBackToView();

    this.snackBar.open(
      `Audit Firm successfully ${
        this.pageMode === PageMode.Add ? 'created' : 'updated'
      }`
    );
  }
}
