export enum ApprovalOutcome {
  Pending = 'Pending',
  Approved = 'Approved',
  Rejected = 'Rejected',
  Next = 'Next',
  Amendment = 'Amendment',
  rejectedBusinessToConsiderAnAlternativeServiceProvider = 'Rejected:Business to consider an alternative service provider',
  rejectedWorkFallsWithinTheStatutoryAuditScopeOfWork = 'Rejected:Work falls within the statutory audit scope of work'

}
