import { Location } from '@angular/common';
import { Router } from '@angular/router';
import {
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalService,
} from '@azure/msal-angular';
import { Environments } from 'src/app/enums';
import { AppSettings } from 'src/environments/app-settings/app-settings';
import { AbstractRouteGuard } from './route-mock.guard.abstract';

export function FactoryRouteGuard(
  msalGuardConfig: MsalGuardConfiguration,
  msalBroadcastService: MsalBroadcastService,
  authService: MsalService,
  location: Location,
  router: Router
): AbstractRouteGuard {
  switch (AppSettings.environment) {
    case Environments.MockData:
      return new MsalGuard(
        msalGuardConfig,
        msalBroadcastService,
        authService,
        location,
        router
      );
    default:
      //return new MockRouteGuard();
      return new MsalGuard(
        msalGuardConfig,
        msalBroadcastService,
        authService,
        location,
        router
      );
  }
}
