import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ModelDialogDataConfirmation} from "../../model/dialog-data/dialog-data.model";

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent {
  header: string;
  message: string;
  displayCancelButton: boolean;
  icon: string;
  okButtonText: string;
  cancelButtonText: string;
  topic?: string;

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModelDialogDataConfirmation
  ) {
    this.header = data?.header ?? 'Confirmation';
    this.message = data?.message ?? 'Confirmation not initialized with data';
    this.displayCancelButton = data?.displayCancelButton ?? true;
    this.icon = data?.icon ?? 'info';
    this.okButtonText = data?.okButtonText ?? 'Ok';
    this.cancelButtonText = data?.cancelButtonText ?? 'Cancel';
    this.topic = data?.topic ?? undefined;
  }
}
