import {PagesServiceAbstract} from "./pages.service.abstract";
import {AbstractEndpoints} from "../../../../environments/endpoints/endpoints.abstract";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {PolicyDocumentResponse} from "../models/policy/policy-document-response.model";
import {UserGuideResponse} from "../models/user-guide/user-guide-response.model";
import {PageTemplateResponse} from "../models";

export class PagesService implements PagesServiceAbstract{
  constructor(private endpoints: AbstractEndpoints, private http: HttpClient) {}

  createPolicy(file: FormData): Observable<PolicyDocumentResponse>{
    return this.http
      .post<PolicyDocumentResponse>(this.endpoints.page.createPolicyDocument,
        file,
      )
  };

  getAllUserGuides(): Observable<UserGuideResponse[]> {
    return this.http
      .get<UserGuideResponse[]>(
        this.endpoints.page.getAllUserGuides
      );
  };

  GetUserGuideById(userGuideId: number): Observable<UserGuideResponse> {
    return this.http
      .get<UserGuideResponse>(
        this.endpoints.page.GetUserGuideById(userGuideId)
      );
  };

  createUserGuide(file: FormData): Observable<number> {
    return this.http.post<number>(
      this.endpoints.page.createUserGuide,
      file,
    );
  }

  updateUserGuide(userGuideId: number, file: FormData) {
    return this.http.put<number>(
      this.endpoints.page.updateUserGuide(userGuideId),
      file,
    )
  }

  deleteUserGuide(userGuideId: number): Observable<number> {
    return this.http.delete<number>(
      this.endpoints.page.deleteUserGuide(userGuideId),
    );
  }

  //Page Template
  getActivePageTemplate(pageTarget: number): Observable<PageTemplateResponse> {
    return this.http.get<PageTemplateResponse>(
      this.endpoints.pagesManagement.getActivePageTemplate(pageTarget)
    );
  }
}
