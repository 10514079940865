import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AttachmentConstants, RequestMaintainFileUploadAttestData, RequestResponse} from "../../../models";
import {RequestManagementValidator} from "../../../validators/request-management.validator";

@Component({
  selector: 'app-request-maintain-file-upload-attest',
  templateUrl: './request-maintain-file-upload-attest.component.html',
  styleUrls: ['./request-maintain-file-upload-attest.component.scss']
})
export class RequestMaintainFileUploadAttestComponent
 implements  OnInit {
    @ViewChild('fileInput', {static: false}) fileInput: ElementRef | undefined;

    @Input()
    selectedRequest?: RequestResponse;

    @Input()
    requestNumber!: string;

    isLoading = false;
    fileUploadAttestForm: FormGroup;

    errorMessage: string = '';
    successMessage: string = '';
    allowedFileExtensions: string[] = ['pdf'];
    fileName: string = '';
    fileFormData?: FormData;
    constructor(
        private requestManagementValidator: RequestManagementValidator,
    ) {
        this.fileUploadAttestForm = new FormGroup({});
    }

    protected get acceptedFileExtensions() {
        return this.allowedFileExtensions.map((ext) => `.${ext}`).join(',');
    }

    ngOnInit() : void {
        this.setupForm();
    }

    protected setupForm() : void {
        this.fileUploadAttestForm = new FormGroup({
            attestation: new FormControl(undefined, Validators.required),
            fileName: new FormControl(undefined, Validators.required)
        });

        this.fileUploadAttestForm.controls['attestation'].addValidators([
            Validators.required,
            this.requestManagementValidator.attestationTrueValidator(this.fileUploadAttestForm),
        ])
    }

    clearForm() : void {
      this.fileUploadAttestForm = new FormGroup({
        attestation: new FormControl(undefined, Validators.required),
        fileName: new FormControl(undefined, Validators.required)
      });

      this.fileUploadAttestForm.controls['attestation'].addValidators([
        Validators.required,
        this.requestManagementValidator.attestationTrueValidator(this.fileUploadAttestForm),
      ])

      this.fileName = '';
      this.fileFormData = undefined;
      this.successMessage = '';
    }
    protected onSelectFile() {
        this.fileInput?.nativeElement.click();
    }

    protected attachFile(event: any) {
        const file: File = event.target.files?.[0];

        if (file && this.isValid(file)) {
            this.fileFormData = new FormData();
            this.errorMessage = '';

            this.fileName = this.requestNumber + '_' + file.name;

            this.fileFormData.append('file', file, this.fileName);

            this.fileUploadAttestForm.controls['fileName'].patchValue(this.fileName);

            this.successMessage = `Document ${file.name} is uploaded successfully`;
        }
    }

    protected isValid(file: File) {
        if (file.size > AttachmentConstants.maxFileSizeBinary) {
            this.errorMessage = AttachmentConstants.fileSizeErrorMessage;
            return false;
        }

        if (
            !AttachmentConstants.allowedFileExtensions.includes(
                this.getExtension(file.name.toLowerCase())
            )
        ) {
            this.errorMessage = AttachmentConstants.fileTypeErrorMessage;
            return false;
        }

        this.errorMessage = '';
        return true;
    }

    protected getExtension(filename: string) {
        return filename.substring(filename?.lastIndexOf('.') + 1);
    }

    public fetchData(): RequestMaintainFileUploadAttestData {
        return {
            attestation: this.fileUploadAttestForm.controls['attestation'].value,
            fileFormData: this.fileFormData ?? undefined,
        } as RequestMaintainFileUploadAttestData;
    }
}
