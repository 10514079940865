<div class="flex flex-col gap-[32px] p-3 items-stretch content-stretch">
  <div fxLayout="column" fxLayoutGap="8px">
    <div class="mat-display-4">Update Policy </div>
    <div class="mat-subheading-1">
      Manage and maintain all user policy data within the system below
    </div>
  </div>
  <mat-divider></mat-divider>

  <div>
    <div class="card-content">
      <div class="card-body ">
        <h6>Document Name</h6>
        <span class="alert">{{ errorMessage }}</span>
        <span class="success">{{ successMessage }}</span>
        <input #fileInput type="file" class="file-input" [accept]="acceptedFileExtensions" (change)="attachFile($event)" />
        <div class="row document-type">
          <div class="col-xs-12">
            <button mat-mini-fab color="primary" class="upload-btn" action="file" (click)="onSelectFile()" type="submit">
              <mat-icon>attach_file</mat-icon>
            </button>
            Only pdf file types allowed
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



