import {IConfig} from "../../../environments/config/config.interface";
import {Injectable} from "@angular/core";
import {HttpBackend, HttpClient} from "@angular/common/http";
import {AppSettings} from "../../../environments/app-settings/app-settings";
import {firstValueFrom} from "rxjs";
import {ErrorSeverityLevel} from "../../enums/separated-enums/error-severity-level.enum";

@Injectable({
  providedIn: 'root',
})
export class ServiceConfig extends IConfig {
  private readonly http: HttpClient;

  constructor(private readonly httpHandler: HttpBackend) {
    super();
    this.http = new HttpClient(httpHandler);
  }

  loadConfig(): Promise<void> {
    const observable = this.http.get<IConfig>(AppSettings.configFileLocation);
    var promise = firstValueFrom(observable);
    return promise
      .then((config: IConfig) => {
        Object.assign(this, config);
        switch (config.logging.loggingLevel) {
          case 'info':
            this.logging.loggingLevel = ErrorSeverityLevel.Info;
            break;
          case 'warning':
            this.logging.loggingLevel = ErrorSeverityLevel.Warning;
            break;
          case 'error':
            this.logging.loggingLevel = ErrorSeverityLevel.Error;
            break;
          default:
            console.warn(
              "Logging level not set in config.  Level set to 'Info' by default"
            );
            this.logging.loggingLevel = ErrorSeverityLevel.Info;
            break;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }
}
