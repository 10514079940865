import {Injectable} from "@angular/core";
import {catchError, Observable, retry, throwError} from "rxjs";
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {ServiceMonitoring} from "../service/monitor/monitor.service";

@Injectable()
export class InterceptorError implements HttpInterceptor {
  constructor(private serviceMonitor: ServiceMonitoring) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      retry(0),
      catchError((error: HttpErrorResponse) => {
        let errorMessage = '';
        if (this.isServerSideError(error)) {
          // client-side error
          errorMessage = `HTTP Client-Error: ${error.error.message}`;
        } else {
          // server-side error
          errorMessage = `HTTP Server-Error: Status: ${error.status}\nMessage: ${error.message}\nServerError:${error.error.title}`;
        }
        this.serviceMonitor.logException(this, errorMessage, {
          requestContent: request,
          errorContent: error,
        });
        return throwError(error);
      })
    );
  }

  private isServerSideError(error: HttpErrorResponse): boolean {
    return !(error.error instanceof ErrorEvent);
  }
}
