import {Injectable} from "@angular/core";
import {AbstractControl, FormGroup, ValidatorFn} from "@angular/forms";
import {SystemUserResponse} from "../models/system-user";

@Injectable({
  providedIn: 'root',
})
export class UserManagementValidator {
  duplicateNameValidator(form: FormGroup, existingItems?: any[]): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const id = form.controls['id'].value ?? 0;
      const email = form.controls['email'].value;

      console.log('id', id);
      console.log('email', email);
      if (!email || !existingItems) {
        return null;
      }

      control.markAsTouched();

      const isDuplicate = existingItems.find((obj) => {
        return (
          obj.email.trim().toLowerCase() === email.trim().toLowerCase() &&
          obj.id !== id
        );
      });

      return isDuplicate
        ? {
          duplicate: true,
        }
        : null;
    };
  }

  duplicateEmailValidator(form: FormGroup, existingItems?: any[]): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const id = form.controls['id'].value ?? 0;
      const email = form.controls['email'].value ?? "";

      if (!email || !existingItems) {
        return null;
      }

      control.markAsTouched();
      const isDuplicate = existingItems.find((obj) => {
        return (
          obj.email?.trim().toLowerCase() === email?.trim().toLowerCase() &&
          obj.id !== id
        );
      });

      return isDuplicate
        ? {
          duplicate: true,
        }
        : null;
    };
  }

  isInternalEmailValidator(form: FormGroup, selectUser?: SystemUserResponse): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const isAbsaEmployee = selectUser ? selectUser?.isAbsaEmployee : form.controls['isAbsaEmployee'].value === 'true';
      const name = form.controls['email'].value ?? "";

      if (!name || !isAbsaEmployee) {
        return null;
      }

      control.markAsTouched();

      const IsNotInternalEmailAddress =  (
        !name?.trim().toLowerCase().includes("@absa.africa") && !name?.trim().toLowerCase().includes("@absa.co.za") &&
        isAbsaEmployee
      );

      return IsNotInternalEmailAddress
        ? {
          IsNotInternalEmailAddress: true,
        }
        : null;
    };
  }

  isExternalEmailValidator(form: FormGroup, selectUser?: SystemUserResponse): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const isAbsaEmployee = (selectUser ? selectUser?.isAbsaEmployee : form.controls['isAbsaEmployee'].value === 'true') ?? false;
      const name = form.controls['email'].value ?? "";

      if (!name || isAbsaEmployee) {
        return null;
      }

      control.markAsTouched();

      const IsNotExternalEmailAddress =  (
        name?.trim().toLowerCase().includes("@absa.africa") &&
        !isAbsaEmployee
      );

      return IsNotExternalEmailAddress
        ? {
          IsNotExternalEmailAddress: true,
        }
        : null;
    };
  }
}
