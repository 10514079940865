import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {ServiceLoadingScreen} from "../service/loading-screen/loading-screen.service";
import {ServiceConfig} from "../service/config/config.service";
import {finalize, Observable} from "rxjs";
import {Injectable} from "@angular/core";

@Injectable()
export class InterceptorLoadingScreen implements HttpInterceptor {
  activeRequests = 0;
  skipUrls: string[] = [];

  constructor(
    private loadingScreenService: ServiceLoadingScreen,
              private serviceConfig: ServiceConfig
  ) {
    // this.skipUrls.push('url that should be skipped')
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.headers.has('skip')) {
      // remove the 'skip' header and call next.handle() without loading screen
      const clonedRequest = request.clone({
        headers: request.headers.delete('skip'),
      });
      return next.handle(clonedRequest);
    }

    let displayLoadingScreen = true;

    for (const skipUrl of this.skipUrls) {
      if (new RegExp(skipUrl).test(request.url)) {
        displayLoadingScreen = false;
        break;
      }
    }

    if (displayLoadingScreen) {
      if (this.activeRequests === 0) {
        this.loadingScreenService.startLoading();
      }
      this.activeRequests++;

      return next.handle(request).pipe(
        finalize(() => {
          this.activeRequests--;
          if (this.activeRequests === 0) {
            this.loadingScreenService.stopLoading();
          }
        })
      );
    } else {
      return next.handle(request);
    }
  }
}
