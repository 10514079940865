import {Component, OnInit} from '@angular/core';
import {SessionStorage} from "../../../../service/session/session.storage";
import {PagesServiceAbstract} from "../../services/pages.service.abstract";
import {PageTemplateTarget} from "../../../../enums";
import {HttpErrorResponse} from "@angular/common/http";
import {Constants} from "../../../country-management/models/constants";
import {PageTemplateResponse} from "../../models";
import {ServiceErrorHandler} from "../../../../service/error-handler/error-handler.service";
import {Editor, toHTML} from "ngx-editor";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  private userInitials = '';
  private _userName = '';
  private _greetingMessage: string = 'Good Morning';
  isLoading = true;
  activePageTemplate?: PageTemplateResponse
  safeHtml?: SafeHtml;

  public get userName() {
    return this._userName;
  }

  public get greetingMessage() {
    return this._greetingMessage;
  }

  public set userName(value) {
    this._userName = value;
  }

  public set greetingMessage(value){
    this._greetingMessage = value;
  }
  constructor(
    private sessionStorage: SessionStorage,
    private pagesService: PagesServiceAbstract,
    private serviceErrorHandler: ServiceErrorHandler,
    private sanitizer: DomSanitizer,
  ) {
    if(this.sessionStorage.getUserName){
      this.userName = this.sessionStorage.getUserName
    }

    const midNightObj = { hour:0,minute: 0,second: 0}
    const midDayObj = { hour:12,minute: 0,second: 0}
    const nightObj = { hour:18,minute: 0,second: 0}
    const toMidNightObj = { hour:23,minute: 59,second: 59}

    const todayDateTime = new Date();
    const midDayDateTime = new Date(todayDateTime.getFullYear(), todayDateTime.getMonth(), todayDateTime.getDate(), midDayObj.hour, midDayObj.minute, midDayObj.second);
    const nightDateTime = new Date(todayDateTime.getFullYear(), todayDateTime.getMonth(), todayDateTime.getDate(), nightObj.hour, nightObj.minute, nightObj.second);
    const toMidNightDateTime = new Date(todayDateTime.getFullYear(), todayDateTime.getMonth(), todayDateTime.getDate(), toMidNightObj.hour, toMidNightObj.minute, toMidNightObj.second);
    const midNightDateTime = new Date(todayDateTime.getFullYear(), todayDateTime.getMonth(), todayDateTime.getDate(), midNightObj.hour, midNightObj.minute, midNightObj.second)

    const isEvening = ((todayDateTime.getTime() / 1000) >= (nightDateTime.getTime() / 1000) && (todayDateTime.getTime() / 1000) <= (toMidNightDateTime.getTime() / 1000));
    const isMorning = ((todayDateTime.getTime() / 1000) >= (midNightDateTime.getTime() / 1000) && (todayDateTime.getTime() / 1000) <= (midDayDateTime.getTime() / 1000));
    const isAfterNoon = ((todayDateTime.getTime() / 1000) >= (midDayDateTime.getTime() / 1000) && (todayDateTime.getTime() / 1000) < (nightDateTime.getTime() / 1000))

    this.greetingMessage = isMorning ? 'Good Morning' : isAfterNoon? 'Good Afternoon' : isEvening ? 'Good Evening' : '';
  }

  ngOnInit(): void {
    this.loadData()
  }

  loadData() {
    this.isLoading = true;
    this.pagesService
      .getActivePageTemplate(PageTemplateTarget.HomePage)
      .subscribe({
        next: (result: PageTemplateResponse) => {
          let newResult = result;
          this.activePageTemplate = newResult;
          this.safeHtml = this.sanitizer.bypassSecurityTrustHtml(newResult.templateJson ? toHTML(JSON.parse(newResult.templateJson, )) : "");
        },
        error: (error: HttpErrorResponse) => {
          this.serviceErrorHandler.displayHttpErrorDialog(
            this,
            error,
            Constants.ErrorMessages
          );
        },
      })
      .add(() => {
        this.isLoading = false;
      });
  }
}
