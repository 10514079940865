<form [formGroup]="reasonForm" fxLayout="column" fxLayoutGap="2px">

    <mat-form-field fxFlex appearance="fill">
        <mat-label>{{ "Was a similar request submitted last year?" }}</mat-label>
        <mat-select formControlName="submissionLastYear">
            <mat-option value={{true}}>Yes</mat-option>
            <mat-option value={{false}}>No</mat-option>
        </mat-select>
      <mat-error *ngIf="reasonForm.controls['submissionLastYear'].hasError('required')">
        The Was a similar request submitted last year? field is required.
      </mat-error>
    </mat-form-field>

    <!--Old_Request-->
    <mat-form-field fxFlex appearance="fill"  *ngIf="isOldRequestVisible">
      <mat-label >{{ "Previous Request Number" }}</mat-label>
      <input matInput  formControlName="oldRequest">
      <mat-error *ngIf="reasonForm.controls['oldRequest'].hasError('required')">
        Previous Request Number field is required.
      </mat-error>
    </mat-form-field>

    <!--Reasons_For_Engagement-->
    <mat-form-field fxFlex appearance="fill">
        <mat-label >{{ "Reasons why the statutory auditors were selected for the engagement" }}</mat-label>
        <mat-select formControlName="reasonAuditorsId">
            <ng-container>
                <mat-option *ngFor="let p of reasons" [value]="p.id">
                    {{ p.name }}
                </mat-option>
            </ng-container>
        </mat-select>
      <mat-error *ngIf="reasonForm.controls['reasonAuditorsId'].hasError('required')">
        The Reason Auditors field is required.
      </mat-error>
    </mat-form-field>
</form>
