export enum BusinessArea {
  Admin = 'Admin',
  AuditFirm = 'Audit Firm',
  BusinessFinancialController = 'Business Financial Controller',
  ChiefFinancialOfficer = 'Chief Financial Officer',
  Developer = 'Developer',
  ExcoMember = 'Exco Member',
  FinancialDirector = 'Financial Director',
  GACCChair = 'GACC Chair',
  GACCGroup = 'GACC Group',
  GroupFinancialController = 'Group Financial Controller',
  GroupSecretariat = 'Group Secretariat',
  Review = 'Review',
  FinalApproval = 'Final Approval',
  Complete = 'Process Complete',
}
