import {BehaviorSubject} from "rxjs";
import {ModelAccountFromToken} from "../../model/authorization/account-from-token.model";

export abstract class AbstractServiceAuthentication {
  /**
   * Returns a boolean indicating if the user is logged in.
   * @returns {boolean}
   * @remarks
   * REMEMBER TO UNSUBSCRIBE IF YOU SUBSCRIBE TO THIS PROPERTY
   * IF YOU DON'T NEED TO SUBSCRIBE JUST USE isLoggedIn.getValue()
   */
  abstract isLoggedIn: BehaviorSubject<boolean>;
  abstract get account(): ModelAccountFromToken | undefined;
  abstract registerForAuthenticationEvents(): void;

  abstract setRegisterAuthority(): void;

  abstract setLoginAuthority(): void;

  abstract setLoginDisplay(): boolean;

  abstract logout(): void;
}
