import {HttpContextToken, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs";
import {MsalInterceptor} from "@azure/msal-angular";
import {Injectable} from "@angular/core";

export const BYPASS_MSAL = new HttpContextToken(() => true);

@Injectable()
export class MsalSkipInterceptor extends MsalInterceptor implements HttpInterceptor {
  override intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.headers.get("skip")) {
      return next.handle(request);
    }
    return super.intercept(request, next);
  }
}
