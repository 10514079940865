import {NavigationRoutes} from "./navigation.routes.helper";
import {Type} from "@angular/core";
import {Router} from "@angular/router";
import {
  AuditFirmMaintainComponent
} from "../modules/master-data-configuration/components/audit-firm-maintain/audit-firm-maintain.component";
import {PolicyMaintainComponent} from "../modules/pages/components/policy-maintain/policy-maintain.component";
import {
  UserGuideMaintainComponent
} from "../modules/pages/components/user-guide-maintain/user-guide-maintain.component";
import {
  DeliverableMaintainComponent
} from "../modules/master-data-configuration/components/deliverable-maintain/deliverable-maintain.component";
import {
  ReasonMaintainComponent
} from "../modules/master-data-configuration/components/reason-maintain/reason-maintain.component";
import {
  SystemUserMaintainComponent
} from "../modules/user-management/components/system-user-maintain/system-user-maintain.component";
import {
  SegmentMaintainComponent
} from "../modules/segment-management/components/segment-maintain/segment-maintain.component";
import {RoleMaintainComponent} from "../modules/user-management/components/role-maintain/role-maintain.component";
import {
  RequestMaintainComponent
} from "../modules/request-management/components/request-maintain/request-maintain.component";
import {
  ServiceTypeMaintainComponent
} from "../modules/service-management/components/service-type-maintain/service-type-maintain.component";
import {
  ServiceCategoryMaintainComponent
} from "../modules/service-management/components/service-category-maintain/service-category-maintain.component";
import {
  ServiceSubCategoryMaintainComponent
} from "../modules/service-management/components/service-sub-category-maintain/service-sub-category-maintain.component";
import {
  SubSegmentMaintainComponent
} from "../modules/segment-management/components/sub-segment-maintain/sub-segment-maintain.component";
import {
  CountryMaintainComponent
} from "../modules/country-management/components/country-maintain/country-maintain.component";
import {
  CountryCurrencyMaintainComponent
} from "../modules/country-management/components/country-currency-maintain/country-currency-maintain.component";
import {RequestDetailsSearchQuery} from "../modules/request-management/models";
import {HomeMaintainComponent} from "../modules/pages-management/components/home-maintain/home-maintain.component";
import {ActiveSearchQuery} from "../model/filter/active-search-query.model";
import {
  DetailedServiceTypeMaintainComponent
} from "../modules/service-management/components/detailed-service-type-maintain/detailed-service-type-maintain.component";
import {
  DetailedSegmentMaintainComponent
} from "../modules/segment-management/components/detailed-segment-maintain/detailed-segment-maintain.component";

declare module '@angular/router' {
  export interface Router {
    Routes: NavigationRoutes;
    NavigateTo: NavigationHelper;
    GetNavigationParameter<T>(component: Type<any>): T;
    InitializeExtensionMethods(): void;
  }
}

Router.prototype.InitializeExtensionMethods = function () {
  const router = this as unknown as Router;
  if (!router.NavigateTo) {
    router.NavigateTo = new NavigationHelper(router);
  }
};

const parameters: { [key: string]: any } = {};

Router.prototype.GetNavigationParameter = function <T>(component: Type<any>) {
  return parameters[nameOf(component)] as T;
};
function nameOf<T extends new (...args: any[]) => any>(classType: T): string {
  return classType.name;
}

class NavigationHelper {
  constructor(private router: Router) {}

  LandingPage(): void {
    this.router.navigate([`${NavigationRoutes.LandingPage.fullPath}`]);
  }

  NotFound(): void {
    this.router.navigate([`${NavigationRoutes.NotFound.fullPath}`]);
  }

  NotAuthorized(): void {
    this.router.navigate([`${NavigationRoutes.NotAuthorized.fullPath}`]);
  }

  Pages(): void {
    this.router.navigate([`${NavigationRoutes.Pages.fullPath}`]);
  }

  Policy(): void {
    this.router.navigate([`${NavigationRoutes.Policy.fullPath}`]);
  }

  MaintainPolicy(
    params: any,
    parameterName = nameOf(PolicyMaintainComponent)
  ): void {
    parameters[parameterName] = params;
    this.router.navigate([`${NavigationRoutes.MaintainPolicy.fullPath}`]);
  }

  UserGuide(): void {
    this.router.navigate([`${NavigationRoutes.UserGuide.fullPath}`]);
  }

  MaintainUserGuide(
    params: any,
    parameterName = nameOf(UserGuideMaintainComponent)
  ): void {
    parameters[parameterName] = params;
    this.router.navigate([`${NavigationRoutes.MaintainUserGuide.fullPath}`]);
  }

  UserGuideDownload(): void {
    this.router.navigate([`${NavigationRoutes.UserGuideDownload.fullPath}`]);
  }

  Request(): void {
    this.router.navigate([`${NavigationRoutes.Request.fullPath}`]);
  }

  RequestMade(): void {
    this.router.navigate([`${NavigationRoutes.RequestMade.fullPath}`]);
  }

  RequestDetail(
  ): void {
    this.router.navigate([`${NavigationRoutes.RequestDetail.fullPath}`]);
  }

  RequestDetailByBusinessArea(
    query: RequestDetailsSearchQuery
  ): void {
    this.router.navigate([`${NavigationRoutes.RequestDetail.fullPath}`], { queryParams: query });
  }

  RequestMadeDetailByBusinessArea(
    query: RequestDetailsSearchQuery
  ): void {
    this.router.navigate([`${NavigationRoutes.RequestMade.fullPath}`], { queryParams: query });
  }

  ActiveFilter(router: string, filter: ActiveSearchQuery): void {
    this.router.navigate([`${router}`], { queryParams: filter });
  }

  RequestDetailByStatus(
    requestStatus: string
  ): void {
    this.router.navigate([`${NavigationRoutes.RequestDetail.fullPath}`], { queryParams: { requestStatus: requestStatus }});
  }

  RequestMadeDetailByStatus(
    requestStatus: string
  ): void {
    this.router.navigate([`${NavigationRoutes.RequestMade.fullPath}`], { queryParams: { requestStatus: requestStatus }});
  }
  ViewRequest(
    id: any
  ): void {
    this.router.navigate([`${NavigationRoutes.ViewRequest.fullPath}/${id}`]);
  }

  MaintainRequest(
    params: any,
    parameterName = nameOf(RequestMaintainComponent)
  ): void {
    parameters[parameterName] = params;
    this.router.navigate([`${NavigationRoutes.MaintainRequest.fullPath}`]);
  }

  DashBoard(): void {
    this.router.navigate([`${NavigationRoutes.DashBoard.fullPath}`]);
  }

  DashBoardHome(): void {
    this.router.navigate([`${NavigationRoutes.DashBoardHome.fullPath}`]);
  }

  MasterDataConfiguration(): void {
    this.router.navigate([`${NavigationRoutes.MasterDataConfiguration.fullPath}`]);
  }

  AuditFirm(): void {
    this.router.navigate([`${NavigationRoutes.AuditFirm.fullPath}`]);
  }

  MaintainAuditFirm(
    params: any,
    parameterName = nameOf(AuditFirmMaintainComponent)
  ): void {
    parameters[parameterName] = params;
    this.router.navigate([`${NavigationRoutes.MaintainAuditFirm.fullPath}`]);
  }

  Country(): void {
    this.router.navigate([`${NavigationRoutes.Country.fullPath}`]);
  }

  MaintainCountry(
    params: any,
    parameterName = nameOf(CountryMaintainComponent)
  ): void {
    parameters[parameterName] = params;
    this.router.navigate([`${NavigationRoutes.MaintainCountry.fullPath}`]);
  }

  CountryCurrency(): void {
    this.router.navigate([`${NavigationRoutes.CountryCurrency.fullPath}`]);
  }

  MaintainCountryCurrency(
    params: any,
    parameterName = nameOf(CountryCurrencyMaintainComponent)
  ): void {
    parameters[parameterName] = params;
    this.router.navigate([`${NavigationRoutes.MaintainCountryCurrency.fullPath}`]);
  }


  HomePageTemplate(): void {
    this.router.navigate([`${NavigationRoutes.HomePageTemplate.fullPath}`]);
  }

  MaintainHomePageTemplate(
    params: any,
    parameterName = nameOf(HomeMaintainComponent)
  ): void {
    parameters[parameterName] = params;
    this.router.navigate([`${NavigationRoutes.MaintainHomePageTemplate.fullPath}`]);
  }

  Deliverable(): void {
    this.router.navigate([`${NavigationRoutes.Deliverable.fullPath}`]);
  }

  MaintainDeliverable(
    params: any,
    parameterName = nameOf(DeliverableMaintainComponent)
  ): void {
    parameters[parameterName] = params;
    this.router.navigate([`${NavigationRoutes.MaintainDeliverable.fullPath}`]);
  }

  Reason(): void {
    this.router.navigate([`${NavigationRoutes.Reason.fullPath}`]);
  }

  MaintainReason(
    params: any,
    parameterName = nameOf(ReasonMaintainComponent)
  ): void {
    parameters[parameterName] = params;
    this.router.navigate([`${NavigationRoutes.MaintainReason.fullPath}`]);
  }

  Role(): void {
    this.router.navigate([`${NavigationRoutes.Role.fullPath}`]);
  }

  MaintainRole(
    params: any,
    parameterName = nameOf(RoleMaintainComponent)
  ): void {
    parameters[parameterName] = params;
    this.router.navigate([`${NavigationRoutes.MaintainRole.fullPath}`]);
  }
  ServiceManagement(): void {
      this.router.navigate([`${NavigationRoutes.ServiceManagement.fullPath}`]);
  }

  ServiceType(): void {
    this.router.navigate([`${NavigationRoutes.ServiceType.fullPath}`]);
  }

  MaintainServiceType(
    params: any,
    parameterName = nameOf(ServiceTypeMaintainComponent)
  ): void {
    parameters[parameterName] = params;
    this.router.navigate([`${NavigationRoutes.MaintainServiceType.fullPath}`]);
  }

  ServiceCategory(): void {
    this.router.navigate([`${NavigationRoutes.ServiceCategory.fullPath}`]);
  }

  MaintainServiceCategory(
    params: any,
    parameterName = nameOf(ServiceCategoryMaintainComponent)
  ): void {
    parameters[parameterName] = params;
    this.router.navigate([`${NavigationRoutes.MaintainServiceCategory.fullPath}`]);
  }

  ServiceSubCategory(): void {
    this.router.navigate([`${NavigationRoutes.ServiceSubCategory.fullPath}`])
  }

  MaintainServiceSubCategory(
    params: any,
    parameterName = nameOf(ServiceSubCategoryMaintainComponent)
  ): void {
    parameters[parameterName] = params;
    this.router.navigate([`${NavigationRoutes.MaintainServiceSubCategory.fullPath}`])
  }

  DetailedServiceType(): void {
    this.router.navigate([`${NavigationRoutes.DetailedServiceType.fullPath}`]);
  }

  MaintainDetailedServiceType(
    params: any,
    parameterName = nameOf(DetailedServiceTypeMaintainComponent)
  ): void {
    parameters[parameterName] = params;
    this.router.navigate([`${NavigationRoutes.MaintainDetailedServiceType.fullPath}`]);
  }

  SegmentManagement(): void{
    this.router.navigate([`${NavigationRoutes.SegmentManagement.fullPath}`])
  }

  Segment(): void {
    this.router.navigate([`${NavigationRoutes.Segment.fullPath}`]);
  }

  MaintainSegment(
    params: any,
    parameterName = nameOf(SegmentMaintainComponent)
  ): void {
    parameters[parameterName] = params;
    this.router.navigate([`${NavigationRoutes.MaintainSegment.fullPath}`]);
  }

  SubSegment(): void {
    this.router.navigate([`${NavigationRoutes.SubSegment.fullPath}`]);
  }

  MaintainSubSegment(
    params: any,
    parameterName = nameOf(SubSegmentMaintainComponent)
  ): void {
    parameters[parameterName] = params;
    this.router.navigate([`${NavigationRoutes.MaintainSubSegment.fullPath}`]);
  }

  DetailedSegment(): void {
    this.router.navigate([`${NavigationRoutes.DetailedSegment.fullPath}`]);
  }

  MaintainDetailedSegment(
    params: any,
    parameterName = nameOf(DetailedSegmentMaintainComponent)
  ): void {
    parameters[parameterName] = params;
    this.router.navigate([`${NavigationRoutes.MaintainDetailedSegment.fullPath}`]);
  }

  Auth(): void {
    this.router.navigate([`${NavigationRoutes.Auth.fullPath}`]);
  }

  Login(): void {
    this.router.navigate([`${NavigationRoutes.Login.fullPath}`]);
  }

  SystemUser(): void {
    this.router.navigate([`${NavigationRoutes.SystemUser.fullPath}`]);
  }

  UserManagement(): void {
    this.router.navigate([`${NavigationRoutes.UserManagement.fullPath}`]);
  }

  MaintainSystemUser(
    params: any,
    parameterName = nameOf(SystemUserMaintainComponent)
  ): void {
    parameters[parameterName] = params;
    this.router.navigate([`${NavigationRoutes.MaintainSystemUser.fullPath}`]);
  }
}
