<div class="flex flex-col gap-[32px] items-stretch content-stretch p-3">
  <div fxLayout="column" fxLayoutGap="8px">
    <div class="mat-display-4">{{ pageModeText }} Service Sub Category</div>
    <div class="mat-subheading-1">
      Please capture all required information below.
    </div>
  </div>
  <mat-divider></mat-divider>
  <form [formGroup]="serviceSubCategoryForm" fxLayout="column" fxLayoutGap="32px">

    <mat-form-field fxFlex appearance="fill">
      <mat-label>Service Category</mat-label>
      <mat-select formControlName="serviceCategoryId">
        <mat-option *ngFor="let item of serviceCategories" [value]="item.id">
          {{ item.name }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="serviceSubCategoryForm.controls['serviceCategoryId'].hasError('required')">
        Please select a service category in the indicated field
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex appearance="fill">
      <mat-label>Service Sub Category name</mat-label>
      <input
        matInput
        maxlength="100"
        formControlName="name"
        autocomplete="off"
      />
      <mat-error
        *ngIf="serviceSubCategoryForm.controls['name'].hasError('duplicate')"
      >
        The captured service sub category already exists
      </mat-error>
      <mat-error *ngIf="serviceSubCategoryForm.controls['name'].hasError('required')">
        Please capture service sub category in the indicated field
      </mat-error>
      <mat-hint align="end">{{ nameCharacterCount }} / 100</mat-hint>
    </mat-form-field>

    <mat-form-field fxFlex appearance="fill">
      <mat-label>Display Order</mat-label>
      <input
        matInput
        type="number"
        formControlName="displayOrder"
        autocomplete="off"
      />
      <mat-error *ngIf="serviceSubCategoryForm.controls['displayOrder'].hasError('required')">
        Please capture display order in the indicated field
      </mat-error>
      <mat-error *ngIf="serviceSubCategoryForm.controls['displayOrder'].hasError('min')">
        Please capture display order value need to be greater than 0
      </mat-error>
    </mat-form-field>

  </form>
  <div fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="16px">
    <button
      mat-stroked-button
      color="primary"
      class="mat-stroked-button"
      (click)="onCancel()"
      [disabled]="isLoading"
    >
      Cancel
    </button>
    <button
      mat-flat-button
      color="primary"
      class="mat-flat-button"
      (click)="onSubmit()"
      [disabled]="isLoading"
    >
      {{ submitButtonText }}
    </button>
  </div>
</div>
