<div class="flex flex-col gap-[32px] items-stretch content-stretch p-3">
  <div fxLayout="column" fxLayoutGap="8px">
    <div class="mat-display-4">{{ pageModeText }} Detailed Service Type</div>
    <div class="mat-subheading-1">
      Please capture all required information below.
    </div>
  </div>
  <mat-divider></mat-divider>

  <form [formGroup]="detailedServiceCategoryForm" class="flex flex-col gap-1" *ngIf="!processing">
   <div class="flex flex-row gap-2">
     <mat-form-field fxFlex appearance="fill">
       <mat-label>Select Or {{pageMode === PageMode.Add ? 'Create New' : 'Update'}}  Service Type</mat-label>
       <mat-select formControlName="serviceTypeId">
         <mat-option [value]="0" *ngIf="serviceTypes.length > 5">
          <span class="greenTextColor">
            Create new service type
          </span>
         </mat-option>
         <mat-option *ngFor="let item of serviceTypes" [value]="item.id">
           <span [ngClass]="getUpdateServiceType(item.id)">
             {{ item.name }}
           </span>
         </mat-option>
         <mat-option [value]="0">
          <span class="greenTextColor">
            Create new service type
          </span>
         </mat-option>
       </mat-select>
       <mat-error *ngIf="detailedServiceCategoryForm.controls['serviceTypeId'].hasError('required')">
         Please select a service type in the indicated field
       </mat-error>
     </mat-form-field>

     <mat-form-field fxFlex appearance="fill" *ngIf="(createNewServiceType || pageMode === PageMode.Edit) && !updateSelectedServiceType">
       <mat-label>Service Type name</mat-label>
       <input
         matInput
         maxlength="100"
         formControlName="serviceTypeName"
         autocomplete="off"
       />
       <mat-error
         *ngIf="detailedServiceCategoryForm.controls['serviceTypeName'].hasError('duplicate')"
       >
         The captured service type already exists
       </mat-error>
       <mat-error *ngIf="detailedServiceCategoryForm.controls['serviceTypeName'].hasError('required')">
         Please capture service type in the indicated field
       </mat-error>
     </mat-form-field>
   </div>

    <mat-divider></mat-divider>

   <div class="flex flex-row gap-2">
     <mat-form-field fxFlex appearance="fill" >
       <mat-label>Select Or {{pageMode === PageMode.Add ? 'Create New' : 'Update'}} Service Category</mat-label>
       <mat-select formControlName="serviceCategoryId">
         <mat-option [value]="0" *ngIf="serviceCategories.length > 5">
          <span class="greenTextColor">
            Create new service category
          </span>
         </mat-option>
         <mat-option *ngFor="let item of serviceCategories" [value]="item.id">
           <span [ngClass]="getUpdateServiceCategory(item.id)">
              {{ item.name }}
           </span>
         </mat-option>
         <mat-option [value]="0" >
          <span class="greenTextColor">
            Create new service category
          </span>
         </mat-option>
       </mat-select>
       <mat-error *ngIf="detailedServiceCategoryForm.controls['serviceCategoryId'].hasError('required')">
         Please select a service Category in the indicated field
       </mat-error>
     </mat-form-field>

     <mat-form-field fxFlex appearance="fill" *ngIf="(createNewServiceCategory || pageMode === PageMode.Edit) && !updateSelectedServiceCategory">
       <mat-label>Service Category name</mat-label>
       <input
         matInput
         maxlength="100"
         formControlName="serviceCategoryName"
         autocomplete="off"
       />
       <mat-error
         *ngIf="detailedServiceCategoryForm.controls['serviceCategoryName'].hasError('duplicate')"
       >
         The captured service category already exists
       </mat-error>
       <mat-error *ngIf="detailedServiceCategoryForm.controls['serviceCategoryName'].hasError('required')">
         Please capture service category in the indicated field
       </mat-error>
     </mat-form-field>
   </div>

    <mat-divider></mat-divider>

    <mat-form-field fxFlex appearance="fill">
      <mat-label>Do you want to create a service sub category</mat-label>
      <mat-select formControlName="createServiceSubCategory">
        <mat-option [value]="0">
          <span class="redTextColor">
            No
          </span>
        </mat-option>
        <mat-option [value]="1">
          <span class="greenTextColor">
           Yes
          </span>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div *ngIf="createNewServiceSubCategory" class="flex flex-row gap-2">
      <mat-form-field fxFlex appearance="fill">
        <mat-label>Service Sub Category name</mat-label>
        <input
          matInput
          maxlength="100"
          formControlName="serviceSubCategoryName"
          autocomplete="off"
        />
        <mat-error
          *ngIf="detailedServiceCategoryForm.controls['serviceSubCategoryName'].hasError('duplicate')"
        >
          The captured service sub category already exists
        </mat-error>
        <mat-error *ngIf="detailedServiceCategoryForm.controls['serviceSubCategoryName'].hasError('required')">
          Please capture service sub category in the indicated field
        </mat-error>
      </mat-form-field>

      <mat-form-field fxFlex appearance="fill">
        <mat-label>Display Order</mat-label>
        <input
          matInput
          type="number"
          formControlName="serviceSubCategoryDisplayOrder"
          autocomplete="off"
        />
        <mat-error *ngIf="detailedServiceCategoryForm.controls['serviceSubCategoryDisplayOrder'].hasError('required')">
          Please capture display order in the indicated field
        </mat-error>
        <mat-error *ngIf="detailedServiceCategoryForm.controls['serviceSubCategoryDisplayOrder'].hasError('min')">
          Please capture display order value need to be greater than 0
        </mat-error>
      </mat-form-field>
    </div>


  </form>

  <div fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="16px">
    <button
      mat-stroked-button
      color="primary"
      class="mat-stroked-button"
      (click)="onCancel()"
      [disabled]="isLoading"
    >
      Cancel
    </button>
    <button
      mat-flat-button
      color="primary"
      class="mat-flat-button"
      (click)="onSubmit()"
      [disabled]="isLoading"
    >
      {{ submitButtonText }}
    </button>
  </div>
</div>
