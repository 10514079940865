<div class="jumbotron background font-sans font-normal h-auto min-h-[800px]" style="background-image: url('../../../assets/img/images/absa_banner.jpg');">
  <div class="w-full h-full min-h-[800px]" style="padding: 2% 2% 2% 5%; background-color: rgba(0, 0, 0, 0.5);">
    <div class="flex flex-col">
      <div class="w-full flex flex-col mb-3">
        <h1 *ngIf="!activePageTemplate || activePageTemplate?.showGreeting" class="mb-1 text-3xl">{{greetingMessage}} {{userName}}</h1>
        <h3  *ngIf="!activePageTemplate || activePageTemplate?.showGreeting" class="mb-2 text-2xl">Welcome to the NAS workflow management system</h3>
<!--        <span *ngIf="!activePageTemplate || activePageTemplate?.showGreeting" class="mb-2 text-[1.35rem]">What is Non-Audit Services?</span>-->
      </div>
      <div class="flex flex-col gap-1 text-start" [innerHTML]="safeHtml"></div>
    </div>
  </div>
</div>

<!--<h4 class="mt-4 text-bold-400 mb-3">What is Non-Audit Services?</h4>-->
<!--<p class="text-left mb-3">Non-Audit Services (NAS) refers to services provided by the appointed statutory auditors (auditors) to Absa Group Limited and all its subsidiaries other than the statutory audit or review of an entity’s financial statements </p>-->
<!--<p class="text-left mb-3">The auditors should only be engaged to supply NAS in exceptional circumstances as its default position is to not use the auditors for non-audit services other than those services that are legally required to be performed by the auditors. Users are able to access the policy via the Tab on the left of the screen. </p>-->
<!--<p class="text-left mb-3">The Group’s policy on NAS sets out what additional services the appointed statutory auditors may be allowed to perform and in particular what services are prohibited. </p>-->
<!--<p class="text-left mb-3">-->
<!--  Any services that do not clearly fall within the ambit of the statutory audit must be referred to the policy to ascertain <br>-->
<!--  a) whether the services being considered are allowed and <br>-->
<!--  b) whether there is any risk of the auditor’s objectivity and independence being impaired.-->
<!--</p>-->
<!--<p class="text-left mb-3"></p>-->
<!--<p class="text-left mb-3">In the event that the services are permissible, the request must be formally logged<a asp-controller="RequestDetails" asp-action="Create">here</a>  and will be subject to the NAS approval process. </p>-->
<!--<p class="text-left mb-3">The policy and reference guides are available from the menu for your ease of reference. You may also contact<a href="mailto:africanon-auditservices@absa.co.za">Group Secretariat</a> should you require any further information on the policy and/or process. </p>-->

<!--<small class="mb-3">-->
<!--  <i>-->
<!--    Please note that the Non-Audit Services Portal is best viewed in Google Chrome. Using a different browser may result in the page not performing correctly-->
<!--  </i>-->
<!--</small>-->
