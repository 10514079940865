import {Injectable} from "@angular/core";
import {SessionServiceAbstract} from "./session.service.abstract";
import {AbstractEndpoints} from "../../../environments/endpoints/endpoints.abstract";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {SystemUserResponse} from "../../model/user/system-user-response.model";

@Injectable({
  providedIn: 'root',
})
export class SessionServiceMock implements SessionServiceAbstract {
  constructor(private endpoints: AbstractEndpoints, private http: HttpClient) {}

  getSystemUserByEmailAddress(email: string): Observable<SystemUserResponse>{
    return this.http.get<SystemUserResponse>(
      this.endpoints.userManagement.getSystemUserByEmailAddress(email)
    );
  }
}
