import {Role} from "../enums";
import {Directive, Input, OnInit, TemplateRef, ViewContainerRef} from "@angular/core";
import {AbstractServiceAuthentication} from "../service/authentication/authentication.service.abstract";
import {SessionStorage} from "../service/session/session.storage";
import {NoAccessHideModel} from "../model/no-access-hide/no-access-hide.model";

@Directive({
  selector: '[appNoAccessHide]',
})
export class NoAccessHideDirective{
  private _requiredRole: Role[] | undefined = [];
  private _secondaryValidator: boolean | undefined;
  get requiredRole() {
    return {
      requiredRoles : this._requiredRole,
      secondaryValidator: this._secondaryValidator
    };
  }

  @Input('appNoAccessHide')
  set requiredRole(value: NoAccessHideModel) {
    this._requiredRole = value.requiredRoles;
    this._secondaryValidator = value.secondaryValidator
    this.validateAccess();
  }

  constructor(
    private serviceAuth: AbstractServiceAuthentication,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
  ) { }

  private validateAccess(): void {

    // Undefined actions implies no restriction - have access without the need for any specific action
    if(this.requiredRole.secondaryValidator && !this.requiredRole.secondaryValidator){
      this.viewContainer.clear();
      return;
    }

    if(this.requiredRole.requiredRoles?.includes(Role.OnlyWhenNotAdmin) && this.serviceAuth.account?.idTokenClaims.roles?.includes(Role.Admin)){
      this.viewContainer.clear();
      return;
    }

    if(this.requiredRole.requiredRoles?.includes(Role.OnlyWhenNotAdmin) && !this.serviceAuth.account?.idTokenClaims.roles?.includes(Role.Admin)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      return;
    }

    // if(this.requiredRole === undefined || this.requiredRole?.length === 0){
    //   this.viewContainer.createEmbeddedView(this.templateRef);
    //   return;
    // }


    if (!this.requiredRole) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      return;
    }

    // Empty actions implies no access - no action will give access
    if (this.requiredRole.requiredRoles?.length === 0) {
      this.viewContainer.clear();
      return;
    }


    if (this.requiredRole.requiredRoles?.some((value)=> {
          return this.serviceAuth.account?.idTokenClaims.roles?.includes(
            value.toString()
          );
        })) {
      console.log("role passed")
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
