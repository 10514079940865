import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import {SnackBarService} from "../../../../service/snack-bar/snack-bar.service";
import {HttpClient, HttpErrorResponse, HttpHeaders} from "@angular/common/http";
import {AttachmentConstants} from "../../../request-management/models/constants";
import {ReasonResponse} from "../../../master-data-configuration/models/reason";
import {UserGuideResponse} from "../../models/user-guide/user-guide-response.model";
import {Router} from "@angular/router";
import {PageMode} from "../../../../enums";
import {ServiceErrorHandler} from "../../../../service/error-handler/error-handler.service";
import {
  MasterDataConfigurationServiceAbstract
} from "../../../master-data-configuration/services/master-data-configuration.service.abstract";
import {PagesServiceAbstract} from "../../services/pages.service.abstract";
import {Constants} from "../../../master-data-configuration/models/constants";

@Component({
  selector: 'app-user-guide-maintain',
  templateUrl: './user-guide-maintain.component.html',
  styleUrls: ['./user-guide-maintain.component.scss']
})
export class UserGuideMaintainComponent {
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef | undefined;

  existingUserGuides?: UserGuideResponse[];
  selectedUserGuide?: UserGuideResponse;


  errorMessage = '';
  successMessage = '';
  allowedFileExtensions: string[] = ['pdf'];
  pageMode: PageMode = PageMode.Add;
  pageModeText = 'Create new';
  submitButtonText = 'Create';

  isLoading = false;
  useGuard = true;

  constructor(
    public router: Router,
    private snackBar: SnackBarService,
    private pagesService: PagesServiceAbstract,
    private serviceErrorHandler: ServiceErrorHandler,
  ) {
    const parameters: any = router.GetNavigationParameter(
      UserGuideMaintainComponent
    );

    if (parameters) {
      this.existingUserGuides = parameters.existingUserGuides;
      this.selectedUserGuide = parameters.selectedUserGuide;

      if (this.selectedUserGuide) {
        this.pageMode = PageMode.Edit;
        this.pageModeText = 'Edit';
        this.submitButtonText = 'Update';
      } else {
        this.pageMode = PageMode.Add;
        this.pageModeText = 'Create new';
        this.submitButtonText = 'Create';
      }
    }
    else{
      router.NavigateTo.UserGuide();
    }
  }
  get acceptedFileExtensions() {
    return this.allowedFileExtensions.map((ext) => `.${ext}`).join(',');
  }
  onSelectFile() {
    this.fileInput?.nativeElement.click();
  }


  isValid(file: File) {
    if (file.size > AttachmentConstants.maxFileSizeBinary) {
      this.errorMessage = AttachmentConstants.fileSizeErrorMessage;
      return false;
    }

    if (
      !AttachmentConstants.allowedFileExtensions.includes(
        this.getExtension(file.name.toLowerCase())
      )
    ) {
      this.errorMessage = AttachmentConstants.fileTypeErrorMessage;
      return false;
    }

    this.errorMessage = '';
    return true;
  }

  getExtension(filename: string) {
    return filename.substring(filename?.lastIndexOf('.') + 1);
  }

  getSuccess(){
    return this.snackBar.open('User Guide Upload Success','User Guide');
  }

  getError(){
    return this.snackBar.open('User Guide Upload fail','User Guide');
  }


  onSubmit(event: any) {
    const inputElement = event.target as HTMLInputElement;
    console.log('file', event.target.files?.[0]);
    const file: File = event.target.files?.[0];
    if(file && this.isValid(file)){
      this.errorMessage = '';


      if (this.pageMode === PageMode.Add) {
        this.processCreate(file);
      } else {
        if(this.selectedUserGuide?.id){
          this.processEdit(this.selectedUserGuide?.id, file);
        }
      }
    }
  }

  processCreate(file: File) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    this.isLoading = true;
    this.pagesService
      .createUserGuide(formData)
      .subscribe({
        next: () => {
          this.successMessage = `Document ${file.name} is uploaded successfully`;
          this.getSuccess();
          this.showSuccessMessage();
        },
        error: (error: HttpErrorResponse) => {
          this.successMessage = `Document ${file.name} is uploaded successfully`;
          this.getSuccess();
          console.log(error);

          this.serviceErrorHandler.displayHttpErrorDialog(
            this,
            error,
            Constants.ErrorMessages
          );
        },
      })
      .add(() => {
        this.isLoading = false;
      });
  }

  processEdit(id: number, file: File) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    this.isLoading = true;
    this.pagesService
      .updateUserGuide(
        id,
        formData
      )
      .subscribe({
        next: () => {
          this.showSuccessMessage();
        },
        error: (error: HttpErrorResponse) => {
          this.serviceErrorHandler.displayHttpErrorDialog(
            this,
            error,
            Constants.ErrorMessages
          );
        },
      })
      .add(() => {
        this.isLoading = false;
      });
  }

  onCancel() {
    this.NavigateBackToView();
  }

  NavigateBackToView() {
    this.router.NavigateTo.UserGuide();
  }

  showSuccessMessage() {
    this.useGuard = false;
    this.NavigateBackToView();

    this.snackBar.open(
      `User Guide successfully ${
        this.pageMode === PageMode.Add ? 'created' : 'updated'
      }`
    );
  }
}
