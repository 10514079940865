import {Component, OnInit} from '@angular/core';
import {CanComponentDeactivateConfirm} from "../../../../guards/navigate-away-confirm.guard";
import {PageMode} from "../../../../enums/separated-enums/page-mode.enum";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {CreateSystemUserCommand, SystemUserResponse, UpdateSystemUsersCommand} from "../../models/system-user";
import {Router} from "@angular/router";
import {SnackBarService} from "../../../../service/snack-bar/snack-bar.service";
import {ServiceErrorHandler} from "../../../../service/error-handler/error-handler.service";
import {UserManagementValidator} from "../../validators/user-management.validator";
import {UserManagementServiceAbstract} from "../../services/user-management.service.abstract";
import {HttpErrorResponse} from "@angular/common/http";
import {Constants} from "../../../master-data-configuration/models/constants";
import {RoleResponse} from "../../models/role";
import {Employee} from "../../../../model/employee/employee.model";
import {
  MasterDataConfigurationServiceAbstract
} from "../../../master-data-configuration/services/master-data-configuration.service.abstract";
import {AuditFirmResponse} from "../../models/audit-firm-response.model";
import {Role, RoleType} from "../../../../enums";
import {MatSelectChange} from "@angular/material/select";

@Component({
  selector: 'app-system-user-maintain',
  templateUrl: './system-user-maintain.component.html',
  styleUrls: ['./system-user-maintain.component.scss']
})
export class SystemUserMaintainComponent
  implements OnInit, CanComponentDeactivateConfirm
{
  existingSystemUsers?: SystemUserResponse[];
  selectedSystemUser?: SystemUserResponse;
  roles: RoleResponse[] = [];
  auditFirms: AuditFirmResponse[] = []
  pageMode: PageMode = PageMode.Add;
  pageModes = PageMode;
  pageModeText = 'Create new';
  submitButtonText = 'Create';
  isLoading = false;
  selectedEmployee?: Employee;
  systemUserForm: FormGroup;

  useGuard = true;

  isAbsaUser: boolean = false;
  isAbsaUserSelected: boolean = false;
  isAuditFirmSelected: boolean = false;



  constructor(
    public router: Router,
    private snackBar: SnackBarService,
    private userManagementValidator: UserManagementValidator,
    private userManagementService: UserManagementServiceAbstract,
    private masterDataConfigurationService : MasterDataConfigurationServiceAbstract,
    private serviceErrorHandler: ServiceErrorHandler,
  ){
    const parameters: any = router.GetNavigationParameter(
      SystemUserMaintainComponent
    );



    if (parameters) {
      this.existingSystemUsers = parameters.existingSystemUsers;
      this.selectedSystemUser = parameters.selectedSystemUser;

      console.log("this.existingSystemUsers", this.existingSystemUsers)

      if(this.selectedSystemUser){
        this.isAbsaUser = this.selectedSystemUser.isAbsaEmployee!;
      }


      if (this.selectedSystemUser) {
        this.pageMode = PageMode.Edit;
        this.pageModeText = 'Edit';
        this.submitButtonText = 'Update';
        this.isAbsaUserSelected = true;
      } else {
        this.pageMode = PageMode.Add;
        this.pageModeText = 'Create new';
        this.submitButtonText = 'Create';
      }
    }
    else{
      router.NavigateTo.SystemUser();
    }

    this.systemUserForm = new FormGroup({});

  }

  ngOnInit(): void {
    this.getAuditFirms();
    this.setupSystemUserForm();
  }

  confirmNavigateAway(): boolean {
    return (
      this.systemUserForm.touched && this.systemUserForm.dirty && this.useGuard
    );
  }

  get userNameCharacterCount() {
    return this.systemUserForm.controls['userName'].value.length;
  }

  get firstNameCharacterCount() {
    return this.systemUserForm.controls['firstName'].value.length;
  }

  get lastNameCharacterCount() {
    return this.systemUserForm.controls['lastName'].value.length;
  }

  get createSystemUserCommand() {
    return {
      isAbsaEmployee: this.isAbsaUser,
      firstName: this.systemUserForm.controls['firstName'].value,
      lastName: this.systemUserForm.controls['lastName'].value,
      userName: this.systemUserForm.controls['userName'].value,
      email: this.systemUserForm.controls['email'].value,
      fullName: this.systemUserForm.controls['fullName'].value,
      emailConfirmed: true,
      otherOrganization: this.systemUserForm.controls['otherOrganization'].value,
      phoneNumber: this.systemUserForm.controls['phoneNumber'].value,
      phoneNumberConfirmed: this.systemUserForm.controls['phoneNumberConfirmed'].value,
      isActive: true,
      roleIds: this.systemUserForm.controls['roles'].value,
      organizationId: !this.isAbsaUser && this.isAuditFirmSelected ? this.systemUserForm.controls['organizationId'].value : null,
    } as CreateSystemUserCommand;
  }

  get updateSystemUserCommand() {
    return {
      isAbsaEmployee: this.isAbsaUser,
      firstName: this.systemUserForm.controls['firstName'].value,
      lastName: this.systemUserForm.controls['lastName'].value,
      userName: this.systemUserForm.controls['userName'].value,
      email: this.systemUserForm.controls['email'].value,
      fullName: this.isAbsaUser ? this.systemUserForm.controls['fullName'].value : `${this.systemUserForm.controls['firstName'].value} ${this.systemUserForm.controls['lastName'].value}` ,
      emailConfirmed: this.systemUserForm.controls['emailConfirmed'].value,
      otherOrganization: this.systemUserForm.controls['otherOrganization'].value,
      phoneNumber: this.systemUserForm.controls['phoneNumber'].value,
      phoneNumberConfirmed: this.systemUserForm.controls['phoneNumberConfirmed'].value,
      isActive: this.systemUserForm.controls['isActive'].value,
      roleIds: this.systemUserForm.controls['roles'].value,
      organizationId: !this.isAbsaUser && this.isAuditFirmSelected ? this.systemUserForm.controls['organizationId'].value : null,
    } as UpdateSystemUsersCommand;
  }

  setupSystemUserForm(): void {
    this.systemUserForm = new FormGroup({
      id: new FormControl(
        this.selectedSystemUser ? this.selectedSystemUser.id : undefined
      ),
      isAbsaEmployee: new FormControl(this.selectedSystemUser? this.selectedSystemUser.isAbsaEmployee : false),
      isAbsaEmployeeName: new FormControl(this.selectedSystemUser? this.selectedSystemUser.isAbsaEmployee === true ?'Yes' :'No': 'No'),
      firstName: new FormControl(this.selectedSystemUser? this.selectedSystemUser.firstName : ''),
      lastName: new FormControl(this.selectedSystemUser ? this.selectedSystemUser.lastName : ''),
      userName: new FormControl(this.selectedSystemUser ? this.selectedSystemUser.userName : ''),
      email: new FormControl(this.selectedSystemUser ? this.selectedSystemUser.email : ''),
      fullName: new FormControl(this.selectedSystemUser? this.selectedSystemUser.fullName : ''),
      emailConfirmed: new FormControl(this.selectedSystemUser ? this.selectedSystemUser.emailConfirmed : false),
      otherOrganization: new FormControl(this.selectedSystemUser ? this.selectedSystemUser.otherOrganization : ''),
      phoneNumber: new FormControl(this.selectedSystemUser ? this.selectedSystemUser.phoneNumber : ''),
      phoneNumberConfirmed: new FormControl(this.selectedSystemUser ? this.selectedSystemUser.phoneNumberConfirmed : false),
      isActive: new FormControl(this.selectedSystemUser ? this.selectedSystemUser.isActive : false),
      organizationId: new FormControl(this.selectedSystemUser ? this.selectedSystemUser.organization?.id: undefined),
      roles: new FormControl(this.selectedSystemUser?.roles ? this.selectedSystemUser.roles?.map(x => x.id) : null)
    });

    this.systemUserForm.controls['email'].setValidators([
      Validators.required,
      this.userManagementValidator.duplicateEmailValidator(
        this.systemUserForm,
        this.existingSystemUsers
      ),
      this.userManagementValidator.isInternalEmailValidator(this.systemUserForm, this.selectedSystemUser),
      this.userManagementValidator.isExternalEmailValidator(this.systemUserForm, this.selectedSystemUser),
    ]);

    if(this.pageMode === PageMode.Edit){
      this.systemUserForm.controls['isAbsaEmployeeName'].disable()
    }else{

      this.systemUserForm.controls['isAbsaEmployee'].valueChanges.subscribe((value) => {
        this.isAbsaUserSelected = true;

        if(value && value === 'true') {
          this.isAbsaUser = true;
          this.getRoles(RoleType.Internal);
        } else if(value && value === 'false') {
          this.isAbsaUser = false;
          this.getRoles(RoleType.External);
        }
      });

      this.systemUserForm.controls['email'].valueChanges.subscribe((value) =>{
        if(this.isAbsaUserSelected && this.isAbsaUser){
          if((value as string).includes(".") &&  (value as string).includes("@")){
            let email = value as string;
            const _firstName = email.split('.')[0];
            const lastName_ = email.split('.')[1];
            const _lastName = lastName_.split('@')[0];

            const firstName = _firstName.charAt(0).toUpperCase() + _firstName.slice(1);
            const lastName = _lastName.charAt(0).toUpperCase() + _lastName.slice(1);

            const fullName = firstName + ' ' + lastName;

            this.systemUserForm.controls['firstName'].patchValue(firstName);
            this.systemUserForm.controls['lastName'].patchValue(lastName);
            this.systemUserForm.controls['userName'].patchValue(fullName);
            this.systemUserForm.controls['fullName'].patchValue(fullName);
          }

        }

      });

      this.systemUserForm.controls['firstName'].valueChanges.subscribe((value) =>{
        const _lastName = this.systemUserForm.controls['lastName'].value
        const firstName = value?.charAt(0).toUpperCase() + value?.slice(1);
        const lastName = _lastName?.charAt(0).toUpperCase() + _lastName?.slice(1);

        const fullName = firstName + ' ' + lastName;

        this.systemUserForm.controls['userName'].patchValue(fullName);
        this.systemUserForm.controls['fullName'].patchValue(fullName);
      });

      this.systemUserForm.controls['lastName'].valueChanges.subscribe((value) =>{
        const _firstName = this.systemUserForm.controls['firstName'].value;

        const firstName = _firstName?.charAt(0).toUpperCase() + _firstName?.slice(1);
        const lastName = value?.charAt(0).toUpperCase() + value?.slice(1);

        const fullName = firstName + ' ' + lastName;
        this.systemUserForm.controls['userName'].patchValue(fullName);
        this.systemUserForm.controls['fullName'].patchValue(fullName);
      });

      this.systemUserForm.controls['roles'].statusChanges.subscribe((value) => {

        const isAbsaEmployee = Boolean(this.systemUserForm.controls['isAbsaEmployee'].value);
        const rolesId = this.systemUserForm.controls['roles'].value as number[] ?? [];

        console.log("isAbsaEmployee", isAbsaEmployee)

        if(!isAbsaEmployee){
          rolesId.forEach((roleId) => {
            if(this.roles.find(x => x.id === roleId)?.name === Role.AuditFirm){
              this.isAuditFirmSelected = true;
            }
            else {
              this.isAuditFirmSelected = false;
            }
          });
        }

        console.log("this.isAuditFirmSelected", this.isAuditFirmSelected)
      });
    }

    if(this.selectedSystemUser && !this.selectedSystemUser?.isAbsaEmployee){
      this.getRoles(RoleType.External)
      if(this.selectedSystemUser?.roles?.find(x => x.name === Role.AuditFirm)){
        this.isAuditFirmSelected = true;
      }else {
        this.isAuditFirmSelected = false;
      }
    }
    else{
      this.getRoles(RoleType.Internal);
    }

    console.log("this.isAuditFirmSelected", this.isAuditFirmSelected)
  }

  getRoles(roleType: RoleType) {
    this.isLoading = true;
    this.userManagementService
      .getAllRoles()
      .subscribe({
        next: (result: RoleResponse[]) => {
          this.roles = result.filter(x => x.roleType === roleType);
        },
        error: (error: HttpErrorResponse) => {
          this.serviceErrorHandler.displayHttpErrorDialog(
            this,
            error,
            Constants.ErrorMessages
          );
        },
      })
      .add(() => {
        this.isLoading = false;
      });
  }

  onRolesChange(event: MatSelectChange) {
    console.log(event.value)
    if(event.value.length <= 0){
      this.isAuditFirmSelected = false
    }

    const roles = this.roles.filter(x => event.value.includes(x.id)).map(x => x.name);

    if(roles && roles.includes(Role.AuditFirm)){
      this.isAuditFirmSelected = true;
    } else {
      this.isAuditFirmSelected = false;
    }

  }

  getAuditFirms() {
    this.isLoading = true;
    this.masterDataConfigurationService
      .getAllAudiFirm()
      .subscribe({
        next: (result: AuditFirmResponse[]) => {
          this.auditFirms = result;
        },
        error: (error: HttpErrorResponse) => {
          this.serviceErrorHandler.displayHttpErrorDialog(
            this,
            error,
            Constants.ErrorMessages
          );
        },
      })
      .add(() => {
        this.isLoading = false;
      });
  }

  onSubmit() {
    Object.values(this.systemUserForm.controls).forEach((control) => {
      control.markAsTouched();
    });
    console.log('this.systemUserForm.valid', this.systemUserForm.valid)
    if (this.systemUserForm.valid) {
      console.log(this.pageMode)
      if (this.pageMode === PageMode.Add) {
        this.processCreate();
      } else {
        this.processEdit();
      }
    }
  }

  processCreate() {
    this.isLoading = true;
    this.userManagementService
      .createSystemUser(this.createSystemUserCommand)
      .subscribe({
        next: () => {
          this.showSuccessMessage();
        },
        error: (error: HttpErrorResponse) => {
          this.serviceErrorHandler.displayHttpErrorDialog(
            this,
            error,
            Constants.ErrorMessages
          );
        },
      })
      .add(() => {
        this.isLoading = false;
      });
  }

  processEdit() {
    if (this.selectedSystemUser?.id) {
      this.isLoading = true;
      this.userManagementService
        .updateSystemUser(
          this.selectedSystemUser.id,
          this.updateSystemUserCommand
        )
        .subscribe({
          next: () => {
            this.showSuccessMessage();
          },
          error: (error: HttpErrorResponse) => {
            this.serviceErrorHandler.displayHttpErrorDialog(
              this,
              error,
              Constants.ErrorMessages
            );
          },
        })
        .add(() => {
          this.isLoading = false;
        });
    }
  }

  onCancel() {
    this.NavigateBackToView();
  }

  NavigateBackToView() {
    this.router.NavigateTo.SystemUser();
  }

  showSuccessMessage() {
    this.useGuard = false;
    this.NavigateBackToView();

    this.snackBar.open(
      `System user successfully ${
        this.pageMode === PageMode.Add ? 'created' : 'updated'
      }`
    );
  }
}
