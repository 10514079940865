import {Component, Input} from '@angular/core';
import {Router} from "@angular/router";
import {ListActiveFilter} from "../../enums/separated-enums/list-active-filter.enum";

@Component({
  selector: 'app-active-filter',
  templateUrl: './active-filter.component.html',
  styleUrls: ['./active-filter.component.scss']
})
export class ActiveFilterComponent {

  @Input()
  path?: string;
  constructor(private router: Router){}


  reDirectToActiveFilter(filter: ListActiveFilter | string | null) {
    this.router.navigate([this.path], { queryParams: { filter: filter } });
  }

  protected readonly ListActiveFilter = ListActiveFilter;
}
