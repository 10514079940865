import {
  DetailedServiceTypeListComponent
} from "../../modules/service-management/components/detailed-service-type-list/detailed-service-type-list.component";

export enum PagePaginationNumberNames {
  RequestMadePagination = 'RequestMadePagination',
  AllRequestMadePagination = 'AllRequestMadePagination',
  PagesPagination = 'PagesPagination',
  ReasonsPagination = 'ReasonsPagination',
  DeliverablesPagination = 'DeliverablesPagination',
  UserGuidesPagination = 'UserGuidesPagination',
  SystemUsersPagination = 'SystemUsersPagination',
  RolesPagination = 'RolesPagination',
  SegmentPagination = 'SegmentPagination',
  SubSegmentPagination = 'SubSegmentPagination',
  DetailedSegmentPagination = 'DetailedSegmentPagination',
  ServiceTypePagination = 'ServiceTypePagination',
  ServiceCategoryPagination = 'ServiceCategoryPagination',
  ServiceSubCategoryPagination = 'ServiceSubCategoryPagination',
  DetailedServiceTypePagination= 'DetailedServiceTypePagination',
  AuditFirmPagination = 'AuditFirmPagination',
  CountryPagination = 'CountryPagination',
}
