import {Component, Input, OnInit} from '@angular/core';
import {RequestMaintainBasicData, RequestResponse} from "../../../models";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {PageMode} from "../../../../../enums";

@Component({
  selector: 'app-request-maintain-basic',
  templateUrl: './request-maintain-basic.component.html',
  styleUrls: ['./request-maintain-basic.component.scss']
})
export class RequestMaintainBasicComponent
  implements OnInit
{
  @Input()
  selectedRequest?: RequestResponse;

  @Input()
  pageMode?: PageMode;

  public basicForm: FormGroup;

  protected isLegislationAndLegislationVisible: boolean = false;
  protected isScopeOfWorkVisible: boolean = false;

  constructor() {
    this.basicForm = new FormGroup({});
  }
  ngOnInit(): void {
    this.setupForm();
  }

  protected setupForm(){
    this.basicForm = new FormGroup({
      id: new FormControl(this.selectedRequest ? this.selectedRequest.id : undefined),
      regulatory: new FormControl(this.selectedRequest ? String(this.selectedRequest.regulatory) : '', Validators.required),
      beneficiaryName: new FormControl(this.selectedRequest ? this.selectedRequest.beneficiaryName : '', Validators.required),
      legislation: new FormControl(this.selectedRequest ? this.selectedRequest.legislation : ''),
      sectionOrRegulation: new FormControl(this.selectedRequest ? this.selectedRequest.sectionOrRegulation : ''),
      scopeOfWork: new FormControl(this.selectedRequest ? this.selectedRequest.scopeOfWork : ''),
      title: new FormControl(this.selectedRequest ? this.selectedRequest.title : '', Validators.required),
      name: new FormControl(this.selectedRequest ? this.selectedRequest.name : ''),

    });

    this.basicForm.controls['beneficiaryName'].valueChanges.subscribe((value) => {
      const legislation = this.basicForm.controls['legislation'].value;
      const sectionOrRegulation = this.basicForm.controls['sectionOrRegulation'].value;
      const scopeOfWork = this.basicForm.controls['scopeOfWork'].value;
      const regulatory = this.basicForm.controls['regulatory'].value;
      const  legislationTrueConcat = `${value} ${legislation} ${sectionOrRegulation}`
      const  legislationFalseConcat = `${value} ${scopeOfWork}`

      if (regulatory === 'true') {
        this.basicForm.controls['title'].patchValue(legislationTrueConcat?.trim());
      } else if (regulatory === 'false') {
        this.basicForm.controls['title'].patchValue(legislationFalseConcat?.trim());
      }
    });

    this.basicForm.controls['regulatory'].valueChanges.subscribe((value) => {
      if (value === 'true') {
        this.basicForm.controls['legislation'].setValidators(Validators.required);
        this.basicForm.controls['sectionOrRegulation'].setValidators(Validators.required)
        this.basicForm.controls['scopeOfWork'].clearValidators()
        this.basicForm.controls['scopeOfWork'].patchValue('');
        this.isScopeOfWorkVisible = false;
        this.isLegislationAndLegislationVisible = true;

      } else if (value === 'false') {
        this.basicForm.controls['scopeOfWork'].setValidators(Validators.required);
        this.basicForm.controls['legislation'].clearValidators();
        this.basicForm.controls['legislation'].patchValue('');
        this.basicForm.controls['sectionOrRegulation'].clearValidators();
        this.basicForm.controls['sectionOrRegulation'].patchValue('');
        this.isScopeOfWorkVisible = true;
        this.isLegislationAndLegislationVisible = false;
      }
    });

    this.basicForm.controls['sectionOrRegulation'].valueChanges.subscribe((value) => {
      let Beneficiary = this.basicForm.controls['beneficiaryName'].value;
      let Legislation = this.basicForm.controls['legislation'].value;
      this.basicForm.controls['title'].patchValue((Beneficiary + ' ' + Legislation + ' ' + value)?.trim());
    });

    this.basicForm.controls['scopeOfWork'].valueChanges.subscribe((value) => {
      let Beneficiary = this.basicForm.controls['beneficiaryName'].value;
      this.basicForm.controls['title'].patchValue(Beneficiary?.trim() + ' ' + value?.trim());
    });

    this.basicForm.controls['legislation'].valueChanges.subscribe((value) => {
      const beneficiaryName = this.basicForm.controls['beneficiaryName'].value;
      const sectionOrRegulation = this.basicForm.controls['sectionOrRegulation'].value;

      this.basicForm.controls['title'].patchValue((beneficiaryName + ' ' + value + ' ' + sectionOrRegulation)?.trim());
    });


    if(this.selectedRequest?.regulatory && this.selectedRequest?.regulatory === true){
      this.basicForm.controls['scopeOfWork'].clearValidators()
      this.basicForm.controls['legislation'].setValidators(Validators.required);
      this.basicForm.controls['sectionOrRegulation'].setValidators(Validators.required);
      this.isScopeOfWorkVisible = false;
      this.isLegislationAndLegislationVisible = true;
      this.basicForm.controls['regulatory'].patchValue('true');
      this.basicForm.controls['legislation'].patchValue(this.selectedRequest?.legislation);
      this.basicForm.controls['sectionOrRegulation'].patchValue(this.selectedRequest?.sectionOrRegulation);
    }else if(this.selectedRequest?.regulatory === false){
      this.basicForm.controls['scopeOfWork'].setValidators(Validators.required);
      this.basicForm.controls['legislation'].clearValidators();
      this.basicForm.controls['sectionOrRegulation'].clearValidators();
      this.isScopeOfWorkVisible = true;
      this.isLegislationAndLegislationVisible = false;
      this.basicForm.controls['regulatory'].patchValue('false');
      this.basicForm.controls['scopeOfWork'].patchValue(this.selectedRequest?.scopeOfWork);
    }
  }

  clearForm(): void {
    this.basicForm = new FormGroup({
      id: new FormControl(undefined),
      regulatory: new FormControl( '', Validators.required),
      beneficiaryName: new FormControl('', Validators.required),
      legislation: new FormControl(null),
      sectionOrRegulation: new FormControl( ''),
      scopeOfWork: new FormControl(''),
      title: new FormControl('', Validators.required),
      name: new FormControl(''),

    });

    this.basicForm.controls['beneficiaryName'].valueChanges.subscribe((value) => {
      const legislation = this.basicForm.controls['legislation'].value;
      const sectionOrRegulation = this.basicForm.controls['sectionOrRegulation'].value;
      const scopeOfWork = this.basicForm.controls['scopeOfWork'].value;
      const regulatory = this.basicForm.controls['regulatory'].value;
      const  legislationTrueConcat = `${value} ${legislation} ${sectionOrRegulation}`
      const  legislationFalseConcat = `${value} ${scopeOfWork}`

      if (regulatory === 'true') {
        this.basicForm.controls['title'].patchValue(legislationTrueConcat?.trim());
      } else if (regulatory === 'false') {
        this.basicForm.controls['title'].patchValue(legislationFalseConcat?.trim());
      }
    });

    this.basicForm.controls['regulatory'].valueChanges.subscribe((value) => {
      if (value === 'true') {
        this.basicForm.controls['legislation'].setValidators(Validators.required);
        this.basicForm.controls['sectionOrRegulation'].setValidators(Validators.required)
        this.basicForm.controls['scopeOfWork'].clearValidators()
        this.basicForm.controls['scopeOfWork'].patchValue('');
        this.isScopeOfWorkVisible = false;
        this.isLegislationAndLegislationVisible = true;

      } else if (value === 'false') {
        this.basicForm.controls['scopeOfWork'].setValidators(Validators.required);
        this.basicForm.controls['legislation'].clearValidators();
        this.basicForm.controls['legislation'].patchValue('');
        this.basicForm.controls['sectionOrRegulation'].clearValidators();
        this.basicForm.controls['sectionOrRegulation'].patchValue('');
        this.isScopeOfWorkVisible = true;
        this.isLegislationAndLegislationVisible = false;
      }
    });

    this.basicForm.controls['sectionOrRegulation'].valueChanges.subscribe((value) => {
      let Beneficiary = this.basicForm.controls['beneficiaryName'].value;
      let Legislation = this.basicForm.controls['legislation'].value;
      this.basicForm.controls['title'].patchValue((Beneficiary + ' ' + Legislation + ' ' + value)?.trim());
    });

    this.basicForm.controls['scopeOfWork'].valueChanges.subscribe((value) => {
      let Beneficiary = this.basicForm.controls['beneficiaryName'].value;
      this.basicForm.controls['title'].patchValue((Beneficiary + ' ' + value)?.trim());
    });

    this.basicForm.controls['legislation'].valueChanges.subscribe((value) => {
      const beneficiaryName = this.basicForm.controls['beneficiaryName'].value;
      const sectionOrRegulation = this.basicForm.controls['sectionOrRegulation'].value;

      this.basicForm.controls['title'].patchValue((beneficiaryName + ' ' + value + ' ' + sectionOrRegulation)?.trim());
    });
  }

  public fetchData(): RequestMaintainBasicData {
    const legislation = this.basicForm.controls['legislation'].value;
    const sectionOrRegulation = this.basicForm.controls['sectionOrRegulation'].value;
    const scopeOfWork = this.basicForm.controls['scopeOfWork'].value;
    return {
      id: Number(this.basicForm.controls['id'].value),
      regulatory: this.basicForm.controls['regulatory'].value === 'true',
      beneficiaryName: this.basicForm.controls['beneficiaryName'].value,
      legislation: legislation != '' ? legislation : null,
      sectionOrRegulation: sectionOrRegulation != null ? sectionOrRegulation : null,
      scopeOfWork: scopeOfWork != '' ? scopeOfWork : null,
      title: this.basicForm.controls['title'].value,
      name: this.basicForm.controls['name'].value
    } as RequestMaintainBasicData
  }
}
