import {AbstractServiceAuthentication} from "./authentication.service.abstract";
import {AbstractEndpoints} from "../../../environments/endpoints/endpoints.abstract";
import {MsalBroadcastService, MsalService} from "@azure/msal-angular";
import {ServiceMonitoring} from "../monitor/monitor.service";
import {AppSettings} from "../../../environments/app-settings/app-settings";
import {Environments} from "../../enums/separated-enums/environments.enum";
import {ServiceAuthentication} from "./authentication.service";

export function FactoryServiceAuthentication(
  serviceMsal: MsalService,
  serviceBroadcast: MsalBroadcastService,
  endpoints: AbstractEndpoints,
  serviceMonitor: ServiceMonitoring,
): AbstractServiceAuthentication {
  serviceMonitor.logEvent('FactoryServiceAuthentication', 'Authentication Service Factory loaded');
  switch (AppSettings.environment) {
    case Environments.MockData:
      // return new MockServiceAuthentication(httpClient, endpoints);
      return new ServiceAuthentication(serviceMsal, serviceBroadcast, serviceMonitor);
    default:
      return new ServiceAuthentication(serviceMsal, serviceBroadcast, serviceMonitor);
  }
}
