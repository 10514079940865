<form [formGroup]="serviceTypeForm" fxLayout="column" fxLayoutGap="2px">
  <mat-form-field fxFlex appearance="fill">
    <mat-label >{{ "Service Type" }}</mat-label>
    <mat-select formControlName="serviceTypeId">
      <ng-container>
        <mat-option *ngFor="let p of serverTypes" [value]="p.id">
          {{ p.name }}
        </mat-option>
      </ng-container>
    </mat-select>
    <mat-error *ngIf="serviceTypeForm.controls['serviceTypeId'].hasError('required')">
      The Service Type field is required.
    </mat-error>
  </mat-form-field>

  <mat-form-field fxFlex appearance="fill">
    <mat-label >{{ "Service Category" }}</mat-label>
    <mat-select formControlName="serviceCategoryId">
      <ng-container>
        <mat-option *ngFor="let p of serviceCategories" [value]="p.id">
          {{ p.name }}
        </mat-option>
      </ng-container>
    </mat-select>
    <mat-error *ngIf="serviceTypeForm.controls['serviceCategoryId'].hasError('required')">
      The Service Category field is required.
    </mat-error>
  </mat-form-field>

  <mat-form-field fxFlex appearance="fill" *ngIf="isServiceSubCategoryVisible">
    <mat-label >{{ "Service Sub Category" }}</mat-label>
    <mat-select formControlName="serviceSubCategoryId">
      <ng-container>
        <mat-option>Select Sub Category</mat-option>
        <mat-option *ngFor="let p of serviceSubCategories" [value]="p.id">
          {{ p.name }}
        </mat-option>
      </ng-container>
    </mat-select>
    <mat-error *ngIf="serviceTypeForm.controls['serviceSubCategoryId'].hasError('required')">
      Service Sub Category field is required.
    </mat-error>
  </mat-form-field>

  <mat-form-field fxFlex appearance="fill">
    <mat-label>{{ "Expected Date Of Nas Delivery" }}</mat-label>
    <input
      matInput
      [min]="minDate"
      [matDatepicker]="dateToPicker"
      formControlName="auditReportDate"
      readonly
      (click)="dateToPicker.open()"
    />
    <mat-datepicker-toggle
      matIconSuffix
      [for]="dateToPicker"
    ></mat-datepicker-toggle>

    <mat-datepicker #dateToPicker>
      <mat-datepicker-actions>
        <button mat-button (click)="clearDateCreated(); dateToPicker.close()">
          Clear
        </button>
        <button mat-button matDatepickerCancel>Cancel</button>
        <button mat-raised-button color="primary" matDatepickerApply>
          OK
        </button>
      </mat-datepicker-actions>
    </mat-datepicker>
    <mat-error *ngIf="serviceTypeForm.controls['auditReportDate'].hasError('required')">
      The Expected date of NAS Delivery field is required.
    </mat-error>
  </mat-form-field>

  <mat-form-field fxFlex appearance="fill">
    <mat-label >{{ "Deliverable" }}</mat-label>
    <mat-select formControlName="deliverableId">
      <ng-container>
        <mat-option *ngFor="let p of deliverables" [value]="p.id">
          {{ p.name}}
        </mat-option>
      </ng-container>
    </mat-select>
    <mat-error *ngIf="serviceTypeForm.controls['deliverableId'].hasError('required')">
      The Deliverable field is required.
    </mat-error>
  </mat-form-field>
</form>
