import {Observable} from "rxjs";
import {CreateRequestCommand, RequestDetailsSearchQuery, RequestResponse, UpdateRequestCommand} from "../models";
import {RequestFileResponse} from "../models/request/request-file-response.model";
import {CreateTempRequestCommand} from "../models/request/create-temp-request-command.model";
import {UpdateTempRequestCommand} from "../models/request/update-temp-request-command.model";

export abstract class RequestManagementServiceAbstract{
  //Request
  abstract getAllRequests(): Observable<RequestResponse[]>;
  abstract getRequestById(id: number): Observable<RequestResponse>;
  abstract getRequestByBusinessArea(queryString: RequestDetailsSearchQuery): Observable<RequestResponse[]>;
  abstract getRequestByStatus(status: string): Observable<RequestResponse[]>;
  abstract createRequest(newRequest: CreateRequestCommand): Observable<number>;
  abstract updateRequest(id: number, updateRequest: UpdateRequestCommand): Observable<number>;
  abstract deleteRequest(id: number): Observable<number>;
  abstract addRequestFileById(formData: FormData):  Observable<RequestFileResponse>;

  //Temp Request
  abstract getAllTempRequests(): Observable<RequestResponse[]>;
  abstract getTempRequestById(id: number): Observable<RequestResponse>;
  abstract getTempRequestByEmailAddress(emailAddress: string): Observable<RequestResponse>;
  abstract createTempRequest(newRequest: CreateTempRequestCommand): Observable<number>;
  abstract updateTempRequest(id: number, updateRequest: UpdateTempRequestCommand): Observable<number>;
  abstract deleteTempRequest(id: number): Observable<number>;
  abstract addTempRequestFileById(formData: FormData):  Observable<RequestFileResponse>;
}
