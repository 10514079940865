import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Constants, ReasonResponse, RequestMaintainSimilarRequestReasonData, RequestResponse} from "../../../models";
import {
    MasterDataConfigurationServiceAbstract
} from "../../../../master-data-configuration/services/master-data-configuration.service.abstract";
import {ServiceErrorHandler} from "../../../../../service/error-handler/error-handler.service";
import {HttpErrorResponse} from "@angular/common/http";

@Component({
  selector: 'app-request-maintain-similar-request-reason',
  templateUrl: './request-maintain-similar-request-reason.component.html',
  styleUrls: ['./request-maintain-similar-request-reason.component.scss']
})
export class RequestMaintainSimilarRequestReasonComponent
 implements OnInit
{
    @Input()
    selectedRequest?: RequestResponse;

    isLoading = false;
    reasonForm: FormGroup;

    isOldRequestVisible: boolean = false;

    reasons: ReasonResponse[] = [];

    constructor(
        private masterDataConfigurationService: MasterDataConfigurationServiceAbstract,
        private serviceErrorHandler: ServiceErrorHandler,) {
        this.reasonForm = new FormGroup({});
    }

    ngOnInit() : void {
        this.setupForm();
        this.getReasons();
    }

    protected setupForm(): void {
        this.reasonForm = new FormGroup({
            submissionLastYear: new FormControl(this.selectedRequest ? String(this.selectedRequest.submissionLastYear) : '', Validators.required),
            oldRequest: new FormControl(this.selectedRequest ? this.selectedRequest.oldRequest : ''),
            reasonAuditorsId: new FormControl(this.selectedRequest ? this.selectedRequest.reasonAuditors?.id: null, Validators.required),
        });
        this.reasonForm.controls['submissionLastYear'].valueChanges.subscribe((value) => {
           console.log('submissionLastYear', value)

            if (value === 'true') {
              this.reasonForm.controls['oldRequest'].setValidators(Validators.required);
              this.isOldRequestVisible = true;
            } else if (value === 'false') {
              this.reasonForm.controls['oldRequest'].clearValidators()
              this.reasonForm.controls['oldRequest'].patchValue('')
              this.isOldRequestVisible = false;
            }
        });

        const value = this.selectedRequest?.submissionLastYear ?? false;

        if (value) {
          this.reasonForm.controls['oldRequest'].setValidators(Validators.required);
          this.isOldRequestVisible = true;
        } else if (!value) {
          this.reasonForm.controls['oldRequest'].clearValidators();
          this.isOldRequestVisible = false;
        }
    }

    clearForm(): void {
      this.reasonForm = new FormGroup({
        submissionLastYear: new FormControl(false, Validators.required),
        oldRequest: new FormControl(''),
        reasonAuditorsId: new FormControl(undefined, Validators.required),
      });

      this.reasonForm.controls['submissionLastYear'].valueChanges.subscribe((value) => {
        if (value === 'true') {
          this.reasonForm.controls['oldRequest'].setValidators(Validators.required);
          this.isOldRequestVisible = true;
        } else if (value === 'false') {
          this.isOldRequestVisible = false;
          this.reasonForm.controls['oldRequest'].clearValidators()
          this.reasonForm.controls['oldRequest'].patchValue('')
        }
      });
    }

    protected getReasons(): void {
        this.isLoading = true;
        this.masterDataConfigurationService
            .getAllReasons()
            .subscribe({
                next: (result: ReasonResponse[]) => {
                    this.reasons = result?.filter(x => x?.isActive);
                },
                error: (error: HttpErrorResponse) => {
                    this.serviceErrorHandler.displayHttpErrorDialog(
                        this,
                        error,
                        Constants.ErrorMessages
                    );
                },
            })
            .add(() => {
                this.isLoading = false;
            });
    }

    public fetchData(): RequestMaintainSimilarRequestReasonData {
      const submissionLastYear = this.reasonForm.controls['submissionLastYear'].value === 'true' ? true : this.reasonForm.controls['submissionLastYear'].value === 'false' ? false : null;
        return {
            submissionLastYear : submissionLastYear,
            oldRequest: this.reasonForm.controls['oldRequest'].value,
            reasonAuditorsId: this.reasonForm.controls['reasonAuditorsId'].value ? Number(this.reasonForm.controls['reasonAuditorsId'].value) : null,
        } as RequestMaintainSimilarRequestReasonData;
    }
}
