import {Component, OnInit} from '@angular/core';
import {PageMode} from "../../../../enums/separated-enums/page-mode.enum";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {CanComponentDeactivateConfirm} from "../../../../guards/navigate-away-confirm.guard";
import {Router} from "@angular/router";
import {SnackBarService} from "../../../../service/snack-bar/snack-bar.service";
import {ServiceErrorHandler} from "../../../../service/error-handler/error-handler.service";
import {HttpErrorResponse} from "@angular/common/http";
import {CountryResponse, CreateCountryCommand, UpdateCountryCommand} from "../../models/country";
import {CountryManagementValidator} from "../../validators/country-management.validator";
import {CountryManagementServiceAbstract} from "../../services/country-management.service.abstract";
import {Constants} from "../../models/constants";

@Component({
  selector: 'app-country-maintain',
  templateUrl: './country-maintain.component.html',
  styleUrls: ['./country-maintain.component.scss']
})
export class CountryMaintainComponent implements OnInit, CanComponentDeactivateConfirm {
  existingCountries?: CountryResponse[];
  selectedCountry?: CountryResponse;
  pageMode: PageMode = PageMode.Add;
  pageModeText = 'Create new';
  submitButtonText = 'Create';
  isLoading = false;
  countryForm: FormGroup;
  useGuard = true;


  constructor(
    public router: Router,
    private snackBar: SnackBarService,
    private lookupValidator: CountryManagementValidator,
    private countryManagementService: CountryManagementServiceAbstract,
    private serviceErrorHandler: ServiceErrorHandler,
  ){
    const parameters: any = router.GetNavigationParameter(
      CountryMaintainComponent
    );

    if (parameters) {
      this.existingCountries = parameters.existingCountries;
      this.selectedCountry = parameters.selectedCountry;

      if (this.selectedCountry) {
        this.pageMode = PageMode.Edit;
        this.pageModeText = 'Edit';
        this.submitButtonText = 'Update';
      } else {
        this.pageMode = PageMode.Add;
        this.pageModeText = 'Create new';
        this.submitButtonText = 'Create';
      }
    } else {
      router.NavigateTo.Country();
    }

    this.countryForm = new FormGroup({});
  }

  ngOnInit(): void {
    this.setupForm();
  }

  confirmNavigateAway(): boolean {
    return (
      this.countryForm.touched && this.countryForm.dirty && this.useGuard
    );
  }

  get nameCharacterCount() {
    return this.countryForm.controls['name'].value.length;
  }

  get createCountryCommand() {
    return {
      name: this.countryForm.controls['name'].value.trim(),

    } as CreateCountryCommand;
  }

  get updateCountryCommand() {
    return {
      name: this.countryForm.controls['name'].value.trim(),
    } as UpdateCountryCommand;
  }

  setupForm() {
    this.countryForm = new FormGroup({
      id: new FormControl(
        this.selectedCountry ? this.selectedCountry.id : undefined
      ),
      name: new FormControl(
        this.selectedCountry ? this.selectedCountry.name : ''
      )
    });

    this.countryForm.controls['name'].setValidators([
      Validators.required,
      this.lookupValidator.duplicateNameValidator(
        this.countryForm,
        this.existingCountries
      ),
    ]);
  }

  onSubmit() {
    Object.values(this.countryForm.controls).forEach((control) => {
      control.markAsTouched();
    });

    if (this.countryForm.valid) {
      if (this.pageMode === PageMode.Add) {
        this.processCreate();
      } else {
        this.processEdit();
      }
    }
  }

  processCreate() {
    this.isLoading = true;
    this.countryManagementService
      .createCountry(this.createCountryCommand)
      .subscribe({
        next: () => {
          this.showSuccessMessage();
        },
        error: (error: HttpErrorResponse) => {
          this.serviceErrorHandler.displayHttpErrorDialog(
            this,
            error,
            Constants.ErrorMessages
          );
        },
      })
      .add(() => {
        this.isLoading = false;
      });
  }

  processEdit() {
    if (this.selectedCountry?.id) {
      this.isLoading = true;
      this.countryManagementService
        .updateCountry(
          this.selectedCountry.id,
          this.updateCountryCommand
        )
        .subscribe({
          next: () => {
            this.showSuccessMessage();
          },
          error: (error: HttpErrorResponse) => {
            this.serviceErrorHandler.displayHttpErrorDialog(
              this,
              error,
              Constants.ErrorMessages
            );
          },
        })
        .add(() => {
          this.isLoading = false;
        });
    }
  }

  onCancel() {
    this.NavigateBackToView();
  }

  NavigateBackToView() {
    this.router.NavigateTo.Country();
  }

  showSuccessMessage() {
    this.useGuard = false;
    this.NavigateBackToView();

    this.snackBar.open(
      `Country successfully ${
        this.pageMode === PageMode.Add ? 'created' : 'updated'
      }`
    );
  }

}
