import {Observable} from "rxjs";
import {CreateRoleCommand, RoleResponse, UpdateRoleCommand} from "../models/role";
import {CreateSystemUserCommand, SystemUserResponse, UpdateSystemUsersCommand} from "../models/system-user";

export abstract  class UserManagementServiceAbstract{
  //Role

  abstract getAllRoles(): Observable<RoleResponse[]>;
  abstract getRoleById(id: number): Observable<RoleResponse>;
  abstract createRole(newRole: CreateRoleCommand): Observable<RoleResponse>;
  abstract updateRole(id: number, updateRole: UpdateRoleCommand): Observable<RoleResponse>;
  abstract deleteRole(id: number): Observable<number>;
  abstract hideUser(id: number): Observable<number>;
  abstract showUser(id: number): Observable<number>;

  //System Users
  abstract getAllSystemUsers(): Observable<SystemUserResponse[]>;
  abstract getSystemUserById(id: number): Observable<SystemUserResponse>;
  abstract getSystemUserByEmailAddress(email: string): Observable<SystemUserResponse>;
  abstract createSystemUser(newSystemUser: CreateSystemUserCommand): Observable<SystemUserResponse>;
  abstract updateSystemUser(id: number, updateSystemUser: UpdateSystemUsersCommand): Observable<SystemUserResponse>;
  abstract deleteSystemUser(id: number): Observable<number>;
}
