<div fxLayout="column" fxLayoutAlign="" fxLayoutGap="0px" class="surface-3">
  <div
    fxLayout="column"
    fxLayoutAlign="center center"
    fxLayoutGap="32px"
    class="box-p-8"
  >
    <mat-icon color="primary">
      {{ icon }}
    </mat-icon>
    <div fxLayout="column" fxLayoutGap="16px" fxLayoutAlign="center center">
      <div class="mat-headline">{{ header }}</div>
      <div *ngIf="topic" class="mat-body-1">{{ topic }}</div>
      <div class="mat-body-2">{{ message }}</div>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div
    fxLayout="row"
    fxFlex
    fxLayoutAlign="end center"
    fxLayoutGap="8px"
    class="box-p-8"
  >
    <button
      color="primary"
      class="mat-button"
      *ngIf="displayCancelButton"
      mat-button
      mat-dialog-close="false"
    >
      {{ cancelButtonText }}
    </button>
    <button
      class="mat-flat-button"
      color="primary"
      mat-flat-button
      mat-dialog-close="true"
    >
      {{ okButtonText }}
    </button>
  </div>
</div>
