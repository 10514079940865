  import {ServiceConfig} from "../../app/service/config/config.service";
import {
  BrowserCacheLocation,
  InteractionType,
  IPublicClientApplication,
  PublicClientApplication
} from "@azure/msal-browser";
import {MsalGuardConfiguration, MsalInterceptorConfiguration} from "@azure/msal-angular";
import {NavigationRoutes} from "../../app/helpers/navigation.routes.helper";

export const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

export function MSALInstanceFactory(
  serviceConfig: ServiceConfig
): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: serviceConfig.authentication.clientId,
      authority: serviceConfig.authentication.authority,
      navigateToLoginRequestUrl:
      serviceConfig.authentication.navigateToLoginRequestUrl,
      knownAuthorities: ['https://login.microsoftonline.com'],
      redirectUri: serviceConfig.authentication.redirectUri,
      postLogoutRedirectUri: serviceConfig.authentication.postLogoutRedirectUri,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.SessionStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },

    system: {
      loggerOptions: {
        piiLoggingEnabled: false,
      },
    },
  });
}

export function MSALInterceptorConfigFactory(
  serviceConfig: ServiceConfig
): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(serviceConfig.authentication.apiScope, [
    'user.read',
  ]);

  serviceConfig.authentication.navigateToLoginRequestUrl;

  protectedResourceMap.set(serviceConfig.apiBaseURL, [
    serviceConfig.authentication.apiScope,
  ]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read'],
    },

    loginFailedRoute: NavigationRoutes.LandingPage.path,
  };
}
