import {ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import { NoAccessHideModel } from 'src/app/model/no-access-hide/no-access-hide.model';
import {Role} from "../../enums";
import {Router} from "@angular/router";
import {AbstractServiceAuthentication} from "../../service/authentication/authentication.service.abstract";
import {SessionStorage} from "../../service/session/session.storage";
import {Idle} from "@ng-idle/core";
import {Subject} from "rxjs";
import {NavigationRoutes} from "../../helpers/navigation.routes.helper";
import {NavigationMenus, NavigationMenusType} from "../../constants";
import {NavigationService} from "../../service/navigation/navigation.service";

@Component({
  selector: 'app-left-side-nav',
  templateUrl: './left-side-nav.component.html',
  styleUrls: ['./left-side-nav.component.scss']
})
export class LeftSideNavComponent implements OnInit, OnDestroy  {
  title = 'Absa Angular App';
  private userInitials = '';
  private _userName = '';
  role = Role;
  isIframe = false;
  countdown?: number | null = null;
  counterStarted: boolean = false;
  openSideNav: boolean = true;
  isSubMenuOpened: boolean = false;
  navigationRoute = NavigationRoutes;
  IsLoggedIn: boolean = false;
  navigationMenus: NavigationMenusType[] = NavigationMenus;
  getScreenWidth?: number;
  getScreenHeight?: number;

  private readonly _destroying$ = new Subject<void>();

  public get userName() {
    return this._userName;
  }


  public get sideNavCurrentClasses(){
    return {
      sideNavOpen: this.openSideNav,
      sideNavClose: !this.openSideNav
    };
  }

  public get navTextCurrentClasses() {
    return {
      hideNavText : !this.openSideNav,
      showNavText: this.openSideNav
    }
  }

  public get navTextMoveCurrentClasses() {
    return {
      moveNavTextRight: !this.openSideNav,
      moveNavTextBack: this.openSideNav,
    }
  }

  public get sideNavLogoCurrentClasses(){
    return {
      rotateLogo: !this.openSideNav
    };
  }

  public get subMenuIconCurrentClasses(){
    return {
      rotateSubMenuIconOpen : this.isSubMenuOpened,
      rotateSubMenuIconClose : !this.isSubMenuOpened
    }
  }

  public get sideNavTextCurrentClasses() {
    return {

    };
  }

  public get mainContentCurrentClasses(){
    return {
      mainContentOpen: !this.openSideNav,
      mainContentClose: this.openSideNav
    };
  }

  public set userName(value) {
    this._userName = value;
    this.userInitials = this.getInitials(value);
  }
  constructor(
    private router: Router,
    private serviceAuth: AbstractServiceAuthentication,
    private sessionStorage: SessionStorage,
    private idle: Idle,
    private navigationService: NavigationService,
  ) {}

  ngOnInit(): void {
    this.serviceAuth.isLoggedIn.subscribe((isLoggedIn: boolean) => {
      if (isLoggedIn) {
        this.IsLoggedIn = isLoggedIn;
        this.userName = this.serviceAuth.account?.name ?? '';
      } else {
        this.userName = '';
        this.router.NavigateTo.Login();

      }
    });

    this.navigationService.openSideNav.subscribe((openSideNav: boolean) => {
      this.openSideNav = openSideNav;
    });

    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;

    this.openSideNav = this.getScreenWidth >= 1000;
    // right when the component initializes, start reset state and start watching
    // this.configureAutoLogout();
    // this.reset();
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
    this.openSideNav = this.getScreenWidth >= 1000;
  }

  get initials(): string {
    return this.userInitials;
  }
  get logo(): string {
    return `./assets/img/brand/absa-w.png`;
  }
  navigateTo(route: string): void {
    switch (route) {
      case this.navigationRoute.Auth.fullPath:
        this.router.NavigateTo.Auth();
        break;
      case this.navigationRoute.DashBoard.fullPath:
        this.router.NavigateTo.DashBoard();
        break;
      case this.navigationRoute.MasterDataConfiguration.fullPath:
        this.router.NavigateTo.MasterDataConfiguration();
        break;
      case this.navigationRoute.Pages.fullPath:
        this.router.NavigateTo.Pages();
        break;
      case this.navigationRoute.Request.fullPath:
        this.router.NavigateTo.Request();
        break;

      case this.navigationRoute.UserManagement.fullPath:
        this.router.NavigateTo.UserManagement();
        break;
    }
  }

  toggleSideNav(value: boolean) {
      this.navigationService.openSideNav.next(value)
  }

  toggleMobileSideNav(value: boolean){
    const isToggle  =  this.getScreenWidth! < 1000 && value;
    console.log('toggleMobileSideNav', isToggle);
    if(isToggle)
      this.navigationService.openSideNav.next(value)
  }
  hideByRole(route: string){

    const allowRolesForNavigationPath = this.getAllowedRolesForNavigationPath(route);

    if(allowRolesForNavigationPath?.includes(Role.OnlyWhenNotAdmin) && this.sessionStorage.getSystemRoles?.includes(Role.Admin))
      return false;

    if(allowRolesForNavigationPath?.includes(Role.OnlyWhenNotAdmin) && !this.sessionStorage.getSystemRoles?.includes(Role.Admin))
      return true;

    if(allowRolesForNavigationPath === undefined || allowRolesForNavigationPath?.length === 0)
      return true;

    return allowRolesForNavigationPath?.some((value)=> {
        return this.serviceAuth.account?.idTokenClaims.roles?.includes(
          value.toString()
        );
      }
    );
  }
  get hideIfNotAdmin(){
    return this.sessionStorage.getSystemRoles?.includes(Role.Admin.toString()) ?? false
  }
  getAllowedRolesForNavigationPath(route: string): Role[] | undefined {
    switch (route) {
      case this.navigationRoute.LandingPage.fullPath:
        return this.navigationRoute.LandingPage.allowedRoles;
     case this.navigationRoute.Policy.fullPath:
        return this.navigationRoute.Policy.allowedRoles;
      case this.navigationRoute.UserGuideDownload.fullPath:
        return this.navigationRoute.UserGuideDownload.allowedRoles;
      case this.navigationRoute.Request.fullPath:
        return this.navigationRoute.Request.allowedRoles;
      case this.navigationRoute.RequestMade.fullPath:
        return this.navigationRoute.RequestMade.allowedRoles;
      case this.navigationRoute.MaintainRequest.fullPath:
        return this.navigationRoute.MaintainRequest.allowedRoles;
      case this.navigationRoute.UserGuide.fullPath:
        return this.navigationRoute.UserGuide.allowedRoles;
      case this.navigationRoute.MaintainPolicy.fullPath:
        return this.navigationRoute.MaintainPolicy.allowedRoles;
      case this.navigationRoute.AuditFirm.fullPath:
        return this.navigationRoute.AuditFirm.allowedRoles;
      case this.navigationRoute.Country.fullPath:
        return this.navigationRoute.Country.allowedRoles;
      case this.navigationRoute.Deliverable.fullPath:
        return this.navigationRoute.Deliverable.allowedRoles;
      case this.navigationRoute.Reason.fullPath:
        return this.navigationRoute.Reason.allowedRoles;
      case this.navigationRoute.Role.fullPath:
        return this.navigationRoute.Role.allowedRoles;
      case this.navigationRoute.Segment.fullPath:
        return this.navigationRoute.Segment.allowedRoles;
      case this.navigationRoute.ServiceType.fullPath:
        return this.navigationRoute.ServiceType.allowedRoles;
      case this.navigationRoute.UserManagement.fullPath:
        return this.navigationRoute.UserManagement.allowedRoles;
      case this.navigationRoute.SystemUser.fullPath:
        return this.navigationRoute.SystemUser.allowedRoles;
      case this.navigationRoute.Admin.fullPath:
        return this.navigationRoute.Admin.allowedRoles;
      case this.navigationRoute.DashBoard.fullPath:
        return this.navigationRoute.DashBoard.allowedRoles;
      case this.navigationRoute.DashBoardHome.fullPath:
        return this.navigationRoute.DashBoardHome.allowedRoles;
      default:
        return undefined;
    }
  }

  logout(): void {
    this.serviceAuth.logout();
  }

  getInitials(name: string): string {
    if (!name || name.length === 0) {
      return '';
    }

    // Split the string at the comma and take the second part (First Name)
    const namePart = name.split(',')[1];

    // If there is a name part, take the first character
    const firstLetterName = namePart ? namePart.trim()[0].toUpperCase() : '';

    // Get the first letter of the original string
    const firstLetterSurname = name.charAt(0).toUpperCase();

    // Concatenate the first letter and surname
    return firstLetterName + firstLetterSurname;
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  // configureAutoLogout() {
  //   // set idle parameters
  //   this.idle.setIdle(environment.production == true ? 300 : 120);
  //   this.idle.setTimeout(11); // how long can they be idle before considered timed out, in seconds
  //   this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); // provide sources that will "interrupt" aka provide events indicating the user is active
  //
  //   // do something when the user becomes idle
  //   this.idle.onIdleStart.subscribe(() => {
  //     if (this.serviceAuth.account) {
  //       this.counterStarted = true;
  //     }
  //   });
  //   // do something when the user is no longer idle
  //   this.idle.onIdleEnd.subscribe(() => {
  //     this.countdown = null;
  //     this.counterStarted = false;
  //     this.cd.detectChanges(); // how do i avoid this kludge?
  //   });
  //   // do something when the user has timed out
  //   this.idle.onTimeout.subscribe(() => {
  //     //(this.idleState = 'TIMED_OUT')
  //     if (this.serviceAuth.account) {
  //       this.logout();
  //     }
  //   });
  //   // do something as the timeout countdown does its thing
  //   this.idle.onTimeoutWarning.subscribe(
  //     (seconds) => (this.countdown = seconds)
  //   );
  // }

  reset() {
    // we'll call this method when we want to start/reset the idle process
    // reset any component state and be sure to call idle.watch()
    this.idle.watch();
    this.countdown = null;
  }
}
