import {Observable} from "rxjs";
import {PolicyDocumentResponse} from "../models/policy/policy-document-response.model";
import {UserGuideResponse} from "../models/user-guide/user-guide-response.model";
import {PageTemplateResponse} from "../models";

export abstract class PagesServiceAbstract {
  //Policy
  abstract createPolicy(file: FormData): Observable<PolicyDocumentResponse>;

  //
  abstract getAllUserGuides(): Observable<UserGuideResponse[]>;
  abstract GetUserGuideById(userGuideId: number): Observable<UserGuideResponse>;
  abstract createUserGuide(file: FormData): Observable<number>;
  abstract updateUserGuide(userGuideId: number, file: FormData): Observable<number>;
  abstract deleteUserGuide(userGuideId: number): Observable<number>;
  abstract getActivePageTemplate(pageTarget: number): Observable<PageTemplateResponse>;
}
