import {Component, OnDestroy, OnInit} from '@angular/core';
import {CanComponentDeactivateConfirm} from "../../../../guards/navigate-away-confirm.guard";
import {PageMode, PageTemplateTarget} from "../../../../enums";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {SnackBarService} from "../../../../service/snack-bar/snack-bar.service";
import {ServiceErrorHandler} from "../../../../service/error-handler/error-handler.service";
import {PagesManagementServiceAbstract} from "../../services/pages-management.service.abstract";
import {CreatePageTemplateCommand, PageTemplateResponse, UpdatePageTemplateCommandModel} from "../../models";
import {PagesManagementValidator} from "../../validators/pages-management.validator";
import {HttpErrorResponse} from "@angular/common/http";
import {Constants} from "../../../country-management/models/constants";
import {Editor, toDoc, toHTML, Toolbar} from "ngx-editor";

@Component({
  selector: 'app-home-maintain',
  templateUrl: './home-maintain.component.html',
  styleUrls: ['./home-maintain.component.scss']
})
export class HomeMaintainComponent implements OnInit, OnDestroy, CanComponentDeactivateConfirm  {
  editor: Editor;
  existingPageTemplates?: PageTemplateResponse[];
  selectedPageTemplate?: PageTemplateResponse;
  pageMode: PageMode = PageMode.Add;
  pageModeText = 'Create new';
  submitButtonText = 'Create';
  isLoading = false;
  pageTemplateForm: FormGroup;
  useGuard = true;

  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['link', 'image'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];
  colorPresets = ['red', '#FF0000', 'rgb(255, 0, 0)'];

  constructor(
    public router: Router,
    private snackBar: SnackBarService,
    private lookupValidator: PagesManagementValidator,
    private pagesManagementService: PagesManagementServiceAbstract,
    private serviceErrorHandler: ServiceErrorHandler,
  ){
    const parameters: any = router.GetNavigationParameter(
      HomeMaintainComponent
    );

    if (parameters) {
      this.existingPageTemplates = parameters.existingPageTemplates;
      this.selectedPageTemplate = parameters.selectedPageTemplate;

      if (this.selectedPageTemplate) {
        this.pageMode = PageMode.Edit;
        this.pageModeText = 'Edit';
        this.submitButtonText = 'Update';
      } else {
        this.pageMode = PageMode.Add;
        this.pageModeText = 'Create new';
        this.submitButtonText = 'Create';
      }
    } else {
      router.NavigateTo.HomePageTemplate();
    }

    this.pageTemplateForm = new FormGroup({});
    this.editor = new Editor({
      inputRules: true,
      keyboardShortcuts: true,
    });
  }

  ngOnInit(): void {
    this.setupForm();
    this.editor = new Editor();
  }

  confirmNavigateAway(): boolean {
    return (
      this.pageTemplateForm.touched && this.pageTemplateForm.dirty && this.useGuard
    );
  }

  get templateNameCharacterCount() {
    return this.pageTemplateForm.controls['templateName'].value.length;
  }

  get createPageTemplateCommand() {
    return {
      templateName: this.pageTemplateForm.controls['templateName'].value.trim(),
      pageTarget: PageTemplateTarget.HomePage,
      showGreeting: this.pageTemplateForm.controls['showGreeting'].value === 'true',
      templateHtml: null,
      templateJson: this.pageTemplateForm.controls['templateJson'].value,
      isActive: this.selectedPageTemplate?.isActive ?? false,
    } as CreatePageTemplateCommand;
  }

  get updateCountryCommand() {
    return {
      id: this.pageTemplateForm.controls['id'].value,
      templateName: this.pageTemplateForm.controls['templateName'].value.trim(),
      pageTarget: PageTemplateTarget.HomePage,
      showGreeting: this.pageTemplateForm.controls['showGreeting'].value === 'true',
      templateHtml: null,
      templateJson: this.pageTemplateForm.controls['templateJson'].value,
      isActive: this.selectedPageTemplate?.isActive ?? false,
    } as UpdatePageTemplateCommandModel;
  }

  setupForm() {
    this.pageTemplateForm = new FormGroup({
      id: new FormControl(
        this.selectedPageTemplate ? this.selectedPageTemplate.id : undefined
      ),
      templateName: new FormControl(
        this.selectedPageTemplate ? this.selectedPageTemplate.templateName : ''
      ),
      showGreeting: new FormControl(
        this.selectedPageTemplate ? this.selectedPageTemplate.showGreeting ? 'true' : 'false' : 'false'
      ),
      templateHtml: new FormControl(
        this.selectedPageTemplate?.templateJson ? toHTML(JSON.parse(this.selectedPageTemplate.templateJson)) : ''
      ),
      templateJson: new FormControl(
        this.selectedPageTemplate?.templateJson ? this.selectedPageTemplate.templateJson : ''
      ),
    });

    this.pageTemplateForm.controls["templateHtml"].valueChanges.subscribe(value => {
      const json = JSON.stringify( toDoc(value));
      this.pageTemplateForm.controls["templateJson"].setValue(json);
    })

    this.pageTemplateForm.controls['templateName'].setValidators([
      Validators.required,
      this.lookupValidator.duplicateNameValidator(
        this.pageTemplateForm,
        this.existingPageTemplates
      ),
    ]);
  }

  onSubmit() {
    Object.values(this.pageTemplateForm.controls).forEach((control) => {
      control.markAsTouched();
    });

    if (this.pageTemplateForm.valid) {
      if (this.pageMode === PageMode.Add) {
        this.processCreate();
      } else {
        this.processEdit();
      }
    }
  }

  processCreate() {
    this.isLoading = true;
    console.log("this.pageTemplateForm.controls['templateHtml'].value", this.pageTemplateForm.controls['templateHtml'].value)
    this.pagesManagementService
      .createPageTemplate(this.createPageTemplateCommand)
      .subscribe({
        next: () => {
          this.showSuccessMessage();
        },
        error: (error: HttpErrorResponse) => {
          this.serviceErrorHandler.displayHttpErrorDialog(
            this,
            error,
            Constants.ErrorMessages
          );
        },
      })
      .add(() => {
        this.isLoading = false;
      });
  }

  processEdit() {
    if (this.selectedPageTemplate?.id) {
      console.log("this.pageTemplateForm.controls['templateHtml'].value", this.pageTemplateForm.controls['templateHtml'].value)
      this.isLoading = true;
      this.pagesManagementService
        .updatePageTemplate(
          this.selectedPageTemplate.id,
          this.updateCountryCommand
        )
        .subscribe({
          next: () => {
            this.showSuccessMessage();
          },
          error: (error: HttpErrorResponse) => {
            this.serviceErrorHandler.displayHttpErrorDialog(
              this,
              error,
              Constants.ErrorMessages
            );
          },
        })
        .add(() => {
          this.isLoading = false;
        });
    }
  }

  onCancel() {
    this.NavigateBackToView();
  }

  NavigateBackToView() {
    this.router.NavigateTo.HomePageTemplate();
  }

  showSuccessMessage() {
    this.useGuard = false;
    this.NavigateBackToView();

    this.snackBar.open(
      `Home template successfully ${
        this.pageMode === PageMode.Add ? 'created' : 'updated'
      }`
    );
  }

  ngOnDestroy(): void {
    this.editor?.destroy();
  }
}
