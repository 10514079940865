import {Component, OnInit} from '@angular/core';
import {CanComponentDeactivateConfirm} from "../../../../guards/navigate-away-confirm.guard";
import {
  AuditFirmResponse,
  CreateAudiFirmCommand,
  UpdateAudiFirmCommand
} from "../../../master-data-configuration/models/audit-firm";
import {PageMode} from "../../../../enums/separated-enums/page-mode.enum";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {SnackBarService} from "../../../../service/snack-bar/snack-bar.service";
import {
  MasterDataConfigurationValidator
} from "../../../master-data-configuration/validators/master-data-configuration.validator";
import {
  MasterDataConfigurationServiceAbstract
} from "../../../master-data-configuration/services/master-data-configuration.service.abstract";
import {ServiceErrorHandler} from "../../../../service/error-handler/error-handler.service";
import {HttpErrorResponse} from "@angular/common/http";
import {Constants} from "../../../master-data-configuration/models/constants";
import {ServiceManagementServiceAbstract} from "../../services/service-management.service.abstract";
import {CreateServiceTypeCommand, UpdateServiceTypeCommand} from "../../models/service-type";
import {ServiceManagementValidation} from "../../validators/service-management.validation";

@Component({
  selector: 'app-service-type-maintain',
  templateUrl: './service-type-maintain.component.html',
  styleUrls: ['./service-type-maintain.component.scss']
})
export class ServiceTypeMaintainComponent
  implements OnInit, CanComponentDeactivateConfirm
{
  existingServiceTypes?: AuditFirmResponse[];
  selectedServiceType?: AuditFirmResponse;
  pageMode: PageMode = PageMode.Add;
  pageModeText = 'Create new';
  submitButtonText = 'Create';
  isLoading = false;
  serviceTypeForm: FormGroup;
  useGuard = true;

  constructor(
    public router: Router,
    private snackBar: SnackBarService,
    private serviceManagementValidation: ServiceManagementValidation,
    private serviceManagementService: ServiceManagementServiceAbstract,
    private serviceErrorHandler: ServiceErrorHandler,
  ){
    const parameters: any = router.GetNavigationParameter(
      ServiceTypeMaintainComponent
    );

    if (parameters) {
      this.existingServiceTypes = parameters.existingServiceTypes;
      this.selectedServiceType = parameters.selectedServiceType;

      if (this.selectedServiceType) {
        this.pageMode = PageMode.Edit;
        this.pageModeText = 'Edit';
        this.submitButtonText = 'Update';
      } else {
        this.pageMode = PageMode.Add;
        this.pageModeText = 'Create new';
        this.submitButtonText = 'Create';
      }
    }
    else{
      router.NavigateTo.ServiceType();
    }

    this.serviceTypeForm = new FormGroup({});
  }

  ngOnInit(): void {
    this.setupForm();
  }

  confirmNavigateAway(): boolean {
    return (
      this.serviceTypeForm.touched && this.serviceTypeForm.dirty && this.useGuard
    );
  }

  get nameCharacterCount() {
    return this.serviceTypeForm.controls['name'].value.length;
  }

  get createServiceTypeCommand() {
    return {
      name: this.serviceTypeForm.controls['name'].value.trim(),

    } as CreateServiceTypeCommand;
  }

  get updateServiceTypeCommand() {
    return {
      id: this.serviceTypeForm.controls['id'].value,
      name: this.serviceTypeForm.controls['name'].value.trim(),
    } as UpdateServiceTypeCommand;
  }

  setupForm() {
    this.serviceTypeForm = new FormGroup({
      id: new FormControl(
        this.selectedServiceType ? this.selectedServiceType.id : undefined
      ),
      name: new FormControl(
        this.selectedServiceType ? this.selectedServiceType.name : ''
      )
    });

    this.serviceTypeForm.controls['name'].setValidators([
      Validators.required,
      this.serviceManagementValidation.duplicateNameValidator(
        this.serviceTypeForm,
        this.existingServiceTypes
      ),
    ]);
  }

  onSubmit() {
    Object.values(this.serviceTypeForm.controls).forEach((control) => {
      control.markAsTouched();
    });

    if (this.serviceTypeForm.valid) {
      if (this.pageMode === PageMode.Add) {
        this.processCreate();
      } else {
        this.processEdit();
      }
    }
  }

  processCreate() {
    this.isLoading = true;
    this.serviceManagementService
      .createServiceType(this.createServiceTypeCommand)
      .subscribe({
        next: () => {
          this.showSuccessMessage();
        },
        error: (error: HttpErrorResponse) => {
          this.serviceErrorHandler.displayHttpErrorDialog(
            this,
            error,
            Constants.ErrorMessages
          );
        },
      })
      .add(() => {
        this.isLoading = false;
      });
  }

  processEdit() {
    if (this.selectedServiceType?.id) {
      this.isLoading = true;
      this.serviceManagementService
        .updateServiceType(
          this.selectedServiceType.id,
          this.updateServiceTypeCommand
        )
        .subscribe({
          next: () => {
            this.showSuccessMessage();
          },
          error: (error: HttpErrorResponse) => {
            this.serviceErrorHandler.displayHttpErrorDialog(
              this,
              error,
              Constants.ErrorMessages
            );
          },
        })
        .add(() => {
          this.isLoading = false;
        });
    }
  }

  onCancel() {
    this.NavigateBackToView();
  }

  NavigateBackToView() {
    this.router.NavigateTo.ServiceType();
  }

  showSuccessMessage() {
    this.useGuard = false;
    this.NavigateBackToView();

    this.snackBar.open(
      `Service Type successfully ${
        this.pageMode === PageMode.Add ? 'created' : 'updated'
      }`
    );
  }
}
