import {Component, EventEmitter, Input, OnDestroy, Output, ViewChild} from '@angular/core';
import {Employee} from "../../model/employee/employee.model";
import {MatAutocomplete} from "@angular/material/autocomplete";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {BehaviorSubject, debounceTime, distinctUntilChanged, Subscription} from "rxjs";
import {AsyncPipe} from "@angular/common";
import {ServiceGraph} from "../../service/graph/graph.service";
import {filter} from "rxjs/operators";

@Component({
  selector: 'app-search-graph-dropdown',
  templateUrl: './search-graph-dropdown.component.html',
  styleUrls: ['./search-graph-dropdown.component.scss']
})
export class SearchGraphDropdownComponent
  implements OnDestroy
{
  @Input() existingUsers?: any[] = [];
  @Output() employeeSelected: EventEmitter<Employee> = new EventEmitter();
  @Output() fieldReset: EventEmitter<boolean> = new EventEmitter();

  @ViewChild(MatAutocomplete) autoComplete!: MatAutocomplete;

  frmSearch: FormGroup<{
    searchInput: FormControl<string>;
  }>;

  showSpinner = false;
  searchString = '';
  subscriptions: Subscription[] = [];

  filteredOptions: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  selectedEmployees: Employee[] = [];

  constructor(private serviceGraph: ServiceGraph, private fb: FormBuilder) {
    this.frmSearch = this.fb.group({
      searchInput: this.fb.nonNullable.control('', Validators.required),
    });
    this.subscribeToSearch();
  }

  subscribeToSearch() {
    const subSearch = this.frmSearch.controls.searchInput.valueChanges
      .pipe(
        debounceTime(400),
        distinctUntilChanged(),
        filter((query: string) => query?.length > 2)
      )
      .subscribe((value) => {
        this.searchString = value;
        this.loadUsers(value);
      });
    this.subscriptions.push(subSearch);
  }

  loadUsers(searchValue: string) {
    this.showSpinner = true;
    this.serviceGraph
      .searchAllUsers(undefined, searchValue, true)
      .then((result: any[]) => {
        result = result.filter((employee: Employee) => {
          return !this.selectedEmployees.some((e) => e.upn === employee.upn);
        });
        this.filteredOptions.next(result);
        this.showSpinner = false;
      });
  }

  getAutoCompleteDisplayName(employee: Employee) {
    return !employee ? '' : employee.name;
  }

  getInitial(name: string): string {
    if (!name || name.length === 0) {
      return '';
    }

    const namePart = name.split(',')[1];
    const firstLetterName = namePart ? namePart.trim()[0].toUpperCase() : '';
    const firstLetterSurname = name.charAt(0).toUpperCase();
    return firstLetterName + firstLetterSurname;
  }

  selectAutoComplete(employee: Employee) {
    if (
      this.existingUsers?.some(
        (ee) => ee?.emailAddress?.toUpperCase() === employee.email.toUpperCase()
      )
    ) {
      this.frmSearch.controls.searchInput.setErrors({ duplicate: true });
    } else {
      this.frmSearch.controls.searchInput.setErrors(null);
      this.employeeSelected.emit(employee);
    }
  }

  onChange() {
    this.fieldReset.emit(true);
  }

  markSearchInputAsTouched() {
    this.frmSearch.controls.searchInput.markAsTouched();
  }

  checkSelected() {
    if (
      !this.frmSearch.controls.searchInput.hasError('required') &&
      !this.frmSearch.controls.searchInput.hasError('duplicate')
    ) {
      const selectedOption = this.autoComplete.options.find(
        (option) => option.value === this.frmSearch.controls.searchInput.value
      );
      if (!selectedOption) {
        this.frmSearch.controls.searchInput.setErrors({ notSelected: true });
      } else {
        this.frmSearch.controls.searchInput.setErrors(null);
      }
    }
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }
}
