<div class="flex flex-col gap-[32px] items-stretch content-stretch p-3">
  <div class="flex flex-col gap-[8px]">
    <div class="mat-display-4">{{ pageModeText }} User Guide</div>
    <div class="mat-subheading-1">
      Please capture all required information below.
    </div>
  </div>
  <mat-divider></mat-divider>

  <div>
    <div class="card-content">
      <div class="card-body ">
        <h6>Document Name</h6>
        <span class="alert">{{ errorMessage }}</span>
        <span class="success">{{ successMessage }}</span>
        <input #fileInput type="file" class="file-input" [accept]="acceptedFileExtensions" (change)="onSubmit($event)" />
        <div class="row document-type">
          <div class="col-xs-12">
            <button mat-mini-fab color="primary" class="upload-btn" action="file" (click)="onSelectFile()" type="submit">
              <mat-icon>attach_file</mat-icon>
            </button>
            Only pdf file types allowed
          </div>
        </div>
      </div>
    </div>
  </div>

  <mat-divider></mat-divider>
  <div fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="16px">
    <button
      mat-stroked-button
      color="primary"
      class="mat-stroked-button"
      (click)="onCancel()"
      [disabled]="isLoading"
    >
      Cancel
    </button>

  </div>
</div>
