<div class="flex flex-col gap-[32px] items-stretch content-stretch p-3">
  <div fxLayout="column" fxLayoutGap="8px">
    <div class="mat-display-4">{{ pageModeText }} System User</div>
    <div class="mat-subheading-1">
      Please capture all required information below.
    </div>
  </div>
  <mat-divider></mat-divider>

  <form [formGroup]="systemUserForm" fxLayout="column" fxLayoutGap="2px">
    <mat-form-field fxFlex appearance="fill" *ngIf="pageMode === pageModes.Add">
      <mat-label>Absa Employee</mat-label>
      <mat-select formControlName="isAbsaEmployee">
        <ng-container>
          <mat-option value="{{true}}">Yes</mat-option>
          <mat-option value="{{false}}">No</mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>

    <mat-form-field fxFlex appearance="fill" *ngIf="pageMode === pageModes.Edit">
      <mat-label>Absa Employee</mat-label>
      <input
        matInput
        maxlength="100"
        formControlName="isAbsaEmployeeName"
        autocomplete="off"
      />
    </mat-form-field>

    <mat-form-field fxFlex appearance="fill" *ngIf="pageMode === pageModes.Edit">
      <mat-label>User Name</mat-label>
      <input
        matInput
        maxlength="100"
        formControlName="userName"
        autocomplete="off"
      />
    </mat-form-field>

    <mat-form-field fxFlex appearance="fill" *ngIf="(!isAbsaUser && isAbsaUserSelected) || pageMode === pageModes.Edit">
      <mat-label>First Name</mat-label>
      <input
        matInput
        maxlength="100"
        formControlName="firstName"
        autocomplete="off"
      />
    </mat-form-field>

    <mat-form-field fxFlex appearance="fill" *ngIf="(!isAbsaUser && isAbsaUserSelected) || pageMode === pageModes.Edit">
      <mat-label>Last Name</mat-label>
      <input
        matInput
        maxlength="100"
        formControlName="lastName"
        autocomplete="off"
      />
    </mat-form-field>

    <mat-form-field fxFlex appearance="fill" *ngIf="isAbsaUserSelected">
      <mat-label>Email</mat-label>
      <input
        matInput
        maxlength="100"
        formControlName="email"
        autocomplete="off"
      />
      <mat-error
        *ngIf="systemUserForm.controls['email'].hasError('duplicate')"
      >
        The captured user email address already exists
      </mat-error>
      <mat-error
        *ngIf="systemUserForm.controls['email'].hasError('IsNotInternalEmailAddress')"
      >
        The captured user email address is not an internal email address
      </mat-error>
      <mat-error
        *ngIf="systemUserForm.controls['email'].hasError('IsNotExternalEmailAddress')"
      >
        The captured user email address is not an external email address
      </mat-error>
      <mat-error *ngIf="systemUserForm.controls['email'].hasError('required')">
        Please capture user email address in the indicated field
      </mat-error>
      <mat-hint align="end">{{ lastNameCharacterCount }} / 100</mat-hint>
    </mat-form-field>

    <mat-form-field fxFlex appearance="fill" *ngIf="pageMode === pageModes.Edit">
      <mat-label>Phone Number</mat-label>
      <input
        matInput
        maxlength="100"
        formControlName="phoneNumber"
        autocomplete="off"
      />
    </mat-form-field>

    <div fxFlex fxLayout="column" *ngIf="pageMode === pageModes.Edit">
      <mat-checkbox fxFlex appearance="fill" formControlName="emailConfirmed">Email Confirmed</mat-checkbox>
    </div>

    <div fxFlex fxLayout="column" *ngIf="pageMode === pageModes.Edit">
      <mat-checkbox fxFlex appearance="fill" formControlName="phoneNumberConfirmed">Phone Number Confirmed
      </mat-checkbox>
    </div>

    <br>

    <mat-form-field fxFlex appearance="fill" *ngIf="(isAbsaUserSelected || pageMode === pageModes.Edit)">
      <mat-label>Roles</mat-label>
      <mat-select multiple placeholder="Select Roles" formControlName="roles" (selectionChange)="onRolesChange($event)" >
        <ng-container>
          <mat-option *ngFor="let item of roles" [value]="item.id" >{{item.name}}</mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>

    <mat-form-field fxFlex appearance="fill" *ngIf="(((!isAbsaUser && isAbsaUserSelected ) || (!isAbsaUser && pageMode === pageModes.Edit)) && isAuditFirmSelected)">
      <mat-label>Organization</mat-label>
      <mat-select placeholder="Select Roles" formControlName="organizationId">
        <ng-container>
          <mat-option *ngFor="let item of auditFirms" [value]="item.id">{{item.name}}</mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>

  </form>

  <div fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="16px">
    <button
      mat-stroked-button
      color="primary"
      class="mat-stroked-button"
      (click)="onCancel()"
      [disabled]="isLoading"
    >
      Cancel
    </button>
    <button
      mat-flat-button
      color="primary"
      class="mat-flat-button"
      (click)="onSubmit()"
      [disabled]="isLoading"
    >
      {{ submitButtonText }}
    </button>
  </div>
</div>
