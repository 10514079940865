import {Injectable} from "@angular/core";
import {ServiceConfig} from "../config/config.service";
import {ErrorSeverityLevel} from "../../enums/separated-enums/error-severity-level.enum";

@Injectable({
  providedIn: 'root',
})
export class ServiceMonitoring {
  private _userId: string | undefined;
  get userId(): string | undefined {
    return this._userId;
  }

  //TODO
  set userId(value: string | undefined) {

  }

  loggingLevel = ErrorSeverityLevel.Info;
  logTo: string[] = [];
  //TODO
  // appInsights!: ApplicationInsights;
  constructor(private serviceConfig: ServiceConfig) {}

  logEvent(
    origin: any,
    descriptionOfEvent: string,
    properties?: { [key: string]: any }
  ): void {

  }

  logException(
    origin: any,
    exception: Error | string,
    properties: { [key: string]: any } = {},
    severityLevel:
      | ErrorSeverityLevel.Error
      | ErrorSeverityLevel.Critical = ErrorSeverityLevel.Error
  ): void {

  }
}
