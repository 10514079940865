import {Component, Input, OnInit} from '@angular/core';
import {
  SegmentManagementServiceAbstract
} from "../../../../segment-management/services/segment-management.service.abstract";
import {ServiceErrorHandler} from "../../../../../service/error-handler/error-handler.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {HttpErrorResponse} from "@angular/common/http";
import {
    Constants,
    RequestMaintainSegmentData,
    RequestResponse,
    SegmentResponse,
    SubSegmentResponse
} from "../../../models";

@Component({
  selector: 'app-request-maintain-deliverable-segment',
  templateUrl: './request-maintain-deliverable-segment.component.html',
  styleUrls: ['./request-maintain-deliverable-segment.component.scss']
})
export class RequestMaintainDeliverableSegmentComponent
  implements OnInit {

  @Input()
  selectedRequest?: RequestResponse;

  @Input()
  isBusinessFinancialControllerVisible: boolean = false;

  @Input()
  isGroupFinancialControllerVisible: boolean = false;

  isLoading = false;
  segmentForm: FormGroup;

  segments: SegmentResponse[] = [];
  subSegments: SubSegmentResponse[] = [];

  constructor(
      private segmentManagementService: SegmentManagementServiceAbstract,
      private serviceErrorHandler: ServiceErrorHandler
  ) {
    this.segmentForm = new FormGroup({});
  }

  ngOnInit(): void {
      this.setupForm();
      this.getSegments();
  }

  protected setupForm(): void{
    this.segmentForm = new FormGroup({
        businessAreaId: new FormControl(this.selectedRequest ? this.selectedRequest.businessArea?.id : null, Validators.required),
        subsegmentId: new FormControl(this.selectedRequest ? this.selectedRequest.subsegment?.id : null, Validators.required),
        accountExecutiveUserId: new FormControl(this.selectedRequest ? this.selectedRequest.accountExecutiveUser?.id : null, Validators.required),
        accountExecutiveUserName: new FormControl(this.selectedRequest ? this.selectedRequest.accountExecutiveUser?.fullName : '', Validators.required),
        businessFinancialControllerUserId: new FormControl(this.selectedRequest ?
            this.selectedRequest.businessFinancialControllerUser?.id : null, Validators.required),
        businessFinancialControllerUserName: new FormControl(this.selectedRequest ? this.selectedRequest.businessFinancialControllerUser?.fullName: '', Validators.required),
        groupFinancialControllerUserId: new FormControl(this.selectedRequest ? this.selectedRequest.groupFinancialControllerUser?.id : null, Validators.required),
        groupFinancialControllerUserName: new FormControl(this.selectedRequest ? this.selectedRequest.groupFinancialControllerUser?.fullName : '', Validators.required),
        financialDirectorUserId: new FormControl(this.selectedRequest ? this.selectedRequest.financialDirectorUser?.id : null, Validators.required),
        financialDirectorUserName: new FormControl(this.selectedRequest ? this.selectedRequest.financialDirectorUser?.fullName : '', Validators.required),
        costCenter: new FormControl(this.selectedRequest ? this.selectedRequest.costCenter : '')

    });

      this.segmentForm.controls['businessAreaId'].valueChanges.subscribe((value) => {
          this.getSubSegments(Number(value));
      });

      this.segmentForm.controls['subsegmentId'].valueChanges.subscribe((value) => {
          this.GetAccountExecutive(Number(value));
      });

      if(this.selectedRequest?.businessArea?.id){
        this.getSubSegments(Number(this.selectedRequest?.businessArea.id));
      }
  }

  clearForm() : void {
    this.segmentForm = new FormGroup({
      businessAreaId: new FormControl(undefined, Validators.required),
      subsegmentId: new FormControl(undefined, Validators.required),
      accountExecutiveUserId: new FormControl(undefined, Validators.required),
      accountExecutiveUserName: new FormControl('', Validators.required),
      businessFinancialControllerUserId: new FormControl(undefined, Validators.required),
      businessFinancialControllerUserName: new FormControl('', Validators.required),
      groupFinancialControllerUserId: new FormControl(undefined, Validators.required),
      groupFinancialControllerUserName: new FormControl('', Validators.required),
      financialDirectorUserId: new FormControl( undefined, Validators.required),
      financialDirectorUserName: new FormControl('', Validators.required),
      costCenter: new FormControl('', Validators.required)
    });

    this.segmentForm.controls['businessAreaId'].valueChanges.subscribe((value) => {
      this.getSubSegments(Number(value));
    });

    this.segmentForm.controls['subsegmentId'].valueChanges.subscribe((value) => {
      this.GetAccountExecutive(Number(value));
    });
  }

  protected getSegments(){
    this.isLoading = true;
    this.segmentManagementService
        .getAllSegments()
        .subscribe({
            next: (result: SegmentResponse[]) => {
                this.segments = result?.filter(x => x?.isActive);
            },
            error: (error: HttpErrorResponse) => {
                this.serviceErrorHandler.displayHttpErrorDialog(
                    this,
                    error,
                    Constants.ErrorMessages
                );
            },
        })
        .add(() => {
            this.isLoading = false;
        });
   }

  private getSubSegments(id: number){
      this.isLoading = true;
      this.segmentManagementService
        .getAllSubSegments()
        .subscribe({
            next: (result: SubSegmentResponse[]) => {
                this.subSegments = result?.filter((x) => x?.segment?.id === id && x?.isActive);
            },
            error: (error: HttpErrorResponse) => {
                this.serviceErrorHandler.displayHttpErrorDialog(
                    this,
                    error,
                    Constants.ErrorMessages
                );
            },
        })
        .add(() => {
            this.isLoading = false;
        });
  }

  private GetAccountExecutive(id: number){
      const result = this.subSegments.find((x) => x.id === id);

      this.segmentForm.controls['costCenter'].patchValue(result?.costCenter);
      this.segmentForm.controls['accountExecutiveUserId'].patchValue(result?.accountExecutiveUser.id);
      this.segmentForm.controls['accountExecutiveUserName'].patchValue(result?.accountExecutiveUser.fullName);

      this.segmentForm.controls['businessFinancialControllerUserId'].patchValue(result?.businessFinancialControllerUser.id);
      this.segmentForm.controls['businessFinancialControllerUserName'].patchValue(result?.businessFinancialControllerUser.fullName);

      this.segmentForm.controls['groupFinancialControllerUserId'].patchValue(result?.groupFinancialControllerUser.id);
      this.segmentForm.controls['groupFinancialControllerUserName'].patchValue(result?.groupFinancialControllerUser.fullName);

      this.segmentForm.controls['financialDirectorUserId'].patchValue(result?.financialDirectorUser.id);
      this.segmentForm.controls['financialDirectorUserName'].patchValue(result?.financialDirectorUser.fullName);
  }

  public fetchData(): RequestMaintainSegmentData {
      return {
          businessAreaId: this.segmentForm.controls['businessAreaId'].value
            ? Number(this.segmentForm.controls['businessAreaId'].value) : null,
          subsegmentId: this.segmentForm.controls['subsegmentId'].value ?
            Number(this.segmentForm.controls['subsegmentId'].value) : null,
          costCenter: this.segmentForm.controls['costCenter'].value,
          accountExecutiveUserId: this.segmentForm.controls['accountExecutiveUserId'].value ?
            Number(this.segmentForm.controls['accountExecutiveUserId'].value) : null,
          businessFinancialControllerUserId: this.segmentForm.controls['businessFinancialControllerUserId'].value ?
            Number(this.segmentForm.controls['businessFinancialControllerUserId'].value) : null,
          groupFinancialControllerUserId: this.segmentForm.controls['groupFinancialControllerUserId'].value ?
            Number(this.segmentForm.controls['groupFinancialControllerUserId'].value) : null,
          financialDirectorUserId: this.segmentForm.controls['financialDirectorUserId'].value ?
            Number(this.segmentForm.controls['financialDirectorUserId'].value) : null,
      } as RequestMaintainSegmentData;
  }

}
