import {AbstractEndpoints} from "./endpoints.abstract";
import {ServiceConfig} from "../../app/service/config/config.service";
import {AppSettings} from "../app-settings/app-settings";
import {Environments} from "../../app/enums/separated-enums/environments.enum";
import {EndpointsMock} from "./endpoints.mock";
import {Endpoints} from "./endpoints";

export function FactoryEndpoints(serviceConfig: ServiceConfig): AbstractEndpoints {
  switch (AppSettings.environment) {
    case Environments.MockData:
      return new EndpointsMock(serviceConfig);
    default:
      return new Endpoints(serviceConfig);
  }
}
