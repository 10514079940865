import {Component, OnInit} from '@angular/core';
import {CanComponentDeactivateConfirm} from "../../../../guards/navigate-away-confirm.guard";
import {PageMode} from "../../../../enums/separated-enums/page-mode.enum";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {CreateReasonCommand, ReasonResponse, UpdateReasonCommand} from "../../models/reason";
import {Router} from "@angular/router";
import {SnackBarService} from "../../../../service/snack-bar/snack-bar.service";
import {MasterDataConfigurationValidator} from "../../validators/master-data-configuration.validator";
import {MasterDataConfigurationServiceAbstract} from "../../services/master-data-configuration.service.abstract";
import {ServiceErrorHandler} from "../../../../service/error-handler/error-handler.service";
import {HttpErrorResponse} from "@angular/common/http";
import {Constants} from "../../models/constants";

@Component({
  selector: 'app-reason-maintain',
  templateUrl: './reason-maintain.component.html',
  styleUrls: ['./reason-maintain.component.scss']
})
export class ReasonMaintainComponent implements OnInit, CanComponentDeactivateConfirm {
  existingReasons?: ReasonResponse[];
  selectedReason?: ReasonResponse;
  pageMode: PageMode = PageMode.Add;
  pageModeText = 'Create new';
  submitButtonText = 'Create';
  isLoading = false;
  reasonForm: FormGroup;
  useGuard = true;

  constructor(
    public router: Router,
    private snackBar: SnackBarService,
    private lookupValidator: MasterDataConfigurationValidator,
    private masterDataConfigurationService: MasterDataConfigurationServiceAbstract,
    private serviceErrorHandler: ServiceErrorHandler,
  ){
    const parameters: any = router.GetNavigationParameter(
      ReasonMaintainComponent
    );

    if (parameters) {
      this.existingReasons = parameters.existingReasons;
      this.selectedReason = parameters.selectedReason;

      if (this.selectedReason) {
        this.pageMode = PageMode.Edit;
        this.pageModeText = 'Edit';
        this.submitButtonText = 'Update';
      } else {
        this.pageMode = PageMode.Add;
        this.pageModeText = 'Create new';
        this.submitButtonText = 'Create';
      }
    }
    else{
      router.NavigateTo.Reason();
    }

    this.reasonForm = new FormGroup({});
  }

  ngOnInit(): void {
    this.setupForm();
  }

  confirmNavigateAway(): boolean {
    return (
      this.reasonForm.touched && this.reasonForm.dirty && this.useGuard
    );
  }

  get nameCharacterCount() {
    return this.reasonForm.controls['name'].value.length;
  }

  get createReasonCommand() {
    return {
      name: this.reasonForm.controls['name'].value.trim(),

    } as CreateReasonCommand;
  }

  get updateReasonCommand() {
    return {
      id: this.reasonForm.controls['id'].value,
      name: this.reasonForm.controls['name'].value.trim(),
    } as UpdateReasonCommand;
  }

  setupForm() {
    this.reasonForm = new FormGroup({
      id: new FormControl(
        this.selectedReason ? this.selectedReason.id : undefined
      ),
      name: new FormControl(
        this.selectedReason ? this.selectedReason.name : ''
      )
    });

    this.reasonForm.controls['name'].setValidators([
      Validators.required,
      this.lookupValidator.duplicateNameValidator(
        this.reasonForm,
        this.existingReasons
      ),
    ]);
  }

  onSubmit() {
    Object.values(this.reasonForm.controls).forEach((control) => {
      control.markAsTouched();
    });

    if (this.reasonForm.valid) {
      if (this.pageMode === PageMode.Add) {
        this.processCreate();
      } else {
        this.processEdit();
      }
    }
  }

  processCreate() {
    this.isLoading = true;
    this.masterDataConfigurationService
      .createReason(this.createReasonCommand)
      .subscribe({
        next: () => {
          this.showSuccessMessage();
        },
        error: (error: HttpErrorResponse) => {
          this.serviceErrorHandler.displayHttpErrorDialog(
            this,
            error,
            Constants.ErrorMessages
          );
        },
      })
      .add(() => {
        this.isLoading = false;
      });
  }

  processEdit() {
    if (this.selectedReason?.id) {
      this.isLoading = true;
      this.masterDataConfigurationService
        .updateReason(
          this.selectedReason.id,
          this.updateReasonCommand
        )
        .subscribe({
          next: () => {
            this.showSuccessMessage();
          },
          error: (error: HttpErrorResponse) => {
            this.serviceErrorHandler.displayHttpErrorDialog(
              this,
              error,
              Constants.ErrorMessages
            );
          },
        })
        .add(() => {
          this.isLoading = false;
        });
    }
  }

  onCancel() {
    this.NavigateBackToView();
  }

  NavigateBackToView() {
    this.router.NavigateTo.Reason();
  }

  showSuccessMessage() {
    this.useGuard = false;
    this.NavigateBackToView();

    this.snackBar.open(
      `Reason successfully ${
        this.pageMode === PageMode.Add ? 'created' : 'updated'
      }`
    );
  }
}
