<form [formGroup]="basicForm" fxLayout="column" fxLayoutGap="2px">
  <mat-form-field fxFlex appearance="fill">
    <mat-label>{{ "Is this a regulatory requirement request?" }}</mat-label>
    <mat-select formControlName="regulatory">
      <ng-container>
        <mat-option value={{true}}>Yes</mat-option>
        <mat-option value={{false}}>No</mat-option>
      </ng-container>
    </mat-select>
    <mat-error *ngIf="basicForm.controls['regulatory'].hasError('required')">
      The Regulatory field is required.
    </mat-error>
  </mat-form-field>

  <mat-form-field fxFlex appearance="fill">
    <mat-label >{{ "Beneficiary Name" }}</mat-label>
    <input matInput maxlength="100" formControlName="beneficiaryName"
      autocomplete="off"
    />
    <mat-error *ngIf="basicForm.controls['beneficiaryName'].hasError('required')">
      The Beneficiary field is required.
    </mat-error>
  </mat-form-field>

  <mat-form-field fxFlex appearance="fill" *ngIf="isLegislationAndLegislationVisible">
    <mat-label>{{ "Legislation" }}</mat-label>
    <input matInput formControlName="legislation">
    <mat-error *ngIf="basicForm.controls['legislation'].hasError('required')">
      Legislation field is required.
    </mat-error>
  </mat-form-field>

  <mat-form-field fxFlex appearance="fill" *ngIf="isLegislationAndLegislationVisible">
    <mat-label>{{ "Section / Regulation" }}</mat-label>
    <input matInput formControlName="sectionOrRegulation">
    <mat-error *ngIf="basicForm.controls['sectionOrRegulation'].hasError('required')">
      Section or Regulation field is required.
    </mat-error>
  </mat-form-field>

  <mat-form-field fxFlex appearance="fill" *ngIf="isScopeOfWorkVisible">
    <mat-label>{{ "Scope Of Work" }}</mat-label>
    <input matInput  formControlName="scopeOfWork"/>
    <mat-error *ngIf="basicForm.controls['scopeOfWork'].hasError('required')">
      Scope of Work field is required.
    </mat-error>
  </mat-form-field>

  <mat-form-field fxFlex appearance="fill">
    <mat-label >{{ "Request Title" }}</mat-label>
    <input matInput formControlName="title" readonly="yes" />
    <mat-error *ngIf="basicForm.controls['title'].hasError('required')">
      Scope of Work field is required.
    </mat-error>
  </mat-form-field>

</form>
