import {MatDialog} from "@angular/material/dialog";
import {ServiceMonitoring} from "../monitor/monitor.service";
import {Injectable} from "@angular/core";
import {ErrorDialogComponent} from "../../components/error-dialog/error-dialog.component";
import {ModelErrorDialogConfig} from "../../model/dialog-data/error-dialog-config.model";
import * as Models from '../../model/models';
import {HttpErrorResponse} from "@angular/common/http";
import {MaterialIcons} from "../../enums/separated-enums/material-icons.enum";

@Injectable({
  providedIn: 'root',
})
export class ServiceErrorHandler {
  constructor(
    private serviceMonitoring: ServiceMonitoring,
    private dialog: MatDialog
  ) {
  }

  /**
   * Display a dialog box with a user friendly error message when the API returns a HttpErrorResponse.
   *
   * @param origin - The class calling the method.  Pass in 'this' if you're unsure about what to use otherwise just a string as the name
   * @param error - Pass your 'HttpErrorResponse' object in this parameter.
   * @param constants - Pass the constants abstract class containing your errors object in parameter.
   * @param header - A custom header if required. Defaults to 'Error'.
   * @param topic - A custom topic if required. Defaults to nothing.
   * @param icon - The icon to display on the dialog box. Defaults to 'Error'.  See 'Enums.MaterialIcons' for other options.

   */
  displayHttpErrorDialog(
    origin: any,
    error: HttpErrorResponse,
    errorMessageConstants: any,
    header = 'Error',
    topic?: string,
    icon = MaterialIcons.Error
  ): void {
    const message = this.getErrorMessage('', error, errorMessageConstants);
    this.logError(message, origin, error);
    this.showDialog(message, header, icon, topic);
  }

  /**
   * Display a dialog box with a user friendly error message.
   *
   * @param origin - The class calling the method.  Pass in 'this' if you're unsure about what to use otherwise just a string as the name
   * @param errorMessage - A user friendly error message
   * @param header - A custom header if required. Defaults to 'Error'.
   * @param icon - The icon to display on the dialog box. Defaults to 'Error'.  See 'Enums.MaterialIcons' for other options.
   * @param error - If you have access to a 'Error' object supply this parameter.
   */
  displayErrorDialog(
    origin: any,
    errorMessage: string,
    header = 'Error',
    icon = MaterialIcons.Error,
    error?: Error
  ): void {
    const message = this.getErrorMessage(errorMessage, error);
    this.logError(message, origin, error);
    this.showDialog(message, header, icon);
  }

  getErrorMessage(
    errorMessage: string,
    error?: Error | HttpErrorResponse,
    errorMessageConstants?: any
  ): string {
    // if (error instanceof HttpErrorResponse) {
    //   if (error.error && error.error?.Errors && errorMessageConstants) {
    //     if (error.error?.Errors[0].code === 'Reference.EntityBeingReferenced') {
    //       return (
    //         error.error?.Errors[0]?.description?.replace(
    //           /(?<![ ])([A-Z])/g,
    //           ' $1'
    //         ) ?? this.genericError
    //       );
    //     } else {
    //       const constantError =
    //         errorMessageConstants[error.error?.Errors[0].code];
    //       return constantError ?? this.genericError;
    //     }
    //   } else {
    //     return this.genericError;
    //   }
    // } else {
    //   return !errorMessage && errorMessage.trim() != ''
    //     ? errorMessage
    //     : this.genericError;
    // }

    return error?.message ?? this.genericError;
  }

  logError(errorMessage: string, origin: any, error?: Error): void {
    const errorToLog = error ?? errorMessage;
    this.serviceMonitoring.logException(origin, errorToLog);
  }

  showDialog(
    errorMessage: string,
    header: string,
    icon: any,
    topic?: string
  ): void {
    const config: ModelErrorDialogConfig =
      Models.Initialize.ErrorDialog.ErrorDialogConfig(
        errorMessage,
        header,
        topic,
        icon
      );
    this.dialog
      .open(ErrorDialogComponent, { data: config })
      .afterClosed()
      .subscribe((reportIssue: string) => {
        if (reportIssue === 'true') {
          this.serviceMonitoring.logException(
            origin,
            'Additional info on error logged'
          );
        }
      });
  }

  get genericError(): string {
    return (
      'We ran into a problem. ' +
      'If the error persists, please contact your system administrator'
    );
  }
}
