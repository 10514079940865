<div class="flex font-sans z-[500]">
    <div class="sl:sideBarMobile sm:sideBarMobile md:sideBarLarge xmd:sideBarLarge lg:sideBarLarge x2l:sideBarLarge"
       [ngClass]="sideNavCurrentClasses">

    <div class="flex flex-row px-2 justify-between items-center">
       <div class="flex justify-start items-center gap-[10px]">
         <img class="text-2xl duration-500"
              [ngClass]="sideNavLogoCurrentClasses"
              src="./assets/img/absa-logo-roundred.png"
              width="35"
              height="35"
              alt="logo"/>
         <span class="text-white text-2xl duration-500 hover:scale-100" [ngClass]="sideNavTextCurrentClasses">NAS</span>
       </div>
       <div class="flex justify-center items-center">
         <mat-slide-toggle class="sl:hidden sm:hidden md:inline-block xmd:inline-block lg:inline-block x2l:inline-block" [ngClass]="sideNavTextCurrentClasses" [(ngModel)]="openSideNav"></mat-slide-toggle>
         <a class="sl:visible sm:visible md:hidden xmd:hidden lg:hidden x2l:hidden text-white" (click)="toggleSideNav(false)">
           <ng-icon name="featherX"></ng-icon>
         </a>
       </div>
    </div>

    <nav class="w-full p-0 m-0 overflow-y-scroll custom-scrollbar">
      <ul class="pt-2 w-full">
        <li *ngFor="let navMenu of navigationMenus" class="w-full mt-2 relative">
         <a class=" relative flex items-center gap-x-3 w-full h-full hover:bg-nav uiBefore text-white cursor-pointer leading-[2rem] duration-500 transition-all"
            [routerLinkActive]="'active'"
            [routerLink]="navMenu.link"
            *ngIf="hideByRole(navMenu.hideByRole) && !navMenu.hasSubMenu"
            (click)="toggleMobileSideNav(false)"
            [ngClass]="navTextCurrentClasses">
            <span class="text-[1rem] block float-left" [ngClass]="navTextMoveCurrentClasses">
            <ng-icon name="{{navMenu.icon}}"></ng-icon>
            </span>
           <span class="m-0 text-sm opacity-100 font-normal h-fit flex-1">{{navMenu.title}}</span>
           <span *ngIf="navMenu.subMenuIcon && navMenu.hasSubMenu" class="text-sm float-right">
             <ng-icon name="{{navMenu.subMenuIcon}}"></ng-icon>
           </span>
         </a>
          <a class=" relative flex items-center gap-x-3 w-full h-full hover:bg-nav uiBefore text-white cursor-pointer leading-[2rem] duration-500 transition-all duration-500 transition-all"
             (click)="isSubMenuOpened = !isSubMenuOpened"
             *ngIf="hideByRole(navMenu.hideByRole) && navMenu.hasSubMenu"
             [ngClass]="navTextCurrentClasses">
            <span class="text-[1rem] block float-left" [ngClass]="navTextMoveCurrentClasses">
            <ng-icon name="{{navMenu.icon}}"></ng-icon>
            </span>
            <span class="m-0 text-sm opacity-100 font-normal h-fit flex-1">{{navMenu.title}}</span>
            <span *ngIf="navMenu.subMenuIcon && navMenu.hasSubMenu" [ngClass]="subMenuIconCurrentClasses" class="text-sm m-0 p-0 duration-500">
             <ng-icon name="{{navMenu.subMenuIcon}}"></ng-icon>
           </span>
          </a>
          <ul *ngIf="navMenu.hasSubMenu && isSubMenuOpened" class="w-full duration-300 transition-all">
            <li *ngFor="let subMenu of navMenu.subMenuItems" class="w-full mt-2 duration-300 delay-500 transition-all">
              <a class="py-[8px] pl-[60px] pr-[18px]  relative flex items-center gap-x-3 w-full h-full hover:bg-nav uiBefore text-white cursor-pointer leading-[2rem] my-[5px]"
                 [routerLink]="subMenu.link"
                 [routerLinkActive]="'active'"
                 (click)="toggleMobileSideNav(false)"
                 *ngIf="hideByRole(subMenu.hideByRole)">
                 <span class="text-[1rem] block float-left">
                  <ng-icon name="{{subMenu.icon}}"></ng-icon>
                 </span>
                <span class="m-0 text-sm opacity-100 font-normal h-fit flex-1">{{subMenu.title}}</span>
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </nav>

  </div>

  <div class="flex-col duration-500 w-full"
      [ngClass]="mainContentCurrentClasses">
    <nav class="flex gap-5 justify-between items-center w-full pt-1">
      <div class="flex gap-[8px] justify-center items-center md:visible xmd:hidden lg:hidden">
        <button class="" mat-icon-button  aria-label="Toggle sidenav" (click)="(toggleSideNav(true)) ">
          <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
        </button>
      </div>
      <div class="flex w-[20px] h-full">

      </div>
      <div class="flex gap-[8px] justify-center items-center justify-self-end" *ngIf="IsLoggedIn">
        <div class="flex justify-content-center items-center bg-white justify-center border rounded-full min-w-[40px] h-[40px] font-medium">
          {{ initials }}
        </div>
        <div fxLayoutAlign="center center">
          {{ userName }}
        </div>
        <button
          type="button"
          aria-label="Toggle sidenav"
          [matMenuTriggerFor]="menu"
          mat-icon-button
        >
          <mat-icon aria-label="Side nav toggle icon">more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu" class="mat-body-2">
          <button mat-menu-item class="right-p-7 left-p-7">
            <mat-icon>logout</mat-icon>
            <div (click)="logout()">Log out</div>
          </button>
        </mat-menu>
      </div>
    </nav>
    <router-outlet *ngIf="!isIframe"></router-outlet>
  </div>
</div>

