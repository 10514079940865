export abstract class AbstractEndpoints {
  dashBoard!: IDashBoardEndpoints;
  userManagement!: IUserManagementEndpoints;
  page!: IPageEndpoints;
  masterDataConfiguration!: IMasterDataConfigurationEndpoints;
  requestManagement!: IRequestManagementEndpoints;
  requestDetailsApprovalEmailManagement!: IRequestDetailsApprovalEmailManagement;
  segmentManagement!: ISegmentManagementEndpoints;
  serviceManagement!: IServiceManagementEndpoints;
  countryManagement!: ICountryManagementEndpoints;
  pagesManagement! : IPagesManagementEndpoints
}

export interface  IDashBoardEndpoints{
  getDashboardDetails: string;
}

export interface IUserManagementEndpoints{
  //User
  getAllSystemUsers: string;
  getSystemUserById(userId: number): string;
  getSystemUserByEmailAddress(email: string): string;
  createSystemUser: string;
  updateSystemUser(userId: number): string;
  deleteSystemUser(userId: number): string;
  hideUser(userId: number): string;
  showUser(userId: number): string;

  //Role
  getAllRoles: string;
  getRoleById(roleId: number): string;
  createRole: string;
  updateRole(roleId: number): string;
  deleteRole(roleId: number): string;
}

export interface IPageEndpoints{
  //Policy
  createPolicyDocument: string;
  downloadPolicyDocument(policyDocumentName: string): string;

  //User Guide
  getAllUserGuides: string;
  GetUserGuideById(userGuideId: number): string;
  createUserGuide: string;
  updateUserGuide(userGuideId: number): string;
  deleteUserGuide(userGuideId: number): string;
  downloadUserGuide(userGuideDocumentName: string): string;
}

export interface ICountryManagementEndpoints{
  //Country
  getAllCountries: string;
  getCountryById(countryId: number): string;
  createCountry: string;
  updateCountry(countryId: number): string;
  deleteCountry(countryId: number): string;
  hideCountry(countryId: number): string;
  showCountry(countryId: number): string;

  //Country Currency
  getAllCountryCurrencies: string;
  getAllCountryCurrencyById(countryCurrencyId: number): string;
  createCountryCurrency: string;
  updateCountryCurrency(countryCurrencyId: number): string;
  deleteCountryCurrency(countryCurrencyId: number): string;
  hideCountryCurrency(countryCurrencyId: number): string;
  showCountryCurrency(countryCurrencyId: number): string;
}

export interface IPagesManagementEndpoints{
  //Country
  getAllPageTemplates: string;
  getPageTemplateByPageTarget(pageTarget: number): string;
  getPageTemplateById(id: number): string;
  createPageTemplate: string;
  updatePageTemplate(countryId: number): string;
  deletePageTemplate(countryId: number): string;
  getActivePageTemplate(pageTarget: number): string;
  setActivePageTemplate(id: number, pageTarget: number): string
}

export interface IMasterDataConfigurationEndpoints {
  //Audit Firm
  getAllAuditFirms: string;
  getAuditFirmById(auditFirmId: number): string;
  createAuditFirm: string;
  updateAuditFirm(auditFirmId: number): string;
  deleteAuditFirm(auditFirmId: number): string;
  hideAuditFirm(auditFirmId: number): string;
  showAuditFirm(auditFirmId: number): string;

  //Deliverable
  getAllDeliverables: string;
  getDeliverableById(deliverableId: number): string;
  createDeliverable: string;
  updateDeliverable(deliverableId: number): string;
  deleteDeliverable(deliverableId: number): string;
  hideDeliverable(deliverableId: number): string;
  showDeliverable(deliverableId: number): string;

  //Reason
  getAllReasons: string;
  getReasonById(reasonId: number): string;
  createReason: string;
  updateReason(reasonId: number): string;
  deleteReason(reasonId: number): string;
  hideReason(reasonId: number): string;
  showReason(reasonId: number): string;


  //Service Type
  getAllServiceTypes: string;
  getServiceTypeById(serviceTypeId: number): string;
  createServiceType: string;
  updateServiceType(serviceTypeId: number): string;
  deleteServiceType(serviceTypeId: number): string;
  hideServiceType(serviceTypeId: number): string;
  showServiceType(serviceTypeId: number): string;

}

export interface IRequestManagementEndpoints{
  getAllRequests: string;
  getRequestById(requestId: number): string;
  addRequestFileById(requestId: number): string;
  createRequest: string;
  updateRequest(requestId: number): string;
  deleteRequest(requestId: number): string;
  uploadLoadFile: string;
  downloadFile(fileName: string): string;
  getRequestByBusinessArea: string;
  getRequestByStatus(status: string): string;


  //Temp Request
  getAllTempRequests: string;
  getTempRequestById(requestId: number): string;
  getTempRequestByEmailAddress(emailAddress: string): string;
  addTempRequestFileById(requestId: number): string;
  createTempRequest: string;
  updateTempRequest(requestId: number): string;
  deleteTempRequest(requestId: number): string;
  uploadLoadTempRequestFile: string;
  downloadTempRequestFile(fileName: string): string;
}

export interface IRequestDetailsApprovalEmailManagement {
  requestDetailsApprovalEmail: string;
  requestDetailsApprovalEmailCheck: string;
  requestDetailsApprovalEmailWithAmendment: string;
  requestDetailsApprovalEmailWithAmendmentCheck: string;
}

export interface IServiceManagementEndpoints {
  //Service Type
  getAllServiceTypes: string;
  getServiceTypeById(serviceTypeId: number): string;
  createServiceType: string;
  updateServiceType(serviceTypeId: number): string;
  deleteServiceType(serviceTypeId: number): string;
  hideServiceType(serviceTypeId: number): string;
  showServiceType(serviceTypeId: number): string;

  //Service Category
  getAllServiceCategories: string;
  getServiceCategoryById(serviceCategoryId: number): string;
  createServiceCategory: string;
  updateServiceCategory(serviceCategoryId: number): string;
  deleteServiceCategory(serviceCategoryId: number): string;
  hideServiceCategory(serviceCategoryId: number): string;
  showServiceCategory(serviceCategoryId: number): string;

  //Service Sub Category
  getAllServiceSubCategories: string;
  getServiceSubCategoryById(serviceSubCategoryId: number): string;
  createServiceSubCategory: string;
  updateServiceSubCategory(serviceSubCategoryId: number): string;
  deleteServiceSubCategory(serviceSubCategoryId: number): string;
  hideServiceSubCategory(serviceSubCategoryId: number): string;
  showServiceSubCategory(serviceSubCategoryId: number): string;

  //Detailed Service Type
  getAllDetailedServiceTypes: string;
  getDetailedServiceTypeById(serviceTypeId: number): string;
  createDetailedServiceType: string;
  updateDetailedServiceType(serviceTypeId: number, serviceCategoryId: number, serviceSubCategoryId?: number): string;
}

export interface ISegmentManagementEndpoints{
  //Segment
  getAllSegments: string;
  getSegmentById(segmentId: number): string;
  createSegment: string;
  updateSegment(segmentId: number): string;
  deleteSegment(segmentId: number): string;
  hideSegment(segmentId: number): string;
  showSegment(segmentId: number): string;

  //Sub-Segment
  getAllSubSegments: string;
  getSubSegmentById(subSegmentId: number): string;
  createSubSegment: string;
  updateSubSegment(subSegmentId: number): string;
  deleteSubSegment(subSegmentId: number): string;
  hideSubSegment(subSegmentId: number): string;
  showSubSegment(subSegmentId: number): string;

  //Detailed-Segment
  getAllDetailedSegments: string;
  getDetailedSegments(segmentId: number): string;
  createDetailedSegment: string;
  updateDetailedSegment(segmentId: number, subSegmentId?: number): string;
}
