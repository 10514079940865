import {Component, OnInit} from '@angular/core';
import {CanComponentDeactivateConfirm} from "../../../../guards/navigate-away-confirm.guard";
import {
  AuditFirmResponse,
  CreateAudiFirmCommand,
  UpdateAudiFirmCommand
} from "../../../master-data-configuration/models/audit-firm";
import {PageMode} from "../../../../enums/separated-enums/page-mode.enum";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {SnackBarService} from "../../../../service/snack-bar/snack-bar.service";
import {
  MasterDataConfigurationValidator
} from "../../../master-data-configuration/validators/master-data-configuration.validator";
import {
  MasterDataConfigurationServiceAbstract
} from "../../../master-data-configuration/services/master-data-configuration.service.abstract";
import {ServiceErrorHandler} from "../../../../service/error-handler/error-handler.service";
import {
  CreateServiceCategoryCommand,
  ServiceCategoryResponse,
  UpdateServiceCategoryCommand
} from "../../models/service-category";
import {HttpErrorResponse} from "@angular/common/http";
import {Constants} from "../../../master-data-configuration/models/constants";
import {ServiceManagementServiceAbstract} from "../../services/service-management.service.abstract";
import {ServiceManagementValidation} from "../../validators/service-management.validation";
import {ServiceTypeResponse} from "../../models/service-type";
import {ServiceSubCategoryResponse} from "../../models/service-sub-category";

@Component({
  selector: 'app-service-category-maintain',
  templateUrl: './service-category-maintain.component.html',
  styleUrls: ['./service-category-maintain.component.scss']
})
export class ServiceCategoryMaintainComponent
  implements OnInit, CanComponentDeactivateConfirm
{
  existingServiceCategories?: ServiceCategoryResponse[];
  selectedServiceCategory?: ServiceCategoryResponse;
  pageMode: PageMode = PageMode.Add;
  pageModeText = 'Create new';
  submitButtonText = 'Create';
  isLoading = false;
  serviceCategoryForm: FormGroup;
  useGuard = true;

  serviceTypes: ServiceTypeResponse[] = [];
  serviceSubCategories: ServiceSubCategoryResponse[] = [];

  constructor(
    public router: Router,
    private snackBar: SnackBarService,
    private serviceManagementValidation: ServiceManagementValidation,
    private serviceManagementService: ServiceManagementServiceAbstract,
    private serviceErrorHandler: ServiceErrorHandler,
  ){
    const parameters: any = router.GetNavigationParameter(
      ServiceCategoryMaintainComponent
    );

    if (parameters) {
      this.existingServiceCategories = parameters.existingServiceCategories;
      this.selectedServiceCategory = parameters.selectedServiceCategory;

      if (this.selectedServiceCategory) {
        this.pageMode = PageMode.Edit;
        this.pageModeText = 'Edit';
        this.submitButtonText = 'Update';
      } else {
        this.pageMode = PageMode.Add;
        this.pageModeText = 'Create new';
        this.submitButtonText = 'Create';
      }
    }
    else{
      router.NavigateTo.ServiceCategory();
    }

    this.serviceCategoryForm = new FormGroup({});
  }

  ngOnInit(): void {
    this.setupForm();
    this.getServiceSubCategory();
    this.getServiceType();
  }

  confirmNavigateAway(): boolean {
    return (
      this.serviceCategoryForm.touched && this.serviceCategoryForm.dirty && this.useGuard
    );
  }

  get nameCharacterCount() {
    return this.serviceCategoryForm.controls['name'].value.length;
  }

  get createServiceCategoryCommand() {
    return {
      name: this.serviceCategoryForm.controls['name'].value.trim(),
      serviceTypeId: this.serviceCategoryForm.controls['serviceTypeId'].value,
    } as CreateServiceCategoryCommand;
  }

  get updateServiceCategoryCommand() {
    return {
      name: this.serviceCategoryForm.controls['name'].value.trim(),
      serviceTypeId: this.serviceCategoryForm.controls['serviceTypeId'].value,
    } as UpdateServiceCategoryCommand;
  }

  setupForm() {
    this.serviceCategoryForm = new FormGroup({
      id: new FormControl(
        this.selectedServiceCategory ? this.selectedServiceCategory.id : undefined
      ),
      name: new FormControl(
        this.selectedServiceCategory ? this.selectedServiceCategory.name : ''
      ),
      serviceTypeId: new FormControl(this.selectedServiceCategory?.serviceType ? this.selectedServiceCategory.serviceType.id : null, Validators.required),
    });

    this.serviceCategoryForm.controls['name'].setValidators([
      Validators.required,
      this.serviceManagementValidation.duplicateNameValidator(
        this.serviceCategoryForm,
        this.existingServiceCategories
      ),
    ]);
  }

  getServiceSubCategory() {
    this.isLoading = true;
    this.serviceManagementService
      .getAllServiceSubCategories()
      .subscribe({
        next: (result: ServiceSubCategoryResponse[]) => {
          this.serviceSubCategories = result;
        },
        error: (error: HttpErrorResponse) => {
          this.serviceErrorHandler.displayHttpErrorDialog(
            this,
            error,
            Constants.ErrorMessages
          );
        },
      })
      .add(() => {
        this.isLoading = false;
      });
  }

  getServiceType() {
    this.isLoading = true;
    this.serviceManagementService
      .getAllServiceTypes()
      .subscribe({
        next: (result: ServiceTypeResponse[]) => {
          this.serviceTypes = result;
        },
        error: (error: HttpErrorResponse) => {
          this.serviceErrorHandler.displayHttpErrorDialog(
            this,
            error,
            Constants.ErrorMessages
          );
        },
      })
      .add(() => {
        this.isLoading = false;
      });
  }

  onSubmit() {
    Object.values(this.serviceCategoryForm.controls).forEach((control) => {
      control.markAsTouched();
    });

    if (this.serviceCategoryForm.valid) {
      if (this.pageMode === PageMode.Add) {
        this.processCreate();
      } else {
        this.processEdit();
      }
    }
  }

  processCreate() {
    this.isLoading = true;
    this.serviceManagementService
      .createServiceCategory(this.createServiceCategoryCommand)
      .subscribe({
        next: () => {
          this.showSuccessMessage();
        },
        error: (error: HttpErrorResponse) => {
          this.serviceErrorHandler.displayHttpErrorDialog(
            this,
            error,
            Constants.ErrorMessages
          );
        },
      })
      .add(() => {
        this.isLoading = false;
      });
  }

  processEdit() {
    if (this.selectedServiceCategory?.id) {
      this.isLoading = true;
      this.serviceManagementService
        .updateServiceCategory(
          this.selectedServiceCategory.id,
          this.updateServiceCategoryCommand
        )
        .subscribe({
          next: () => {
            this.showSuccessMessage();
          },
          error: (error: HttpErrorResponse) => {
            this.serviceErrorHandler.displayHttpErrorDialog(
              this,
              error,
              Constants.ErrorMessages
            );
          },
        })
        .add(() => {
          this.isLoading = false;
        });
    }
  }

  onCancel() {
    this.NavigateBackToView();
  }

  NavigateBackToView() {
    this.router.NavigateTo.ServiceCategory();
  }

  showSuccessMessage() {
    this.useGuard = false;
    this.NavigateBackToView();

    this.snackBar.open(
      `Service Category successfully ${
        this.pageMode === PageMode.Add ? 'created' : 'updated'
      }`
    );
  }
}
