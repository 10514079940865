import {Observable} from "rxjs";
import {CountryResponse, CreateCountryCommand, UpdateCountryCommand} from "../models/country";
import {
  CountryCurrencyResponse,
  CreateCountryCurrencyCommand,
  UpdateCountryCurrencyCommand
} from "../models/country-currency";

export abstract class CountryManagementServiceAbstract{
  //Country
  abstract getAllCountries(): Observable<CountryResponse[]>;
  abstract getCountryById(id: number): Observable<CountryResponse>;
  abstract createCountry(newCountry: CreateCountryCommand): Observable<CountryResponse>;
  abstract updateCountry(id: number, updateCountry: UpdateCountryCommand): Observable<CountryResponse>;
  abstract deleteCountry(id: number): Observable<number>;
  abstract hideCountry(id: number): Observable<number>;
  abstract showCountry(id: number): Observable<number>;

  //Country Currency
  abstract getAllCountryCurrencies(): Observable<CountryCurrencyResponse[]>;
  abstract getAllCountryCurrencyById(id: number): Observable<CountryCurrencyResponse>;
  abstract createCountryCurrency(newCountryCurrency: CreateCountryCurrencyCommand): Observable<CountryCurrencyResponse>;
  abstract updateCountryCurrency(id: number, updateCountryCurrency: UpdateCountryCurrencyCommand): Observable<CountryCurrencyResponse>;
  abstract deleteCountryCurrency(id: number): Observable<number>;
  abstract hideCountryCurrency(id: number): Observable<number>;
  abstract showCountryCurrency(id: number): Observable<number>;
}
