import {Observable} from "rxjs";
import {CreateDeliverableCommand, DeliverableResponse, UpdateDeliverableCommand} from "../models/deliverable";
import {AuditFirmResponse, CreateAudiFirmCommand, UpdateAudiFirmCommand} from "../models/audit-firm";
import {CreateReasonCommand, ReasonResponse, UpdateReasonCommand} from "../models/reason";


export abstract class MasterDataConfigurationServiceAbstract {
  //Audit Firm
  abstract getAllAudiFirm(): Observable<AuditFirmResponse[]>;
  abstract getAuditFirmById(id: number): Observable<AuditFirmResponse>;
  abstract createAuditFirm(newAuditFirm: CreateAudiFirmCommand): Observable<AuditFirmResponse>;
  abstract updateAuditFirm(id: number, updateAudiFirm: UpdateAudiFirmCommand): Observable<AuditFirmResponse>;
  abstract deleteAuditFirm(id: number): Observable<number>;
  abstract hideAuditFirm(id: number): Observable<number>;
  abstract showAuditFirm(id: number): Observable<number>;

  //Deliverable
  abstract getAllDeliverables(): Observable<DeliverableResponse[]>;
  abstract getDeliverableById(id: number): Observable<DeliverableResponse>;
  abstract createDeliverable(newDeliverable: CreateDeliverableCommand): Observable<DeliverableResponse>;
  abstract updateDeliverable(id: number, updateDeliverable: UpdateDeliverableCommand): Observable<DeliverableResponse>;
  abstract deleteDeliverable(id: number): Observable<number>;
  abstract hideDeliverable(id: number): Observable<number>;
  abstract showDeliverable(id: number): Observable<number>;

  //Reason
  abstract getAllReasons(): Observable<ReasonResponse[]>;
  abstract getReasonById(id: number): Observable<ReasonResponse>;
  abstract createReason(newReason: CreateReasonCommand): Observable<ReasonResponse>;
  abstract updateReason(id: number, updateReason: UpdateReasonCommand): Observable<ReasonResponse>;
  abstract deleteReason(id: number): Observable<number>;
  abstract hideReason(id: number): Observable<number>;
  abstract showReason(id: number): Observable<number>;

}
