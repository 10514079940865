<button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
  <mat-icon>filter_list</mat-icon>
</button>
<mat-menu #menu="matMenu">
  <div class="flex flex-col gap-2 w-full h-full">
    <button mat-menu-item (click)="reDirectToActiveFilter(ListActiveFilter.All)" >
      <span >All</span>
    </button>

    <button mat-menu-item (click)="reDirectToActiveFilter(ListActiveFilter.Active)">
      <span>Active</span>
    </button>

    <button mat-menu-item (click)="reDirectToActiveFilter(ListActiveFilter.Inactive)">
      <span>In Active</span>
    </button>
  </div>
</mat-menu>
