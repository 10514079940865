import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ServiceTypeResponse} from "../../../../service-management/models/service-type";
import {HttpErrorResponse} from "@angular/common/http";
import {
  ServiceManagementServiceAbstract
} from "../../../../service-management/services/service-management.service.abstract";
import {ServiceErrorHandler} from "../../../../../service/error-handler/error-handler.service";
import {
  Constants,
  DeliverableResponse,
  RequestMaintainServiceTypeData,
  RequestResponse,
  ServiceCategoryResponse
} from "../../../models";
import {ServiceSubCategoryResponse} from "../../../models/reference-response/service-sub-category-response.model";
import {
  MasterDataConfigurationServiceAbstract
} from "../../../../master-data-configuration/services/master-data-configuration.service.abstract";

@Component({
  selector: 'app-request-maintain-service-type',
  templateUrl: './request-maintain-service-type.component.html',
  styleUrls: ['./request-maintain-service-type.component.scss']
})
export class RequestMaintainServiceTypeComponent
  implements OnInit {

  @Input()
  selectedRequest?: RequestResponse;

  @Output()
  onIsBusinessFinancialControllerVisible:EventEmitter<boolean>
    = new EventEmitter<boolean>();

  @Output()
  onIsGroupFinancialControllerVisible: EventEmitter<boolean>
    = new EventEmitter<boolean>();

  serviceTypeForm: FormGroup;
  isLoading = false;

  serverTypes: ServiceTypeResponse[] = [];
  serviceCategories: ServiceCategoryResponse[] = [];
  serviceSubCategories: ServiceSubCategoryResponse[] = [];
  deliverables: DeliverableResponse[] = [];

  isBusinessFinancialControllerVisible: boolean = false;
  isGroupFinancialControllerVisible: boolean = false;
  isServiceSubCategoryVisible: boolean = false;

  auditReportDate?: Date;

  minDate?: Date

  constructor(
    private serviceManagementService : ServiceManagementServiceAbstract,
    private masterDataConfigurationService: MasterDataConfigurationServiceAbstract,
    private serviceErrorHandler: ServiceErrorHandler,
    ) {
    this.serviceTypeForm = new FormGroup({});
  }

  ngOnInit(): void {
    this.getServiceType();
    this.setupForm();
    this.setMinDate();
  }

  private setupForm(){

    console.log('this.selectedRequest', this.selectedRequest);
    this.serviceTypeForm = new FormGroup({
      serviceTypeId: new FormControl(this.selectedRequest?.serviceType ? this.selectedRequest.serviceType.id : null, Validators.required),
      serviceCategoryId: new FormControl(this.selectedRequest?.serviceCategory ? this.selectedRequest.serviceCategory.id : null, Validators.required),
      serviceSubCategoryId: new FormControl(this.selectedRequest?.serviceSubCategory ? this.selectedRequest.serviceSubCategory.id : null),
      auditReportDate: new FormControl(undefined, Validators.required),
      deliverableId: new FormControl(this.selectedRequest?.deliverable ? this.selectedRequest.deliverable.id : null, Validators.required),
    });


    this.serviceTypeForm.controls['serviceTypeId'].valueChanges.subscribe((value) => {
      var serviceType = this.serverTypes.find((x) => x.id === Number(value))
      if (serviceType?.name.trim() === 'Permitted non-audit services') {
        this.isBusinessFinancialControllerVisible = false;
        this.isGroupFinancialControllerVisible = false;
        this.isServiceSubCategoryVisible = true;
        this.serviceTypeForm.controls['serviceSubCategoryId'].setValidators([Validators.required])
      } else if (serviceType?.name.trim() === 'Audit-related non-audit services') {
        this.isBusinessFinancialControllerVisible = true;
        this.isGroupFinancialControllerVisible = true;
        this.isServiceSubCategoryVisible = false;
        this.serviceTypeForm.controls['serviceSubCategoryId'].clearValidators()
      }

      this.onIsBusinessFinancialControllerVisible.emit(this.isBusinessFinancialControllerVisible);
      this.onIsGroupFinancialControllerVisible.emit(this.isGroupFinancialControllerVisible);

      this.getServiceCategories(Number(value));
    });

    this.serviceTypeForm.controls['serviceCategoryId'].valueChanges.subscribe((value) => {
      if(this.isServiceSubCategoryVisible){
        this.getServiceSubCategory(Number(value));
      }
    });


    if(this.selectedRequest?.serviceType?.id){
      const value = this.selectedRequest.serviceType.id;
      var serviceType = this.serverTypes.find((x) => x.id === value)
      if (serviceType?.name.trim() === 'Permitted non-audit services') {
        this.isBusinessFinancialControllerVisible = false;
        this.isGroupFinancialControllerVisible = false;
        this.isServiceSubCategoryVisible = true;
        this.serviceTypeForm.controls['serviceSubCategoryId'].setValidators([Validators.required])
      } else if (serviceType?.name.trim() === 'Audit-related non-audit services') {
        this.isBusinessFinancialControllerVisible = true;
        this.isGroupFinancialControllerVisible = true;
        this.isServiceSubCategoryVisible = false;
        this.serviceTypeForm.controls['serviceSubCategoryId'].clearValidators();
      }

      this.onIsBusinessFinancialControllerVisible.emit(this.isBusinessFinancialControllerVisible);
      this.onIsGroupFinancialControllerVisible.emit(this.isGroupFinancialControllerVisible);

      this.getServiceCategories(value);
    }

    if(this.selectedRequest?.serviceCategory?.id){
      const value = this.selectedRequest.serviceCategory.id;
      if(this.isServiceSubCategoryVisible){
        this.getServiceSubCategory(Number(value));
      }
    }


  }

  clearForm() : void {
    this.serviceTypeForm = new FormGroup({
      serviceTypeId: new FormControl(null, Validators.required),
      serviceCategoryId: new FormControl(null, Validators.required),
      serviceSubCategoryId: new FormControl( null),
      auditReportDate: new FormControl(null, Validators.required),
      deliverableId: new FormControl(null, Validators.required),
    });

    this.serviceTypeForm.controls['serviceTypeId'].valueChanges.subscribe((value) => {
      var serviceType = this.serverTypes.find((x) => x.id === Number(value))

      if (serviceType?.name.trim() === 'Permitted non-audit services') {
        this.isBusinessFinancialControllerVisible = false;
        this.isGroupFinancialControllerVisible = false;
        this.isServiceSubCategoryVisible = true;
      } else if (serviceType?.name.trim() === 'Audit-related non-audit services') {
        this.isBusinessFinancialControllerVisible = true;
        this.isGroupFinancialControllerVisible = true;
        this.isServiceSubCategoryVisible = false;
      }

      this.onIsBusinessFinancialControllerVisible.emit(this.isBusinessFinancialControllerVisible);
      this.onIsGroupFinancialControllerVisible.emit(this.isGroupFinancialControllerVisible);

      this.getServiceCategories(Number(value));
    });

    this.serviceTypeForm.controls['serviceCategoryId'].valueChanges.subscribe((value) => {
      if(this.isServiceSubCategoryVisible){
        this.getServiceSubCategory(Number(value));
      }
    });

  }

  private getServiceType(){
    this.isLoading = true;
    this.serviceManagementService
      .getAllServiceTypes()
      .subscribe({
        next: (result: ServiceTypeResponse[]) => {
          this.serverTypes = result?.filter(x => x?.isActive);
          this.getDeliverables();
        },
        error: (error: HttpErrorResponse) => {
          this.serviceErrorHandler.displayHttpErrorDialog(
            this,
            error,
            Constants.ErrorMessages
          );
        },
      })
      .add(() => {
        this.isLoading = false;
      });
  }


  private setMinDate(): void {
      this.minDate= new Date();
  }

  private getServiceCategories(serviceTypeId: number){
    this.isLoading = true;
    this.serviceManagementService
      .getAllServiceCategories()
      .subscribe({
        next: (result: ServiceCategoryResponse[]) => {
          this.serviceCategories = result?.filter((x) => x?.serviceType?.id === serviceTypeId && x?.isActive);
        },
        error: (error: HttpErrorResponse) => {
          this.serviceErrorHandler.displayHttpErrorDialog(
            this,
            error,
            Constants.ErrorMessages
          );
        },
      })
      .add(() => {
        this.isLoading = false;
      });
  }

  private getDeliverables(){
    this.isLoading = true;
    this.masterDataConfigurationService
        .getAllDeliverables()
        .subscribe({
          next: (result: DeliverableResponse[]) => {
            this.deliverables = result?.filter(x => x?.isActive);
            this.setupForm();
          },
          error: (error: HttpErrorResponse) => {
            this.serviceErrorHandler.displayHttpErrorDialog(
                this,
                error,
                Constants.ErrorMessages
            );
          },
        })
        .add(() => {
          this.isLoading = false;
        });
  }

  private getServiceSubCategory(serviceCategoryId: number){
    this.isLoading = true;
    this.serviceManagementService
      .getAllServiceSubCategories()
      .subscribe({
        next: (result: ServiceSubCategoryResponse[]) => {
          this.serviceSubCategories = result?.filter((x) => x?.serviceCategory?.id === serviceCategoryId && x?.isActive);
        },
        error: (error: HttpErrorResponse) => {
          this.serviceErrorHandler.displayHttpErrorDialog(
            this,
            error,
            Constants.ErrorMessages
          );
        },
      })
      .add(() => {
        this.isLoading = false;
      });
  }

  protected clearDateCreated() {
    this.serviceTypeForm.controls['dateCreated'].reset();
  }

  public fetchData() : RequestMaintainServiceTypeData {
    const auditReportDate = new Date( this.serviceTypeForm.controls['auditReportDate'].value);

    const auditReportYear = auditReportDate.getFullYear();
    const auditReportMonth = (auditReportDate.getMonth());
    const auditReportDay = auditReportDate.getDate();
    const date = new Date(Date.UTC(auditReportYear, auditReportMonth, auditReportDay))

    return {
      serviceTypeId: this.serviceTypeForm.controls['serviceTypeId'].value ? Number(this.serviceTypeForm.controls['serviceTypeId'].value) : null,
      serviceCategoryId: this.serviceTypeForm.controls['serviceCategoryId'].value ?  Number(this.serviceTypeForm.controls['serviceCategoryId'].value) : null,
      serviceSubCategoryId: this.serviceTypeForm.controls['serviceSubCategoryId'].value ? Number(this.serviceTypeForm.controls['serviceSubCategoryId'].value) : null,
      deliverableId: this.serviceTypeForm.controls['deliverableId'].value ? Number(this.serviceTypeForm.controls['deliverableId'].value) : null,
      auditReportDate: date
    } as RequestMaintainServiceTypeData
  }

}
