import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {CanComponentDeactivateConfirm} from "../../../../guards/navigate-away-confirm.guard";
import {Router} from "@angular/router";
import {SnackBarService} from "../../../../service/snack-bar/snack-bar.service";
import {ServiceManagementValidation} from "../../validators/service-management.validation";
import {ServiceManagementServiceAbstract} from "../../services/service-management.service.abstract";
import {ServiceErrorHandler} from "../../../../service/error-handler/error-handler.service";
import {PageMode} from "../../../../enums";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ServiceCategoryResponse} from "../../models/service-category";
import {
  CreateDetailedServiceTypeCommand,
  DetailedServiceTypeResponse,
  UpdateDetailedServiceTypeCommand
} from "../../models/detailed-service-type";
import {ServiceTypeResponse} from "../../models/service-type";
import {HttpErrorResponse} from "@angular/common/http";
import {Constants} from "../../../master-data-configuration/models/constants";

@Component({
  selector: 'app-detailed-service-type-maintain',
  templateUrl: './detailed-service-type-maintain.component.html',
  styleUrls: ['./detailed-service-type-maintain.component.scss']
})
export class DetailedServiceTypeMaintainComponent implements OnInit, CanComponentDeactivateConfirm, OnDestroy {

  existingDetailedServiceTypes?: DetailedServiceTypeResponse[];
  selectedDetailedServiceType?: DetailedServiceTypeResponse;
  pageMode: PageMode = PageMode.Add;
  pageModeText = 'Create new';
  submitButtonText = 'Create';
  isLoading = false;
  detailedServiceCategoryForm: FormGroup;
  useGuard = true;

  serviceTypes: ServiceTypeResponse[] = [];
  serviceCategories: ServiceCategoryResponse[] = [];

  createNewServiceType: boolean = false;
  createNewServiceCategory: boolean = false;
  createNewServiceSubCategory: boolean = false;

  updateSelectedServiceType: boolean = false;
  updateSelectedServiceCategory: boolean = false;

  processing = this.pageMode === PageMode.Edit;



  constructor(
    public router: Router,
    private snackBar: SnackBarService,
    private serviceManagementValidation: ServiceManagementValidation,
    private serviceManagementService: ServiceManagementServiceAbstract,
    private serviceErrorHandler: ServiceErrorHandler,
    private cd : ChangeDetectorRef
  ){
    const parameters: any = router.GetNavigationParameter(
      DetailedServiceTypeMaintainComponent
    );

    if (parameters) {
      this.existingDetailedServiceTypes = parameters.existingDetailedServiceTypes;
      this.selectedDetailedServiceType = parameters.selectedDetailedServiceType;

      if (this.selectedDetailedServiceType) {
        this.pageMode = PageMode.Edit;
        this.pageModeText = 'Edit';
        this.submitButtonText = 'Update';

      } else {
        this.pageMode = PageMode.Add;
        this.pageModeText = 'Create new';
        this.submitButtonText = 'Create';
      }
    }
    else{
      router.NavigateTo.DetailedServiceType();
    }

    this.detailedServiceCategoryForm = new FormGroup({});
  }

  ngOnDestroy(): void {
    this.cd.detach();
  }

  ngOnInit(): void {
    this.getServiceType();
    this.setupForm();
  }

  confirmNavigateAway(): boolean {
    return (
      this.detailedServiceCategoryForm.touched && this.detailedServiceCategoryForm.dirty && this.useGuard
    );
  }

  get createDetailedServiceTypeCommand() {
    return {
      serviceTypeId: this.detailedServiceCategoryForm.controls['serviceTypeId'].value,
      serviceTypeName: this.createNewServiceType ? this.detailedServiceCategoryForm.controls['serviceTypeName'].value : null,
      serviceCategoryId: this.detailedServiceCategoryForm.controls['serviceCategoryId'].value,
      serviceCategoryName: this.createNewServiceCategory ? this.detailedServiceCategoryForm.controls['serviceCategoryName'].value : null,
      serviceSubCategoryId: this.detailedServiceCategoryForm.controls['serviceSubCategoryId'].value,
      serviceSubCategoryName: +this.detailedServiceCategoryForm.controls['createServiceSubCategory'].value === 1 ? this.detailedServiceCategoryForm.controls['serviceSubCategoryName'].value : null,
      serviceSubCategoryDisplayOrder: +this.detailedServiceCategoryForm.controls['createServiceSubCategory'].value === 1 ? this.detailedServiceCategoryForm.controls['serviceSubCategoryDisplayOrder'].value : null,
    } as CreateDetailedServiceTypeCommand;
  }

  get updateDetailedServiceTypeCommand() {
    const formServiceTypeId = +this.detailedServiceCategoryForm.controls['serviceTypeId'].value;
    const formServiceCategoryId = +this.detailedServiceCategoryForm.controls['serviceCategoryId'].value;
    return {
      serviceTypeId: formServiceTypeId,
      serviceTypeName: this.createNewServiceType || formServiceTypeId === this.selectedDetailedServiceType?.serviceTypeId ? this.detailedServiceCategoryForm.controls['serviceTypeName'].value : null,
      serviceCategoryId: formServiceCategoryId,
      serviceCategoryName: this.createNewServiceCategory || formServiceCategoryId === this.selectedDetailedServiceType?.serviceCategoryId ? this.detailedServiceCategoryForm.controls['serviceCategoryName'].value : null,
      serviceSubCategoryId: this.detailedServiceCategoryForm.controls['serviceSubCategoryId'].value,
      serviceSubCategoryName: +this.detailedServiceCategoryForm.controls['createServiceSubCategory'].value === 1 ? this.detailedServiceCategoryForm.controls['serviceSubCategoryName'].value : null,
      serviceSubCategoryDisplayOrder: +this.detailedServiceCategoryForm.controls['createServiceSubCategory'].value === 1 ? this.detailedServiceCategoryForm.controls['serviceSubCategoryDisplayOrder'].value : null,
    } as UpdateDetailedServiceTypeCommand;
  }


  setupForm() {
    this.detailedServiceCategoryForm = new FormGroup({
      serviceTypeId: new FormControl(this.selectedDetailedServiceType ? this.selectedDetailedServiceType.serviceTypeId : undefined, [Validators.required, Validators.min(0)]),
      serviceTypeName: new FormControl(this.selectedDetailedServiceType ? this.selectedDetailedServiceType.serviceTypeName : ''),
      serviceCategoryId: new FormControl(this.selectedDetailedServiceType ? this.selectedDetailedServiceType.serviceCategoryId : undefined, [Validators.required, Validators.min(0)]),
      serviceCategoryName: new FormControl(this.selectedDetailedServiceType ? this.selectedDetailedServiceType.serviceCategoryName : ''),
      createServiceSubCategory: new FormControl(this.selectedDetailedServiceType ? this.selectedDetailedServiceType.serviceSubCategoryId > 0 ? 1 : 0 :0),
      serviceSubCategoryId: new FormControl(this.selectedDetailedServiceType ? this.selectedDetailedServiceType.serviceSubCategoryId : undefined),
      serviceSubCategoryName: new FormControl(this.selectedDetailedServiceType ? this.selectedDetailedServiceType.serviceSubCategoryName : ''),
      serviceSubCategoryDisplayOrder: new FormControl(this.selectedDetailedServiceType ? this.selectedDetailedServiceType.serviceSubCategoryDisplayOrder : 0)
    });

    if(this.selectedDetailedServiceType) {
      const { serviceTypeId, serviceCategoryId, serviceSubCategoryId } = this.selectedDetailedServiceType;

      if (serviceTypeId) {
        if (serviceTypeId > 0) {
          this.detailedServiceCategoryForm.controls['serviceTypeName'].setValidators([
            Validators.required,
            this.serviceManagementValidation.duplicateDetailedServiceTypeNameValidator(
              this.detailedServiceCategoryForm,
              this.existingDetailedServiceTypes
            ),
          ]);
          this.getServiceCategory(this.selectedDetailedServiceType.serviceTypeId);
          this.cd.detectChanges();
        } else {
          this.detailedServiceCategoryForm.controls['serviceTypeName'].clearValidators();
          this.detailedServiceCategoryForm.controls['serviceTypeName'].updateValueAndValidity();
        }

        if (serviceTypeId < 0) {
          this.detailedServiceCategoryForm.controls['serviceCategoryId'].disable()
        } else {
          this.detailedServiceCategoryForm.controls['serviceCategoryId'].enable()
          this.detailedServiceCategoryForm.controls['serviceCategoryId'].patchValue(this.pageMode === PageMode.Edit ? serviceCategoryId : undefined)
        }
        this.detailedServiceCategoryForm.updateValueAndValidity();
      } else {
        this.detailedServiceCategoryForm.controls['serviceCategoryId'].disable()
      }

      if (serviceSubCategoryId) {
        this.createNewServiceSubCategory = serviceSubCategoryId > 0 ? true : false;
        if (this.createNewServiceSubCategory) {
          this.detailedServiceCategoryForm.controls['serviceSubCategoryName'].setValidators([
            Validators.required,
            this.serviceManagementValidation.duplicateDetailedServiceSubCategoryNameValidator(
              this.detailedServiceCategoryForm,
              this.existingDetailedServiceTypes
            ),
          ]);
          this.detailedServiceCategoryForm.controls['serviceSubCategoryDisplayOrder'].setValidators([Validators.required, Validators.min(1)])
        } else {
          this.detailedServiceCategoryForm.controls['serviceSubCategoryName'].clearValidators();
          this.detailedServiceCategoryForm.controls['serviceSubCategoryDisplayOrder'].clearValidators();
          this.detailedServiceCategoryForm.controls['serviceSubCategoryName'].updateValueAndValidity();
          this.detailedServiceCategoryForm.controls['serviceSubCategoryDisplayOrder'].updateValueAndValidity();
        }

        this.detailedServiceCategoryForm.updateValueAndValidity();
      }

      if (serviceCategoryId) {
        if (serviceCategoryId > 0) {
          this.detailedServiceCategoryForm.controls['serviceCategoryName'].setValidators([
            Validators.required,
            this.serviceManagementValidation.duplicateDetailedServiceCategoryNameValidator(
              this.detailedServiceCategoryForm,
              this.existingDetailedServiceTypes
            ),
          ]);
        } else {
          this.detailedServiceCategoryForm.controls['serviceCategoryName'].clearValidators();
          this.detailedServiceCategoryForm.controls['serviceCategoryName'].updateValueAndValidity();
        }
      }
    }

    this.detailedServiceCategoryForm.controls['serviceTypeId'].valueChanges.subscribe((value) => {
        if(+value === 0){
          this.createNewServiceType = true
          this.updateSelectedServiceType = false;
          this.detailedServiceCategoryForm.controls['serviceTypeName'].setValidators([
            Validators.required,
            this.serviceManagementValidation.duplicateDetailedServiceTypeNameValidator(
              this.detailedServiceCategoryForm,
              this.existingDetailedServiceTypes
            ),
          ]);
          this.detailedServiceCategoryForm.controls['serviceTypeName'].patchValue("");
        }
        else{
          if(+value !== this.selectedDetailedServiceType?.serviceTypeId) {
            this.createNewServiceType = false;
            this.updateSelectedServiceType = true;
            this.detailedServiceCategoryForm.controls['serviceTypeName'].clearValidators();
            this.detailedServiceCategoryForm.controls['serviceTypeName'].updateValueAndValidity();
            this.detailedServiceCategoryForm.controls['serviceTypeName'].patchValue("");
          }
        }

        if(this.pageMode === PageMode.Add){
          if(+value >= 0){
            this.detailedServiceCategoryForm.controls['serviceCategoryId'].enable()
            this.detailedServiceCategoryForm.controls['serviceCategoryId'].patchValue(undefined)
          }
          else{
            this.detailedServiceCategoryForm.controls['serviceCategoryId'].disable()
          }
        }

        if(this.pageMode === PageMode.Edit){
          if(+value === this.selectedDetailedServiceType?.serviceTypeId){
            this.createNewServiceType = true
            this.updateSelectedServiceType = false;
            this.detailedServiceCategoryForm.controls['serviceTypeName'].setValidators([
              Validators.required,
              this.serviceManagementValidation.duplicateDetailedServiceTypeNameValidator(
                this.detailedServiceCategoryForm,
                this.existingDetailedServiceTypes
              ),
            ]);
            this.detailedServiceCategoryForm.controls['serviceTypeName'].patchValue(this.selectedDetailedServiceType.serviceTypeName);
          }
        }

        this.getServiceCategory(+value);

      this.detailedServiceCategoryForm.updateValueAndValidity();
   })

    this.detailedServiceCategoryForm.controls['serviceCategoryId'].valueChanges.subscribe((value) => {

      if(+value === 0){
        this.createNewServiceCategory = true
        this.updateSelectedServiceCategory = false;
        this.detailedServiceCategoryForm.controls['serviceCategoryName'].setValidators([
          Validators.required,
          this.serviceManagementValidation.duplicateDetailedServiceCategoryNameValidator(
            this.detailedServiceCategoryForm,
            this.existingDetailedServiceTypes
          ),
        ]);
        this.detailedServiceCategoryForm.controls['serviceCategoryName'].patchValue("");
      }
      else{
        if(+value !== this.selectedDetailedServiceType?.serviceCategoryId) {
          this.createNewServiceCategory = false;
          this.updateSelectedServiceCategory = true;
          this.detailedServiceCategoryForm.controls['serviceCategoryName'].clearValidators();
          this.detailedServiceCategoryForm.controls['serviceCategoryName'].updateValueAndValidity();
          this.detailedServiceCategoryForm.controls['serviceCategoryName'].patchValue("");
        }
      }

      if(this.pageMode === PageMode.Edit){
        if(+value === this.selectedDetailedServiceType?.serviceCategoryId){
          this.createNewServiceCategory = true
          this.updateSelectedServiceCategory = false;
          this.detailedServiceCategoryForm.controls['serviceCategoryName'].setValidators([
            Validators.required,
            this.serviceManagementValidation.duplicateDetailedServiceCategoryNameValidator(
              this.detailedServiceCategoryForm,
              this.existingDetailedServiceTypes
            ),
          ]);
          this.detailedServiceCategoryForm.controls['serviceCategoryName'].patchValue(this.selectedDetailedServiceType.serviceCategoryName);
        }
      }

      this.detailedServiceCategoryForm.updateValueAndValidity();
    })

    this.detailedServiceCategoryForm.controls['createServiceSubCategory'].valueChanges.subscribe((value) => {
      if(+value === 1){
        this.createNewServiceSubCategory = true;
        this.detailedServiceCategoryForm.controls['serviceSubCategoryName'].setValidators([
          Validators.required,
          this.serviceManagementValidation.duplicateDetailedServiceSubCategoryNameValidator(
            this.detailedServiceCategoryForm,
            this.existingDetailedServiceTypes
          ),
        ]);
        this.detailedServiceCategoryForm.controls['serviceSubCategoryDisplayOrder'].setValidators([Validators.required, Validators.min(1)]);

      }
      else{
        this.createNewServiceSubCategory = false;
        this.detailedServiceCategoryForm.controls['serviceSubCategoryName'].clearValidators();
        this.detailedServiceCategoryForm.controls['serviceSubCategoryDisplayOrder'].clearValidators();
        this.detailedServiceCategoryForm.controls['serviceSubCategoryName'].updateValueAndValidity();
        this.detailedServiceCategoryForm.controls['serviceSubCategoryDisplayOrder'].updateValueAndValidity();
      }

      this.detailedServiceCategoryForm.updateValueAndValidity();
    })

    if(this.pageMode == PageMode.Edit){
      this.detailedServiceCategoryForm.controls['createServiceSubCategory'].disable();
    }

  }

  getServiceType() {
    this.isLoading = true;
    this.serviceManagementService
      .getAllServiceTypes()
      .subscribe({
        next: (result: ServiceTypeResponse[]) => {
          this.serviceTypes = result;
        },
        error: (error: HttpErrorResponse) => {
          this.serviceErrorHandler.displayHttpErrorDialog(
            this,
            error,
            Constants.ErrorMessages
          );
        },
      })
      .add(() => {
        this.isLoading = false;
      });
  }

  getServiceCategory(serviceTypeId: number) {
    console.log('getServiceCategory', serviceTypeId)
    this.isLoading = true;
    this.serviceManagementService
      .getAllServiceCategories()
      .subscribe({
        next: (result: ServiceCategoryResponse[]) => {

          this.serviceCategories = this.pageMode === PageMode.Add ? result.filter((serviceCategory) => serviceCategory.serviceType.id === serviceTypeId)
            : result.filter((serviceCategory) => serviceCategory.serviceType.id === this.selectedDetailedServiceType?.serviceTypeId);
          this.processing = false;
        },
        error: (error: HttpErrorResponse) => {
          this.serviceErrorHandler.displayHttpErrorDialog(
            this,
            error,
            Constants.ErrorMessages
          );
        },
      })
      .add(() => {
        this.isLoading = false;
      });
  }

  onSubmit() {
    Object.values(this.detailedServiceCategoryForm.controls).forEach((control) => {
      control.markAsTouched();
    });

    if (this.detailedServiceCategoryForm.valid) {
      if (this.pageMode === PageMode.Add) {
        this.processCreate();
      } else {
        this.processEdit();
      }
    }
  }

  processCreate() {
    this.isLoading = true;
    this.serviceManagementService
      .createDetailedServiceTypeServiceCategory(this.createDetailedServiceTypeCommand)
      .subscribe({
        next: () => {
          this.showSuccessMessage();
        },
        error: (error: HttpErrorResponse) => {
          this.serviceErrorHandler.displayHttpErrorDialog(
            this,
            error,
            Constants.ErrorMessages
          );
        },
      })
      .add(() => {
        this.isLoading = false;
      });
  }

  processEdit() {
    const formServiceTypeId = +this.detailedServiceCategoryForm.controls['serviceTypeId'].value;
    const formServiceCategoryId = +this.detailedServiceCategoryForm.controls['serviceCategoryId'].value;
    const formServiceSubCategoryId = +this.detailedServiceCategoryForm.controls['createServiceSubCategory'].value === 1 ? this.detailedServiceCategoryForm.controls['serviceSubCategoryId'].value : null;
    if (this.selectedDetailedServiceType?.serviceTypeId && this.selectedDetailedServiceType?.serviceCategoryId) {
      this.isLoading = true;
      this.serviceManagementService
        .updateDetailedServiceType(
          formServiceTypeId,
          formServiceCategoryId,
          this.updateDetailedServiceTypeCommand,
          formServiceSubCategoryId
        )
        .subscribe({
          next: () => {
            this.showSuccessMessage();
          },
          error: (error: HttpErrorResponse) => {
            this.serviceErrorHandler.displayHttpErrorDialog(
              this,
              error,
              Constants.ErrorMessages
            );
          },
        })
        .add(() => {
          this.isLoading = false;
        });
    }
  }

  onCancel() {
    this.NavigateBackToView();
  }

  NavigateBackToView() {
    this.router.NavigateTo.DetailedServiceType();
  }

  showSuccessMessage() {
    this.useGuard = false;
    this.NavigateBackToView();

    this.snackBar.open(
      `Detailed Service Type successfully ${
        this.pageMode === PageMode.Add ? 'created' : 'updated'
      }`
    );
  }

  protected readonly PageMode = PageMode;

  //Ng Classes
  public getUpdateServiceType(serviceTypeId: number){
    return this.pageMode === PageMode.Edit ? {
      primaryRedTextColor: serviceTypeId === this.selectedDetailedServiceType?.serviceTypeId,
      blackTextColor: serviceTypeId !== this.selectedDetailedServiceType?.serviceTypeId,
    } : {
      blackTextColor: true
    }
  }

  public getUpdateServiceCategory(serviceCategoryId: number){
    return this.pageMode === PageMode.Edit ? {
      primaryRedTextColor:serviceCategoryId === this.selectedDetailedServiceType?.serviceCategoryId,
      blackTextColor: serviceCategoryId !== this.selectedDetailedServiceType?.serviceCategoryId,
    } : {
      blackTextColor: true,
    }
  }
}
