<div class="flex flex-col gap-[2px] items-stretch content-stretch p-3">

  <div fxLayout="column" fxLayoutGap="8px">
    <div class="mat-display-4">{{ pageModeText }} Request</div>
    <div class="mat-subheading-1">
      Please capture all required information below.
    </div>
  </div>

  <mat-divider></mat-divider>

  <app-request-maintain-basic
          [selectedRequest]="selectedRequest"
          [pageMode]="pageMode">
  </app-request-maintain-basic>

  <app-request-maintain-service-type
          [selectedRequest]="selectedRequest"
          (onIsBusinessFinancialControllerVisible)="onIsBusinessFinancialControllerVisible($event)"
          (onIsGroupFinancialControllerVisible)="onIsGroupFinancialControllerVisible($event)">
  </app-request-maintain-service-type>

  <app-request-maintain-deliverable-segment
          [selectedRequest]="selectedRequest"
          [isBusinessFinancialControllerVisible]="isBusinessFinancialControllerVisible"
          [isGroupFinancialControllerVisible]="isGroupFinancialControllerVisible">
  </app-request-maintain-deliverable-segment>

  <app-request-maintain-country-contract [selectedRequest]="selectedRequest">
  </app-request-maintain-country-contract>

  <app-request-maintain-audit-firm [selectedRequest]="selectedRequest">
  </app-request-maintain-audit-firm>

  <app-request-maintain-similar-request-reason [selectedRequest]="selectedRequest">
  </app-request-maintain-similar-request-reason>

  <app-request-maintain-file-upload-attest [selectedRequest]="selectedRequest">
  </app-request-maintain-file-upload-attest>

  <mat-divider></mat-divider>

  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="16px">
    <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="16px">
      <button
        mat-stroked-button
        color="primary"
        class="mat-stroked-button"
        (click)="onCancel()"
        [disabled]="isLoading"
      >
        Cancel
      </button>
      <button
        mat-stroked-button
        color="primary"
        class="mat-stroked-button"
        (click)="onClearForm()"
        [disabled]="isLoading"
        *ngIf="!isAmendmentEdit"
      >
        Clear Form
      </button>
    </div>
    <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="16px">
      <button
        mat-flat-button
        color="primary"
        class="mat-flat-button"
        (click)="onSaveProgress()"
        [disabled]="isLoading"
        *ngIf="!isAmendment"
      >
        {{ "Save Progress" }}
      </button>
      <button
        mat-flat-button
        color="primary"
        class="mat-flat-button"
        (click)="onSubmit()"
        [disabled]="isLoading"
      >
        {{ submitButtonText }}
      </button>
    </div>
  </div>
</div>
