import {Injectable} from "@angular/core";
import {InteractionType} from "@azure/msal-browser";
import {Client, PageCollection, PageIterator, PageIteratorCallback} from "@microsoft/microsoft-graph-client";
import {MsalAuthenticationProvider} from "./msal-auth-provider";
import {MsalService} from "@azure/msal-angular";
import {AbstractServiceAuthentication} from "../authentication/authentication.service.abstract";
import {ServiceConfig} from "../config/config.service";
import {ServiceMonitoring} from "../monitor/monitor.service";
import * as MicrosoftGraph from '@microsoft/microsoft-graph-types';

@Injectable({
  providedIn: 'root',
})
export class ServiceGraph {
  private providerOptions: any = {
    // Temp, need to use model
    account: this.serviceAuth.account!,
    scopes: this.serviceConfig.graph.scopes,
    interactionType: InteractionType.Popup,
  };

  private _clientGraph: Client | undefined;
  public get client(): Client {
    if (!this._clientGraph) {
      let clientOptions = {
        authProvider: new MsalAuthenticationProvider(
          this.providerOptions,
          this.authService
        ),
      };
      const graphClient = Client.initWithMiddleware(clientOptions);
      this._clientGraph = graphClient;
    }
    return this._clientGraph;
  }
  private set client(value: Client) {
    this._clientGraph = value;
  }

  private _pageIterator: PageIterator | undefined;
  public get pageIterator(): PageIterator | undefined {
    return this._pageIterator;
  }
  private set pageIterator(v: PageIterator | undefined) {
    this._pageIterator = v;
  }

  private userList: any[] = [];

  constructor(
    private authService: MsalService,
    private serviceAuth: AbstractServiceAuthentication,
    private serviceConfig: ServiceConfig,
    private serviceMonitor: ServiceMonitoring
  ) {}

  // /**
  //  * Returns a graph client object with the provided token acquisition options
  //  * @param {ProviderOptions} providerOptions: object containing user account, required scopes and interaction type
  //  */
  // getGraphClient = (providerOptions: Models.ProviderOptions) => {
  //   /**
  //    * Pass the instance as authProvider in ClientOptions to instantiate the Client which will create and set the default middleware chain.
  //    * For more information, visit: https://github.com/microsoftgraph/msgraph-sdk-javascript/blob/dev/docs/CreatingClientInstance.md
  //    */
  //   let clientOptions = {
  //     authProvider: new MsalAuthenticationProvider(providerOptions, this.authService),
  //   };
  //   const graphClient = Client.initWithMiddleware(clientOptions);
  //   return graphClient;
  // }

  // /**
  //  * Returns a list of users in a paged format
  //  * @param {pageSize} number: the size of the page that should be loaded
  //  * @param {pageIterationCallback} number: the function that will be called when iterating to a new page
  //  *
  //  * To iterate through the pages call GraphService.pageIterator.resume() from your component
  //  */
  async getPagedUsers(
    pageSize: number,
    pageIterationCallback: (users: any[]) => void,
    searchString: string = ''
  ) {
    try {
      let filterString = `JobTitle ne 'Service Account - PHONESYSTEM_VIRTUALUSER'
                          and JobTitle ne 'Resource\\Service Account'
                          and JobTitle ne 'Resource/Service Account'`;

      filterString +=
        searchString == ''
          ? '&$count=true'
          : `and (startsWith(displayName, '${searchString}') or startsWith(mail, '${searchString}'))&$count=true`;
      let count = 0;
      // Makes request to fetch users list. Which is expected to have multiple pages of data.
      let response: PageCollection = await this.client
        .api('/users')
        .header('ConsistencyLevel', 'eventual')
        .filter(filterString)
        .select('id,displayName,mail,department,userPrincipalName,jobTitle')
        .orderby('displayName')
        .get();
      // A callback function to be called for every item in the collection. This call back should return boolean indicating whether not to continue the iteration process.
      let callback: PageIteratorCallback = (user: MicrosoftGraph.User) => {
        count++;
        const employee = user;
        this.userList.push(employee);
        if (count === pageSize) {
          count = 0;
          pageIterationCallback(this.userList);
          this.userList = [];
          return false;
        }
        return true;
      };
      if (response.value.length < pageSize) {
        const users = response.value.map((value: MicrosoftGraph.User) => value);
        pageIterationCallback(users);
        this.userList = [];
      } else {
        // Creating a new page iterator instance with client a graph client instance, page collection response from request and callback
        this.pageIterator = new PageIterator(this.client, response, callback);
        // This iterates the collection until the nextLink is drained out.
        await this.pageIterator.iterate();
      }
    } catch (error) {
      this.serviceMonitor.logException(this, 'Error loading users from AD', {
        error: error,
      });
    }
  }

  async searchAllUsers(
    skipToken?: string,
    searchString: string = 'not specified',
    clearUsersBeforeSearch: boolean = false
  ) {
    if (clearUsersBeforeSearch) {
      this.userList = [];
    }
    let skipT = '';
    let filterString = `(startsWith(displayName, '${searchString}') or startsWith(mail, '${searchString}'))`;

    let query = this.client
      .api('/users')
      .header('ConsistencyLevel', 'eventual')
      .filter(filterString)
      .select('id,displayName,mail,department,userPrincipalName,jobTitle');

    let graphQuery = !skipToken ? query : query.skipToken(skipToken);
    await graphQuery.get().then(
      async (res) => {
        let users = res['value'] as MicrosoftGraph.User[];
        for (const user of users) {
          if (
            user.jobTitle !== 'Resource\\Service Account' &&
            user.jobTitle !== 'Resource/Service Account'
          ) {
            const employee = {
              name: user.displayName ?? '',
              surname: user.surname ?? '',
              region: user.department ?? '',
              email: user.mail ?? '',
              upn: user.userPrincipalName ?? '',
              role: user.jobTitle ?? '',
              id: user.id ?? '',
            };
            this.userList.push(employee);
          }
        }
        let nextLink = res['@odata.nextLink'];
        if (nextLink) {
          let url = new URL(nextLink);
          skipT = new URLSearchParams(url.search).get('$skipToken') || '';
          await this.searchAllUsers(skipT);
        }
      },
      (reason) => {
        this.serviceMonitor.logException(this, 'Error loading users from AD', {
          reason: reason,
        });
      }
    );
    return this.userList;
  }
}
