import {AuthenticationProvider} from "@microsoft/microsoft-graph-client";
import {MsalService} from "@azure/msal-angular";
import {AuthenticationResult, InteractionRequiredAuthError, InteractionType} from "@azure/msal-browser";

export class MsalAuthenticationProvider implements AuthenticationProvider {
  account;
  scopes;
  interactionType;

  constructor(providerOptions: any, private authService: MsalService) {
    this.account = providerOptions.account;
    this.scopes = providerOptions.scopes;
    this.interactionType = providerOptions.interactionType;
  }

  /**
   * This method will get called before every request to the ms graph server
   * This should return a Promise that resolves to an accessToken (in case of success) or rejects with error (in case of failure)
   * Basically this method will contain the implementation for getting and refreshing accessTokens
   */
  getAccessToken(): Promise<string> {
    return new Promise(async (resolve, reject) => {
      let response: AuthenticationResult;

      try {
        response = await this.authService.instance.acquireTokenSilent({
          account: this.account,
          scopes: this.scopes,
        });

        if (response.accessToken) {
          resolve(response.accessToken);
        } else {
          reject(Error('Failed to acquire an access token'));
        }
      } catch (error) {
        // in case if silent token acquisition fails, fallback to an interactive method
        if (error instanceof InteractionRequiredAuthError) {
          switch (this.interactionType) {
            case InteractionType.Popup:
              response = await this.authService.instance.acquireTokenPopup({
                scopes: this.scopes,
              });

              if (response.accessToken) {
                resolve(response.accessToken);
              } else {
                reject(Error('Failed to acquire an access token'));
              }

              break;

            case InteractionType.Redirect:
              /**
               * This will cause the app to leave the current page and redirect to the consent screen.
               * Once consent is provided, the app will return back to the current page and then the
               * silent token acquisition will succeed.
               */
              this.authService.instance.acquireTokenRedirect({
                scopes: this.scopes,
              });
              break;

            default:
              break;
          }
        }
      }
    });
  }
}
