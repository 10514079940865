import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {CanComponentDeactivateConfirm} from "../../../../guards/navigate-away-confirm.guard";
import {PageMode, Role} from "../../../../enums";
import {FormGroup} from "@angular/forms";
import {Router} from "@angular/router";
import {SnackBarService} from "../../../../service/snack-bar/snack-bar.service";
import {ServiceErrorHandler} from "../../../../service/error-handler/error-handler.service";
import {RequestManagementServiceAbstract} from "../../services/request-management.service.abstract";
import {CountryResponse, CreateRequestCommand, RequestResponse, UpdateRequestCommand} from "../../models";
import {HttpErrorResponse} from "@angular/common/http";
import {DeliverableResponse} from "../../../master-data-configuration/models/deliverable";
import {SegmentResponse} from "../../../segment-management/models/segment";
import {Constants} from "../../models";
import {
  RequestMaintainBasicComponent
} from "../shared-maintain/request-maintain-basic/request-maintain-basic.component";
import {
  RequestMaintainServiceTypeComponent
} from "../shared-maintain/request-maintain-service-type/request-maintain-service-type.component";
import {
  RequestMaintainDeliverableSegmentComponent
} from "../shared-maintain/request-maintain-deliverable-segment/request-maintain-deliverable-segment.component";
import {
  RequestMaintainCountryContractComponent
} from "../shared-maintain/request-maintain-country-contract/request-maintain-country-contract.component";
import {
  RequestMaintainAuditFirmComponent
} from "../shared-maintain/request-maintain-audit-firm/request-maintain-audit-firm.component";
import {
  RequestMaintainSimilarRequestReasonComponent
} from "../shared-maintain/request-maintain-similar-request-reason/request-maintain-similar-request-reason.component";
import {
  RequestMaintainFileUploadAttestComponent
} from "../shared-maintain/request-maintain-file-upload-attest/request-maintain-file-upload-attest.component";
import {RequestFileResponse} from "../../models/request/request-file-response.model";
import {SessionStorage} from "../../../../service/session/session.storage";
import {ConfirmationDialogComponent} from "../../../../dialog-boxes/confirmation-dialog/confirmation-dialog.component";

@Component({
  selector: 'app-request-maintain',
  templateUrl: './request-maintain.component.html',
  styleUrls: ['./request-maintain.component.scss']
})
export class RequestMaintainComponent
  implements CanComponentDeactivateConfirm
{
  @ViewChild(RequestMaintainBasicComponent)
  _requestMaintainBasicComponent!: RequestMaintainBasicComponent;

  @ViewChild(RequestMaintainServiceTypeComponent)
  _requestMaintainServiceTypeComponent!: RequestMaintainServiceTypeComponent;

  @ViewChild(RequestMaintainDeliverableSegmentComponent)
  _requestMaintainDeliverableSegmentComponent!: RequestMaintainDeliverableSegmentComponent;

  @ViewChild(RequestMaintainCountryContractComponent)
  _requestMaintainCountryContractComponent!: RequestMaintainCountryContractComponent;

  @ViewChild(RequestMaintainAuditFirmComponent)
  _requestMaintainAuditFirmComponent!: RequestMaintainAuditFirmComponent;

  @ViewChild(RequestMaintainSimilarRequestReasonComponent)
  _requestMaintainSimilarRequestReasonComponent!: RequestMaintainSimilarRequestReasonComponent;

  @ViewChild(RequestMaintainFileUploadAttestComponent)
  _requestMaintainFileUploadAttestComponent!: RequestMaintainFileUploadAttestComponent;

  errorMessage: string = '';
  fileFormData: FormData;

  existingRequests?: RequestResponse[];
  selectedRequest?: RequestResponse;
  isTempRequest?: boolean;
  isAmendment: boolean = false;
  pageMode: PageMode = PageMode.Add;
  pageModeText = 'Create new';
  submitButtonText = 'Submit';
  isLoading = false;
  requestForm: FormGroup;
  useGuard = true;

  isBusinessFinancialControllerVisible: boolean = false;
  isGroupFinancialControllerVisible: boolean = false;


  deliverables: DeliverableResponse[] = [];
  segments: SegmentResponse[] = [];
  countries: CountryResponse[] = [];


  fileName: string;
  filePath: string;

  constructor(
    public router: Router,
    private snackBar: SnackBarService,
    private requestManagementService: RequestManagementServiceAbstract,
    private serviceErrorHandler: ServiceErrorHandler,
    private sessionStorage: SessionStorage,
  ){
    let parameters: any = router.GetNavigationParameter(
      RequestMaintainComponent
    );

    if(!parameters){
      parameters = {
        existingAuditFirms: [],
        selectedRequest: null,
        isTempRequest: false,
        isAmendment: false,
      }
    }

    if (parameters) {
      this.existingRequests = parameters.existingAuditFirms;
      this.selectedRequest = parameters.selectedRequest;
      this.isTempRequest = parameters.isTempRequest;
      this.isAmendment = parameters.isAmendment;

      if (this.selectedRequest && (this.isTempRequest || this.isAmendment)) {
        this.pageMode = PageMode.Edit;
        this.pageModeText = 'Edit';
        this.submitButtonText = 'Submit';
      } else {
        this.pageMode = PageMode.Add;
        this.pageModeText = 'Create new';
        this.submitButtonText = 'Submit';
      }
    }
    else{
      router.NavigateTo.Request();
    }

    this.requestForm = new FormGroup({});
    this.fileName = '';
    this.filePath = '';
    this.fileFormData = new FormData();
  }


  confirmNavigateAway(): boolean {
    return (
      (this._requestMaintainAuditFirmComponent.auditFirmForm.touched && this._requestMaintainAuditFirmComponent.auditFirmForm.dirty) ||
      (this._requestMaintainBasicComponent.basicForm.touched && this._requestMaintainBasicComponent.basicForm.dirty) && this.useGuard
    );
  }

  get createRequestCommand() {
    const basicData = this._requestMaintainBasicComponent.fetchData();
    const serviceTypeData = this._requestMaintainServiceTypeComponent.fetchData();
    const deliverableSegment = this._requestMaintainDeliverableSegmentComponent.fetchData();
    const countryContractData = this._requestMaintainCountryContractComponent.fetchData();
    const auditFirmData = this._requestMaintainAuditFirmComponent.fetchData();
    const fileUploadAttestData = this._requestMaintainFileUploadAttestComponent.fetchData();
    const similarRequestReason = this._requestMaintainSimilarRequestReasonComponent.fetchData();
    this.fileFormData = fileUploadAttestData.fileFormData!;
    return {
      regulatory: basicData.regulatory,
      beneficiaryName: basicData.beneficiaryName,
      legislation: basicData.legislation,
      sectionOrRegulation: basicData.sectionOrRegulation,
      scopeOfWork: basicData.scopeOfWork,
      title: basicData.title,
      name: this.sessionStorage.getUserName,
      serviceTypeId:serviceTypeData.serviceTypeId,
      serviceCategoryId: serviceTypeData.serviceCategoryId,
      serviceSubCategoryId: serviceTypeData.serviceSubCategoryId,
      deliverableId: serviceTypeData.deliverableId,
      auditReportDate: serviceTypeData.auditReportDate,
      businessAreaId: deliverableSegment.businessAreaId,
      subsegmentId: deliverableSegment.subsegmentId,
      accountExecutiveUserId: deliverableSegment.accountExecutiveUserId,
      businessFinancialControllerUserId: deliverableSegment.businessFinancialControllerUserId,
      groupFinancialControllerUserId: deliverableSegment.groupFinancialControllerUserId,
      financialDirectorUserId: deliverableSegment.financialDirectorUserId,
      costCenter: deliverableSegment.costCenter,
      countryId: countryContractData.countryId,
      contractCurrencyId: countryContractData.contractCurrencyId,
      contractValue: countryContractData.contractValue,
      exchangeRate: countryContractData.exchangeRate,
      exchangeRateDate: countryContractData.exchangeRateDate,
      zAREquivalent: countryContractData.zAREquivalent,

      auditFirmId: auditFirmData.auditFirmId,
      assignedAuditPartnerUserId: auditFirmData.assignedAuditPartnerId,
      assignedAuditPartner: auditFirmData.assignedAuditPartner,

      userEmailAddress: this.sessionStorage.getUserEmailAddress,

      submissionLastYear: similarRequestReason.submissionLastYear,
      oldRequest: similarRequestReason.oldRequest,
      reasonAuditorsId: similarRequestReason.reasonAuditorsId,

      fileName: this.fileName,
      filePath: this.filePath,
      attestation: fileUploadAttestData.attestation,
      status: 'Submitted for review',
      isFormFinished: true,
      editedCount: 0,
      edited: false,
      dateAmended: null,


    } as CreateRequestCommand;
  }

  get updateRequestCommand() {
    const basicData = this._requestMaintainBasicComponent.fetchData();
    const serviceTypeData = this._requestMaintainServiceTypeComponent.fetchData();
    const deliverableSegment = this._requestMaintainDeliverableSegmentComponent.fetchData();
    const countryContractData = this._requestMaintainCountryContractComponent.fetchData();
    const auditFirmData = this._requestMaintainAuditFirmComponent.fetchData();
    const fileUploadAttestData = this._requestMaintainFileUploadAttestComponent.fetchData();
    const similarRequestReason = this._requestMaintainSimilarRequestReasonComponent.fetchData();
    this.fileFormData = fileUploadAttestData.fileFormData!;
    return {
      regulatory: basicData.regulatory,
      beneficiaryName: basicData.beneficiaryName,
      legislation: basicData.legislation,
      sectionOrRegulation: basicData.sectionOrRegulation,
      scopeOfWork: basicData.scopeOfWork,
      title: basicData.title,
      name: basicData.name ?? this.sessionStorage.getUserName,
      serviceTypeId:serviceTypeData.serviceTypeId,
      serviceCategoryId: serviceTypeData.serviceCategoryId,
      serviceSubCategoryId: serviceTypeData.serviceSubCategoryId,
      deliverableId: serviceTypeData.deliverableId,
      auditReportDate: serviceTypeData.auditReportDate,
      businessAreaId: deliverableSegment.businessAreaId,
      subsegmentId: deliverableSegment.subsegmentId,
      accountExecutiveUserId: deliverableSegment.accountExecutiveUserId,
      businessFinancialControllerUserId: deliverableSegment.businessFinancialControllerUserId,
      groupFinancialControllerUserId: deliverableSegment.groupFinancialControllerUserId,
      financialDirectorUserId: deliverableSegment.financialDirectorUserId,
      costCenter: deliverableSegment.costCenter,
      countryId: countryContractData.countryId,
      contractCurrencyId: countryContractData.contractCurrencyId,
      contractValue: countryContractData.contractValue,
      exchangeRate: countryContractData.exchangeRate,
      exchangeRateDate: countryContractData.exchangeRateDate,
      zAREquivalent: countryContractData.zAREquivalent,

      auditFirmId: auditFirmData.auditFirmId,
      assignedAuditPartnerUserId: auditFirmData.assignedAuditPartnerId,
      assignedAuditPartner: auditFirmData.assignedAuditPartner,

      userEmailAddress: this.sessionStorage.getUserEmailAddress,

      submissionLastYear: similarRequestReason.submissionLastYear,
      oldRequest: similarRequestReason.oldRequest,
      reasonAuditorsId: similarRequestReason.reasonAuditorsId,

      fileName: this.fileName,
      filePath: this.filePath,
      attestation: fileUploadAttestData.attestation,
      status: 'Submitted for review',
      isFormFinished: true,
      editedCount: 0,
      edited: false,
      dateAmended: null,


    }  as UpdateRequestCommand;
  }

  get isAmendmentEdit(): boolean {
    return (this.pageMode === PageMode.Edit && this.isAmendment );
  }

  onSubmit(){
    const auditFirmForm = this._requestMaintainAuditFirmComponent.auditFirmForm;
    const basicForm = this._requestMaintainBasicComponent.basicForm;
    const countryContractForm = this._requestMaintainCountryContractComponent.countryContractForm;
    const segmentForm = this._requestMaintainDeliverableSegmentComponent.segmentForm;
    const serviceTypeForm = this._requestMaintainServiceTypeComponent.serviceTypeForm;
    const reasonForm = this._requestMaintainSimilarRequestReasonComponent.reasonForm;
    const fileUploadAttestForm = this._requestMaintainFileUploadAttestComponent.fileUploadAttestForm

    Object.values(auditFirmForm.controls).forEach((control) => {
      control.markAsTouched();
    });

    Object.values(basicForm.controls).forEach((control) => {
      control.markAsTouched();
    });

    Object.values(countryContractForm.controls).forEach((control) => {
      control.markAsTouched();
    });

    Object.values(segmentForm.controls).forEach((control) => {
      control.markAsTouched();
    });

    Object.values(serviceTypeForm.controls).forEach((control) => {
      control.markAsTouched();
    });

    Object.values(reasonForm.controls).forEach((control) => {
      control.markAsTouched();
    });

    Object.values(fileUploadAttestForm.controls).forEach((control) => {
      control.markAsTouched();
    });

    if (auditFirmForm.valid &&
      countryContractForm.valid &&
      segmentForm.valid &&
      serviceTypeForm.valid &&
      reasonForm.valid &&
      fileUploadAttestForm.valid) {
      if (this.pageMode === PageMode.Add || this.isTempRequest) {
        this.addRequestFile();
      } else if(this.pageMode === PageMode.Edit && this.isAmendment) {
        this.addAmendmentRequestFile();
      }

      if(this.isTempRequest && this.selectedRequest){
        this.deleteTempRequestById()
      }
    }
    else{
      this.showErrorMessage('Please fill in all required fields');
    }
  }

  onSaveProgress() {
    if(!this.isTempRequest && this.pageMode === PageMode.Add){
        console.log("addTempRequestFile")
      this.addTempRequestFile();
    } else {
        console.log("editTempRequestFile")
      this.editTempRequestFile();
    }
  }

  addTempRequestFile() {
    if(this._requestMaintainFileUploadAttestComponent.fetchData().fileFormData){
      this.isLoading = true;
      this.requestManagementService
        .addRequestFileById(this._requestMaintainFileUploadAttestComponent.fetchData().fileFormData!)
        .subscribe({
          next: (response: RequestFileResponse) => {
            this.fileName = response.fileName;
            this.filePath = response.filePath;
            console.log('processCreateTempRequest after file save');
            this.processCreateTempRequest();
          },
          error: (error: HttpErrorResponse) => {
            this.serviceErrorHandler.displayHttpErrorDialog(
              this,
              error,
              Constants.ErrorMessages
            );
          },
        })
        .add(() => {
          this.isLoading = false;
        });
    }else {
        console.log('processCreateTempRequest');
      this.processCreateTempRequest();
    }

  }

  processCreateTempRequest() {
      console.log({
          ...this.createRequestCommand,
          isFormFinished: false,
      })
    this.isLoading = true;
    this.requestManagementService
      .createTempRequest({
        ...this.createRequestCommand,
        isFormFinished: false,
      })
      .subscribe({
        next: (response) => {
          this.showSuccessMessage();
        },
        error: (error: HttpErrorResponse) => {
          this.serviceErrorHandler.displayHttpErrorDialog(
            this,
            error,
            Constants.ErrorMessages
          );
        },
      })
      .add(() => {
        this.isLoading = false;
      });
  }

  editTempRequestFile() {
    if(this._requestMaintainFileUploadAttestComponent.fetchData().fileFormData){
      this.isLoading = true;
      this.requestManagementService
        .addRequestFileById(this._requestMaintainFileUploadAttestComponent.fetchData().fileFormData!)
        .subscribe({
          next: (response: RequestFileResponse) => {
            this.fileName = response.fileName;
            this.filePath = response.filePath;
            this.processEditTempRequest();
          },
          error: (error: HttpErrorResponse) => {
            this.serviceErrorHandler.displayHttpErrorDialog(
              this,
              error,
              Constants.ErrorMessages
            );
          },
        })
        .add(() => {
          this.isLoading = false;
        });
    }
    else {
      this.processEditTempRequest();
    }

  }
  processEditTempRequest() {
    if (this.selectedRequest?.id) {
      this.isLoading = true;
      this.requestManagementService
        .updateTempRequest(
          this.selectedRequest.id,
          {
            ...this.updateRequestCommand,
            isFormFinished: false,
          }
        )
        .subscribe({
          next: () => {
            this.showSuccessMessage();
          },
          error: (error: HttpErrorResponse) => {
            this.serviceErrorHandler.displayHttpErrorDialog(
              this,
              error,
              Constants.ErrorMessages
            );
          },
        })
        .add(() => {
          this.isLoading = false;
        });
    }
  }

  processCreate() {
    this.isLoading = true;
    this.requestManagementService
      .createRequest(this.createRequestCommand)
      .subscribe({
        next: (response) => {
          this.showSuccessMessage();
        },
        error: (error: HttpErrorResponse) => {
          this.serviceErrorHandler.displayHttpErrorDialog(
            this,
            error,
            Constants.ErrorMessages
          );
        },
      })
      .add(() => {
        this.isLoading = false;
      });
  }

  addRequestFile() {
    this.isLoading = true;
    this.requestManagementService
      .addRequestFileById(this._requestMaintainFileUploadAttestComponent.fetchData().fileFormData!)
      .subscribe({
        next: (response: RequestFileResponse) => {
          this.fileName = response.fileName;
          this.filePath = response.filePath;
          this.processCreate();
        },
        error: (error: HttpErrorResponse) => {
          this.serviceErrorHandler.displayHttpErrorDialog(
            this,
            error,
            Constants.ErrorMessages
          );
        },
      })
      .add(() => {
        this.isLoading = false;
      });
  }

  addAmendmentRequestFile() {
    this.isLoading = true;
    this.requestManagementService
      .addRequestFileById(this._requestMaintainFileUploadAttestComponent.fetchData().fileFormData!)
      .subscribe({
        next: (response: RequestFileResponse) => {
          this.fileName = response.fileName;
          this.filePath = response.filePath;
          this.processEdit();
        },
        error: (error: HttpErrorResponse) => {
          this.serviceErrorHandler.displayHttpErrorDialog(
            this,
            error,
            Constants.ErrorMessages
          );
        },
      })
      .add(() => {
        this.isLoading = false;
      });
  }

  processEdit() {
    if (this.selectedRequest?.id) {
      this.isLoading = true;
      this.requestManagementService
        .updateRequest(
          this.selectedRequest.id,
          this.updateRequestCommand
        )
        .subscribe({
          next: () => {
            this.showSuccessMessage();
          },
          error: (error: HttpErrorResponse) => {
            this.serviceErrorHandler.displayHttpErrorDialog(
              this,
              error,
              Constants.ErrorMessages
            );
          },
        })
        .add(() => {
          this.isLoading = false;
        });
    }
  }

  deleteTempRequestById(){
    if(this.selectedRequest?.id){
      this.isLoading = true;
      this.requestManagementService
        .deleteTempRequest(this.selectedRequest.id)
        .subscribe({
          next: (result: number) => {
            this.selectedRequest = undefined;
            this.isTempRequest = false;
          },
          error: (error: HttpErrorResponse) => {
            this.serviceErrorHandler.displayHttpErrorDialog(
              this,
              error,
              Constants.ErrorMessages
            );
          },
        })
        .add(() => {
          this.isLoading = false;
        });
    }

  }
  onCancel() {
    if(this.selectedRequest && this.isAmendment){
      this.router.NavigateTo.ViewRequest(this.selectedRequest.id);
    }
    else{
      this.NavigateBackToView();
    }
  }

  onClearForm() {
    this._requestMaintainAuditFirmComponent.clearForm();
    this._requestMaintainBasicComponent.clearForm();
    this._requestMaintainCountryContractComponent.clearForm();
    this._requestMaintainDeliverableSegmentComponent.clearForm();
    this._requestMaintainFileUploadAttestComponent.clearForm();
    this._requestMaintainServiceTypeComponent.clearForm();
    this._requestMaintainSimilarRequestReasonComponent.clearForm();
  }

  NavigateBackToView() {
    const userRoles = this.sessionStorage.getSystemRoles;

    if(userRoles?.includes(Role.Admin)){
      this.router.NavigateTo.Request();
    }
    else{
      this.router.NavigateTo.RequestMade();
    }

  }

  showSuccessMessage() {
    this.useGuard = false;
    this.NavigateBackToView();

    this.snackBar.open(
      `Request successfully ${
        this.pageMode === PageMode.Add ? 'created' : 'updated'
      }`
    );
  }

  showErrorMessage(message: string){
    this.snackBar.open(`${message}` );
  }

  onIsBusinessFinancialControllerVisible(value: boolean){
    console.log('onIsBusinessFinancialControllerVisible', value);
    this.isBusinessFinancialControllerVisible = value;
  }

  onIsGroupFinancialControllerVisible(value: boolean) {
    console.log('onIsGroupFinancialControllerVisible', value);
    this.isGroupFinancialControllerVisible = value;
  }

}
