import {HttpClient} from "@angular/common/http";
import {AbstractEndpoints} from "../../../environments/endpoints/endpoints.abstract";
import {ServiceMonitoring} from "../monitor/monitor.service";
import {AppSettings} from "../../../environments/app-settings/app-settings";
import {Environments} from "../../enums";
import {SessionServiceMock} from "./session.service.mock";
import {SessionService} from "./session.service";

export function SessionServiceFactory(
  http: HttpClient,
  endpoints: AbstractEndpoints,
  serviceMonitor: ServiceMonitoring
) {
  serviceMonitor.logEvent(
    'StatisticalAnnualProcessingServiceFactory',
    'Statistical Annual Processing Service Factory loaded'
  );

  switch (AppSettings.environment) {
    case Environments.MockData:
      return new SessionServiceMock(endpoints, http);
    default:
      return new SessionService(endpoints, http);
  }
}
