import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import {AppSettings} from "./environments/app-settings/app-settings";
import {enableProdMode} from "@angular/core";
import {Environments} from "./app/enums";

if (AppSettings.environment === Environments.Production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
