<form [formGroup]="segmentForm" fxLayout="column" fxLayoutGap="2px">
  <mat-form-field fxFlex appearance="fill">
    <mat-label >{{"Business Area"}}</mat-label>
    <mat-select formControlName="businessAreaId">
      <ng-container>
        <mat-option *ngFor="let segments of segments" [value]="segments.id">
          {{ segments.name }}
        </mat-option>
      </ng-container>
    </mat-select>
    <mat-error *ngIf="segmentForm.controls['businessAreaId'].hasError('required')">
      The Business Area field is required.
    </mat-error>
  </mat-form-field>

  <mat-form-field fxFlex appearance="fill">
    <mat-label >{{ "Subsegment" }}</mat-label>
    <mat-select formControlName="subsegmentId">
      <ng-container>
        <mat-option *ngFor="let subSegment of subSegments" [value]="subSegment.id">
          {{ subSegment.name }}
        </mat-option>
      </ng-container>
    </mat-select>
    <mat-error *ngIf="segmentForm.controls['subsegmentId'].hasError('required')">
      The Subsegment field is required.
    </mat-error>
  </mat-form-field>

  <!--Account_Executive-->
  <mat-form-field fxFlex appearance="fill">
    <mat-label >{{ "Account Executive / Exco Member" }}</mat-label>
    <input matInput readonly formControlName="accountExecutiveUserName"/>
    <mat-error *ngIf="segmentForm.controls['accountExecutiveUserName'].hasError('required') ||
        segmentForm.controls['accountExecutiveUserId'].hasError('required')">
      The field is required.
    </mat-error>
  </mat-form-field>

  <!--Business_Financial_Controller-->
  <mat-form-field fxFlex appearance="fill" *ngIf="isBusinessFinancialControllerVisible">
    <mat-label >{{ "Business Financial Controller" }}</mat-label>
    <input matInput formControlName="businessFinancialControllerUserName" readonly>
    <mat-error *ngIf="segmentForm.controls['businessFinancialControllerUserName'].hasError('required') ||
        segmentForm.controls['businessFinancialControllerUserId'].hasError('required')">
      The field is required.
    </mat-error>
  </mat-form-field>

  <!--Group Financial Controller-->
  <mat-form-field fxFlex appearance="fill" *ngIf="isGroupFinancialControllerVisible">
    <mat-label >{{ "Group Financial Controller" }}</mat-label>
    <input matInput placeholder="Group Financial Controller" formControlName="groupFinancialControllerUserName" readonly>
    <mat-error *ngIf="segmentForm.controls['groupFinancialControllerUserId'].hasError('required') ||
      segmentForm.controls['groupFinancialControllerUserName'].hasError('required')">
      The field is required.
    </mat-error>
  </mat-form-field>

  <!--Financial Director-->
<!--  <mat-form-field fxFlex appearance="fill" *ngIf="isGroupFinancialControllerVisible" class="hidden">-->
<!--    <mat-label >{{ "Financial Director" }}</mat-label>-->
<!--    <input matInput-->
<!--           placeholder="Financial Controller"-->
<!--           formControlName="financialDirectorUserName"-->
<!--           readonly>-->
<!--    <mat-error *ngIf="segmentForm.controls['financialDirectorUserName'].hasError('required') ||-->
<!--    segmentForm.controls['financialDirectorUserId'].hasError('required')">-->
<!--      The field is required.-->
<!--    </mat-error>-->
<!--  </mat-form-field>-->

  <mat-form-field fxFlex appearance="fill">
    <mat-label >{{'Cost Centre'}}</mat-label>
    <input matInput formControlName="costCenter"  readonly>

  </mat-form-field>

</form>
