import { Component } from '@angular/core';

@Component({
  selector: 'app-confirmation-dialog-with-comment',
  templateUrl: './confirmation-dialog-with-comment.component.html',
  styleUrls: ['./confirmation-dialog-with-comment.component.css']
})
export class ConfirmationDialogWithCommentComponent {

}
