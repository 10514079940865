import {MaterialIcons} from "../enums/separated-enums/material-icons.enum";
import * as Models from './models';

export class ModelInitializer {
  static ErrorDialog = class {
    static ErrorDialogConfig = (
      errorMessage: string,
      header = 'Error',
      topic?: string,
      icon: MaterialIcons = MaterialIcons.Error,
      displayReportIssueButton = false
    ): Models.ErrorDialogConfig => {
      return {
        errorMessage,
        header,
        icon,
        topic,
        displayReportIssueButton,
      };
    };
  };
}
